import React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import CustomModal from "../Modal/Modal";
import ExplosionConfetti from "../common/Confetti";
import { Canvas } from "@react-three/fiber";
import { useTranslation } from "react-i18next";
import CustomListButton from "../common/CustomListButton";
import { UserContext } from "../Context/UserContext";
import { useSearchParams } from "react-router-dom";

function BillingSuccess({ open, closeModal }) {
  const { t } = useTranslation("global");
  const [queryParameters] = useSearchParams();

  const { setTempSubscribed } = React.useContext(UserContext);

  const handleClose = () => {
    closeModal();
    setTempSubscribed();
  }

  React.useEffect(() => {
    setTempSubscribed(queryParameters.get("isMonthly"));
  }, []);

  return (
    <>
      <div
        style={{
          width: window.innerWidth,
          height: window.innerHeight,
          position: "absolute",
          top: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Canvas>
          <ExplosionConfetti
            colorsArray={[
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#2196f3",
              "#03a9f4",
              "#00bcd4",
              "#009688",
              "#4CAF50",
              "#8BC34A",
              "#CDDC39",
              "#FFEB3B",
              "#FFC107",
              "#FF9800",
              "#FF5722",
            ]}
          />
        </Canvas>
      </div>
      <CustomModal open={open} handleClose={handleClose}>
        <Container
          style={{
            borderBottom: "1px solid var(--border-color)",
            paddingBottom: 24,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 20,
            }}
          >
            {t("billingSuccessTitle")}
          </div>
          <div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 10,
            marginTop: 20,
          }}
        >
          <div
            style={{
              fontWeight: "400",
              fontSize: 20,
            }}
          >
            {t("billingSuccessSubTitle")}
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: 20,
            }}
          >
            {t("billingDetails")}
          </div>
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={t("billingSuccessButton")}
            onClick={() => handleClose()}
            big={true}
            type={"submit"}
          />
        </div>
      </CustomModal>
    </>
  );
}

export default BillingSuccess;
