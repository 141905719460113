import React from "react";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CheckList from "../common/CheckList";
import { AuthContext } from "../Context/AuthContext";
import { API_URL } from "../../consts";
import { UserContext } from "../Context/UserContext";

function UpgradeOfferCard({ plan, isAnnual }) {
  const { firebaseUser } = React.useContext(AuthContext);
  const { currentMerchantName, currentMerchantId } = React.useContext(UserContext);

  const handlePaylick = () => {
    document.getElementById("checkout-and-portal-button").click();
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          width: "100%",
          gap: 20,
          border: "1px solid var(--border-color)",
          borderRadius: 10,
          padding: 20,
        }}
        sx={{
          ".check-icon > .MuiSvgIcon-root": {
            color: "var(--create-product-primary-button-hover-color)",
          },
        }}
      >
        <h3
          style={{
            color: "var(--billing-primary-text-color)",
            fontSize: 25,
            marginBottom: 0,
          }}
        >
          {plan.title}
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 5,
            }}
          >
            <h2 style={{ margin: 0, fontSize: 30 }}>
              {plan.free ? `0€` : `${plan.price}€`}
            </h2>
            <div
              style={{
                fontSize: 18,
                lineHeight: "30px",
              }}
            >
              /mois
            </div>
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: "30px",
            }}
          >
            {plan.billedType}
          </div>
        </div>
        <form
          action={`${API_URL}api/user/pay`}
          method="POST"
          style={{ display: "none" }}
        >
          <input name="userEmail" value={firebaseUser.email} required />
          <input name="restaurantName" value={currentMerchantName} required />
          <input name="marchantId" value={currentMerchantId} required />
          <input name="isMonthly" value={!isAnnual} required />
          <button id="checkout-and-portal-button" type="submit" />
        </form>

        <CustomListButton
          onClick={() => handlePaylick()}
          text={plan.free ? "Plan actuel" : "Choisir"}
          backgroundColorProps={
            plan.free ? "var(--category-secondary-text-color)" : ""
          }
          backgroundColorHoverProps={
            plan.free ? "var(--category-secondary-text-color)" : ""
          }
          borderColorProps={plan.free ? "var(--bottom-background-color)" : ""}
        />

        <CheckList checkList={plan.avantages} gap={10} />
      </Container>
    </>
  );
}

export default UpgradeOfferCard;
