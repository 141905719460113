import React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import CustomModal from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import CustomListButton from "../common/CustomListButton";
import { useNavigate } from "react-router-dom";

function SubscriptionLimit({ open, closeModal }) {
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  return (
    <>
      <CustomModal open={open} handleClose={() => closeModal()}>
        <Container
          style={{
            borderBottom: "1px solid var(--border-color)",
            paddingBottom: 24,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 20,
            }}
          >
            {t("subscriptionLimitModalTitle")}
          </div>
          <div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal()}
            />
          </div>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 10,
            marginTop: 20,
          }}
        >
          <div
            style={{
              fontWeight: "400",
              fontSize: 20,
            }}
          >
            {t("subscriptionLimitModalSubTitle")}
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: 20,
            }}
          >
            {t("subscriptionLimitModalDescription")}
          </div>
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 10,
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            isPrimary={false}
            text={t("subscriptionLimitModalButton")}
            onClick={() => navigate("/user/billing?backPage=/user/dashboard")}
            big={true}
            type={"submit"}
          />
        </div>
      </CustomModal>
    </>
  );
}

export default SubscriptionLimit;
