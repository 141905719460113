import * as React from "react";
import ProductListItemContainer from "./ProductListItemContainer";
import "../../index.css";
import { FullPageSxStyle } from "./FullPageStyle";

export default function DoliTopPageTitle({ title = "", addPadding = false }) {
  return (
    <>
      <ProductListItemContainer
        style={{
          marginBottom: "15px !important",
          marginTop: "15px !important",
          paddingTop: "15px !important",
          paddingBottom: "15px !important",
          paddingLeft: addPadding ? "16px !important" : "0px !important",
          paddingRight: "0px !important",
          backgroundColor: "none",
          borderBottom: "none",
          borderLeft: "none",
          borderRight: "none",
          borderTop: "1px solid var(--divider-color)",
        }}
        sx={{
          ...FullPageSxStyle,
        }}
      >
        <h2
          style={{
            margin: 0,
            fontSize: "24px",
            lineHeight: "28px",
            color: "var(--black-text-color)",
          }}
        >
          {title}
        </h2>
      </ProductListItemContainer>
    </>
  );
}
