import * as React from "react";
import CustomModal from "../Modal/Modal";
import ImportMenu from "../ImportMenu/ImportMenu";

export default function ImportMenuModal({
  open,
  handleClose,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <ImportMenu closeModal={handleClose} />
    </CustomModal>
  );
}
