import { API_URL } from "../consts";

const createUser = async (userIdParams, userEmail, merchantsListParams = []) => {
  console.log("[User id]", userIdParams);
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      userId: userIdParams,
      merchantsList: merchantsListParams,
      userEmail: userEmail,
    }),
    redirect: "follow",
  };

  fetch(`${API_URL}api/user`, requestOptions)
    .then((response) => {
      response.text();
      console.log("createUser]", response);
    })
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
};

const getUser = async (userIdParams) => {
  const response = await fetch(`${API_URL}api/user/${userIdParams}`);
  const data = await response.json();
  return data;
};

const checkOnboarding = async (userIdParams) => {
  try {
    const response = await fetch(
      `${API_URL}api/user/checkOnboarding/${userIdParams}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    // Handle the response data
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    // Handle errors
    return { onboarding: false, message: error.message };
  }
};

const updateUser = async (
  userIdParams,
  merchantsListParams,
  isOnboarding = false
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify({
      merchantsList: merchantsListParams,
    }),
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}api/user/${userIdParams}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = response.json();
    console.log("updatedUser", data);

    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    // Handle errors
    throw new Error(error);
  }
};

const deleteDoliQrUser = async (userId) => {
  try {
    await fetch(`${API_URL}api/user/${userId}`, {
      method: "DELETE",
    });
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

export { createUser, checkOnboarding, updateUser, getUser, deleteDoliQrUser };
