import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "firebase/auth";
import "firebase/database";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { AuthProvider } from "./components/Context/AuthContext";
import { UserProvider } from "./components/Context/UserContext";
import { IS_DEV } from "./consts";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (!IS_DEV) {
  var console = {};
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  window.console = console;
}

root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <UserProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </UserProvider>
    </React.StrictMode>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
