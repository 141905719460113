import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { Container } from "@mui/material";
import CustomListButton from "../common/CustomListButton";
import CheckIcon from "@mui/icons-material/Check";

function ChooseRestaurant() {
  const navigate = useNavigate();
  const { currentMerchantId, doliQrUser, changeMerchant } =
    React.useContext(UserContext);
  const [selectedMerchant, setSelectedMerchant] = React.useState(
    currentMerchantId ?? doliQrUser.merchantsList[0]._id ?? ""
  );

  const handleContinue = async () => {
    await changeMerchant(selectedMerchant);
    navigate("/user/dashboard");
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "white" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px !important",
          maxWidth: 650,
          height: "100%",
        }}
        sx={{ padding: "34px 0px !important" }}
      >
        <Container
          sx={{
            ".textfiel-add > input": {
              padding: "0px important!",
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
              marginTop: "auto",
              justifyContent: "center",
              minHeight: { xs: "none", sm: "calc(100vh - 84px)" },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <h1 style={{ padding: 0, margin: 0, textAlign: "center" }}>
                Choisissez votre restaurant
              </h1>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  marginTop: 16,
                  color: "var(--category-secondary-text-color)",
                  textAlign: "center",
                }}
              >
                Vous avez plusieurs restaurants, vous devez en choisir un pour
                continuer.
              </div>
            </div>
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0px 16px",
              }}
            >
              {doliQrUser.merchantsList.map((merchant, index) => (
                <Container
                  key={index}
                  onClick={() => setSelectedMerchant(merchant._id)}
                  sx={{
                    padding: "0px 10px !important",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor:
                      selectedMerchant === merchant._id
                        ? "var(--create-product-secondary-button-hover-color)"
                        : "white",
                    ":hover": {
                      backgroundColor:
                        "var(--create-product-secondary-button-hover-color)",
                    },
                    ":hover > .check-icon": {
                      display: "block !important",
                    },
                    ".check-icon > .MuiSvgIcon-root": {
                      color: "var(--create-product-primary-button-hover-color)",
                    },
                  }}
                >
                  <p>{merchant.establishmentName}</p>

                  <div
                    className="check-icon"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "auto",
                      display:
                        selectedMerchant === merchant._id ? "block" : "none",
                    }}
                  >
                    <CheckIcon id="check-icon" fontSize="small" />
                  </div>
                </Container>
              ))}
            </Container>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <CustomListButton
                onClick={handleContinue}
                styles={{
                  width: "100%",
                  height: 46,
                  fontSize: 16,
                  fontWeight: "600 !important",
                }}
                text={"Continuer"}
              />
            </div>
          </Container>
          <Container
            sx={{
              display: { xs: "flex", sm: "none" },
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              padding: "32px 0px",
            }}
          ></Container>
        </Container>
      </Container>
    </div>
  );
}

export default ChooseRestaurant;
