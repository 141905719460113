import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const IOSSwitch = styled((props) => (
  <Switch
    checked={props.checked}
    onChange={props.onChange}
    focusVisibleClassName=".Mui-focusVisible"
    id="d"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: "var(--switch-width-container-size)",
  height: "var(--switch-height-container-size)",
  padding: 0,
  margin: 0,
  label: { margin: "0 !important" },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform:
        "translateX(calc(calc(var(--switch-width-container-size) - 4px) - calc(var(--switch-height-container-size) - 4px)))",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "calc(var(--switch-height-container-size) - 4px)",
    height: "calc(var(--switch-height-container-size) - 4px)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
