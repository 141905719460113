export default function Image({ src, alt, style, height, width, onLoad }) {
  return (
    <img
      id="image"
      src={src}
      alt={alt}
      style={{
        ...style,
      }}
      onLoad={onLoad}
    />
  );
}
