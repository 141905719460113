import { Container } from "@mui/material";
import React from "react";
import DoliButton from "./DoliButton";

function DoliNavigationMainCard({
  id = "",
  className = "",
  key = "",
  buttonStyles,
  onClick = () => {},
  active = false,
  text,
  icon,
}) {
  return (
    <Container
      key={key}
      id={id}
      onClick={() => onClick()}
      className={className}
      sx={{
        margin: "0px !important",
        padding: "0px !important",
        ".MuiSvgIcon-fontSizeMedium": {
          fontSize: "var(--drawer-nav-icon-size) !important",
          marginRight: "10px",
          color: active ? "var(--purple-color)" : "var(--black-color)",
        },
      }}
    >
      <DoliButton
        onClick={() => onClick()}
        iconBefore={icon}
        backgroundColorProps={
          active ? "var(--purple-color-transparent)" : "none"
        }
        borderColorProps={"transparent"}
        backgroundColorHoverProps={
          active
            ? "var(--purple-color-transparent)"
            : "var(--white-button-hover-color)"
        }
        primary={false}
        styles={{
          width: "100%",
          padding: "20px 10px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          fontSize: "16px",
          fontWeight: "500",
          color: active ? "var(--purple-color)" : "var(--black-color)",
          ...buttonStyles,
        }}
        text={text}
      />
    </Container>
  );
}
export default DoliNavigationMainCard;
