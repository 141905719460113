import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { handlePersonalizeSliderChange } from "../Personalize";
import { PersonalizeName } from "./PersonalizeText";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import DoliSlider from "../../common/DoliSlider";
import ActivatePersonalization from "../../Preview/ActivatePersonalization";

function PersonalizePromotional() {
  const { t } = useTranslation("global");
  const {
    promotionalBackgroundColor,
    setPromotionalBackgroundColor,
    promotionalTextColor,
    setPromotionalTextColor,
    promotionalTextSize,
    setPromotionalTextSize,
    promotionalBorderColor,
    setPromotionalBorderColor,
    reinitializeSpecificFieldFromTheme,
  } = React.useContext(PersonalizeContext);

  return (
    <ActivatePersonalization>
      <DoliNotMainSidebarDetailContainer
        title={t("sidebarPersonnalizePromotionnalModuletDetailsTitle")}
      >
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizePromotionalBackgroundColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("promotionalBackgroundColor")
          }
        >
          <ColorPickerPop
            setColor={setPromotionalBackgroundColor}
            color={promotionalBackgroundColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizePromotionalBorderColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("promotionalBorderColor")
          }
        >
          <ColorPickerPop
            setColor={setPromotionalBorderColor}
            color={promotionalBorderColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizePromotionalTextColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("promotionalTextColor")
          }
        >
          <ColorPickerPop
            setColor={setPromotionalTextColor}
            color={promotionalTextColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          widthName="100%"
          name={
            <PersonalizeName
              name={t("personalizePromotionalTextSize")}
              value={promotionalTextSize}
            />
          }
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("promotionalTextSize")
          }
        >
          <DoliSlider
            value={promotionalTextSize}
            onChange={(e, value) =>
              handlePersonalizeSliderChange(e, value, setPromotionalTextSize)
            }
            max={100}
          />
        </AddItemInputContainer>
      </DoliNotMainSidebarDetailContainer>
    </ActivatePersonalization>
  );
}

export default PersonalizePromotional;
