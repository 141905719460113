import * as React from "react";
import "../../index.css";
import DoliButton from "./DoliButton";

export default function CustomListButton({
  onClick,
  text,
  primary = true,
  backgroundColorProps,
  backgroundColorHoverProps,
  borderColorProps,
  big = true,
  type,
  iconBefore,
  minWidth = "64px",
  width = "auto",
  height = "38px",
  lineHeight = "20px",
  fontWeight,
  styles,
  children,
}) {
  return (
    <DoliButton
      onClick={onClick}
      text={text}
      primary={primary}
      backgroundColorProps={backgroundColorProps}
      backgroundColorHoverProps={backgroundColorHoverProps}
      borderColorProps={borderColorProps}
      big={big}
      type={type}
      iconBefore={iconBefore}
      minWidth={minWidth}
      width={width}
      height={height}
      lineHeight={lineHeight}
      styles={styles}
      fontWeight={fontWeight || primary ? 600 : 400}
      children={children}
    />
  );
}
