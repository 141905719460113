import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CloseIcon from "@mui/icons-material/Close";
import { CategoriesContext } from "../Context/CategoriesContext";
import UploadFolderButton from "../common/UploadFolder/UploadFolderButton";
import { allAllergensList } from "../../consts";
import { useTranslation } from "react-i18next";
import { imagesList } from "../../imagesList";

function CreateProduct({ categoryId, closeModal, menuId }) {
  const { t } = useTranslation("global");
  const { addProduct } = React.useContext(CategoriesContext);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [price, setPrice] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [customImage, setCustomImage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [allergensList, setAllergensList] = React.useState([]);

  const handleAddAllergen = (allergen) => {
    setAllergensList([...allergensList, allergen]);
  };

  const handleRemoveAllergen = (allergen) => {
    setAllergensList(allergensList.filter((a) => a !== allergen));
  };

  const handleAllergenClick = (allergen) => {
    if (allergensList.includes(allergen)) {
      handleRemoveAllergen(allergen);
    } else {
      handleAddAllergen(allergen);
    }
  };

  const handleCreateProduct = () => {
    if (!name) {
      setErrorMessage("Le nom du plat est obligatoire.");
    } else {
      addProduct(
        name,
        description,
        price,
        categoryId,
        customImage,
        image,
        allergensList,
        menuId,
        closeModal
      );
    }
  };

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Nouveau Plat
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          {errorMessage && (
            <Container
              sx={{
                fontWeight: "400",
                fontSize: 16,
                color: "var(--unwanted-button-color)",
              }}
            >
              {errorMessage}
            </Container>
          )}
          <Container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              margin: "0px !important",
              padding: "0px !important",
            }}
          >
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 2,
                margin: {
                  xs: "0px 0px 20px 0px !important",
                  md: "0px !important",
                },
                padding: "0px !important",
                gap: "20px !important",
              }}
            >
              <AddItemInputContainer
                name={"Nom du produit"}
                setter={setName}
                required={true}
                value={name}
              />
              <AddItemInputContainer
                name={"Description du produit"}
                rows={2}
                setter={setDescription}
                value={description}
              />
              <AddItemInputContainer
                rows={1}
                name={"Prix du produit"}
                type={"number"}
                setter={setPrice}
                price={true}
                value={price}
              />
              <AddItemInputContainer
                name={t("textAllergens")}
                notTextField={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 6,
                  }}
                >
                  {allAllergensList.map((allergen) => (
                    <CustomListButton
                      text={t(`${allergen.translateText}`)}
                      onClick={() =>
                        handleAllergenClick(allergen.translateText)
                      }
                      styles={{
                        width: "auto",
                        padding: "10px!important",
                        borderRadius: 100,
                        height: 30,
                        fontWeight: "500 !important",
                      }}
                      primary={
                        allergensList?.includes(allergen.translateText) ?? false
                      }
                    />
                  ))}
                </div>
              </AddItemInputContainer>
            </Container>
            <Container
              sx={{
                flex: 1,
              }}
            >
              <UploadFolderButton
                image={image}
                //setImage={setImage}
                customImage={customImage}
                //setCustomImage={setCustomImage}
                imageListToUse={imagesList}
                saveFunction={(image, isCustom) => {
                  console.log("[CreateProduct]saveFunction", image, isCustom);
                  if (image?.extension) {
                    setCustomImage(image);
                  } else {
                    setImage(image);
                  }
                }}
              />
            </Container>
          </Container>
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => handleCreateProduct()}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default CreateProduct;
