import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

function FAQ({ title, description }) {
  return (
    <Accordion
      sx={{
        boxShadow: "none",

        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      }}
    >
      <AccordionSummary
        sx={{
          padding: "10px 0px !important",
          borderBottom: "none",
        }}
        expandIcon={
          <ArrowBackIosOutlinedIcon
            sx={{
              height: 20,
              width: 20,
              transform: `rotate(270deg)`,
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div
          style={{
            fontWeight: "600",
          }}
        >
          {title}
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0px 16px 16px 0px",
          borderBottom: "none !important",
        }}
      >
        <div
          style={{
            fontWeight: "400",
            fontSize: 14,
          }}
        >
          {description}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default FAQ;
