import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import OnboardingTemplate from "./OnboardingTemplate";
import { useTranslation } from "react-i18next";
import ChooseImageHorizonalList from "../common/UploadFolder/ChooseImageHorizonalList";

function SelectBanner({
  image,
  setImage,
  customImageSelected,
  setCustomImageSelected,
  onPrimaryButtonClick,
  error,
}) {
  const { t } = useTranslation("global");

  return (
    <OnboardingTemplate
      error={error}
      primaryButton={{
        text: "Continuer",
        onClick: () => onPrimaryButtonClick(),
      }}
      title={t("onboardingSelectBannerTitle")}
      subtitle={t("onboardingSelectBannerSubTitle")}
      divider={true}
      inputs={
        <>
          <AddItemInputContainer
            name={""}
            notTextField={true}
            titleFontSize={"15px"}
            fontSize={"18px"}
            heightProps={40}
            style={{
              padding: "0px 16px",
            }}
          >
            <ChooseImageHorizonalList
              image={image}
              setImage={setImage}
              customImageSelected={customImageSelected}
              setCustomImageSelected={setCustomImageSelected}
            />
          </AddItemInputContainer>
        </>
      }
    />
  );
}

export default SelectBanner;
