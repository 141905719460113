import React from "react";
import Container from "@mui/material/Container";
import DoliIcon from "../common/DoliIcon";
import { HoverTransitionStyle } from "./FullPageStyle";

function DoliNavigationBasicMainCard({
  text = "Menu",
  icon,
  onClick,
  active = true,
  backgroundColorProps = "transparent",
  backgroundColorHoverProps = "transparent",
  borderColorProps,
  textStyles,
  iconSize = "16px",
  iconSizeInactive = "28px",
  iconColorActive = "white",
  iconColorInActive = "var(--purple-color)",
  primary = false,
}) {
  return (
    <Container
      onClick={() => onClick()}
      sx={{
        display: "flex",
        width: "auto",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "var(--button-border-radius)",
        padding: "0px !important",
        margin: "0px !important",
        color: primary ? "white" : "black",
        cursor: "pointer",
        gap: "2px",
        backgroundColor: backgroundColorProps
          ? backgroundColorProps
          : primary
          ? "var(--purple-color)"
          : "var(--create-product-secondary-button-color)",
        ...HoverTransitionStyle,
        "&:hover": {
          backgroundColor: backgroundColorHoverProps
            ? backgroundColorHoverProps
            : primary
            ? "var(--purple-color-hover)"
            : "var(--create-product-secondary-button-hover-color)",
          borderColor: borderColorProps
            ? borderColorProps
            : primary
            ? "var(--create-product-primary-button-border-color)"
            : "var(--create-product-secondary-button-border-color)",
        },
      }}
    >
      {!active && (
        <DoliIcon
          iconSize={iconSizeInactive}
          color={iconColorInActive}
          iconStyles={{
            margin: "0px !important",
            padding: "5px !important",
          }}
          backgroundColor={active ? "var(--purple-color-transparent)" : "none"}
          backgroundColorHoverProps={"var(--purple-color-transparent)"}
        >
          {icon}
        </DoliIcon>
      )}
      {active && (
        <Container
          sx={{
            margin: "0px !important",
            backgroundColor: "var(--purple-color-transparent)",
            display: "flex",
            width: "38px",
            height: "38px",
            borderRadius: "5px",
            padding: "8px !important",
            ".MuiSvgIcon-fontSizeMedium": {
              fontSize: `${iconSize}!important`,
              color: iconColorActive,
            },
          }}
        >
          <Container
            sx={{
              margin: "0px !important",
              backgroundColor: "var(--purple-color)",
              borderRadius: "5px",
              padding: "0px !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "24px",
              height: "24px",
            }}
          >
            {icon}
          </Container>
        </Container>
      )}
      <Container
        sx={{
          display: "flex",
          padding: "0px !important",
          margin: "0px !important",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          fontWeight: "500",
          color: "var(--purple-color)",
          ...textStyles,
        }}
      >
        {text}
      </Container>
    </Container>
  );
}
export default DoliNavigationBasicMainCard;
