import React from "react";
import { iconsList } from "../../iconsList";
import { getColor } from "../../services/basicsService";

function DoliNavigationSquareCard({
  id = "",
  onClick = () => {},
  text,
  iconProps,
}) {
  return (
    <div
      key={id}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 80,
        background: getColor(),
        borderRadius: "var(--button-border-radius)",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        {iconProps && (
          <div
            style={{
              width: "20px",
              height: "20px",
              display: "flex",
            }}
          >
            {iconsList.find((icon) => icon.name === iconProps).icon}
          </div>
        )}
        <div
          style={{
            width: 80,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
}
export default DoliNavigationSquareCard;
