import * as React from "react";
import { Container } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { basicsSx } from "../IphonePreviewUtils";

export default function TopNavigation({ menuName, onBack }) {
  return (
    <Container
      sx={{
        ...basicsSx,
        position: "absolute",
        top: "0px",
        borderBottom: "var(--border)",
        zIndex: 1,
        height: 50,
        left: "0px",
        padding: "10px 16px 10px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "10px !important",
      }}
    >
      <div
        onClick={onBack}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ArrowBackIosNewIcon sx={{ height: "20px" }} htmlColor={"var(--icon-color)"} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
        }}
      >
        <h2
          style={{
            color: "var(--text-color)",
            fontSize: "var(--important-text-size)",
            fontWeight: "500",
            margin: 0,
            width: "240px",
            textAlign: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "inline-block",
            whiteSpace: "nowrap",
          }}
        >
          {menuName}
        </h2>
      </div>
      <div style={{ width: 20 }}></div>
    </Container>
  );
}
