import React, { createContext, useState, useMemo } from "react";
import { SettingsContext } from "./SettingsContext";
import { useTranslation } from "react-i18next";
import { bannerImagesList } from "../../bannerImagesList";
import { PersonalizeContext } from "./PersonalizeContext";
import { informationsLogs } from "../../services/logs";
import eventBus from "../../services/eventBus";

export const InformationsContext = createContext();

export const imageType = {
  TYPE_1: "TYPE_1",
  TYPE_2: "TYPE_2",
  TYPE_3: "TYPE_3",
  TYPE_4: "TYPE_4",
};

export const InformationsProvider = ({ children }) => {
  const { settings, fetchSettings, updateSettings, setAllIsSaved } =
    React.useContext(SettingsContext);
  const { checkTheme, setCheckTheme } = React.useContext(PersonalizeContext);
  const { t } = useTranslation("global");

  const [restaurantName, setRestaurantName] = React.useState(
    settings?.basic?.establishmentName
  );
  const [restaurantPublicMenuUrl, setRestaurantPublicMenuUrl] = React.useState(
    settings?.basic?.publicMenuURL
  );
  const [restaurantAddress, setRestaurantAddress] = React.useState(
    settings?.basic?.address
  );
  const [promotionalText, setPromotionalText] = React.useState(
    settings?.promotionalText
  );
  const [dayHours, setDayHours] = React.useState(settings?.basic?.dayHours);
  const [restaurantPhoneNumber, setRestaurantPhoneNumber] = React.useState(
    settings?.basic?.phoneNumber
  );
  const [message, setMessage] = React.useState(settings?.basic?.message);
  const [showRestaurantName, setShowRestaurantName] = React.useState(
    settings?.appearance?.showRestaurantName
  );
  const [theme, setTheme] = React.useState(settings?.appearance?.themeColor);
  const [image, setImage] = React.useState(null);
  const [customImage, setCustomImage] = React.useState(
    settings?.appearance?.banner?.custom
      ? settings?.appearance?.banner?.value
      : null
  );
  const [display, setDisplay] = React.useState(settings?.review?.display);
  const [link, setLink] = React.useState(settings?.review?.link);
  const [networkName, setNetworkName] = React.useState(
    settings?.wifi?.networkName
  );
  const [password, setPassword] = React.useState(settings?.wifi?.password);
  const [instagram, setInstagram] = React.useState(
    settings?.socialNetworks?.instagram
  );
  const [facebook, setFacebook] = React.useState(
    settings?.socialNetworks?.facebook
  );
  const [twitter, setTwitter] = React.useState(settings?.socialNetworks?.x);
  const [allSettings, setAllSettings] = useState(null);
  const [allSettingsChanged, setAllSettingsChanged] = useState(false);

  const handleFetchSettings = React.useCallback(async () => {
    try {
      const settingsFetched = await fetchSettings();
      console.log("IN handleFetchSettings,settingsFetched", settingsFetched);
      setAllSettings(settingsFetched);
      console.log("[checkAllIsSaved][Informations] from handleFetchSettings");
    } catch (error) {
      console.error("Error fetching settings", error);
    }
  }, []);

  const handleUpdateTheme = async () => {
    informationsLogs("theme", theme);
    await updateSettings({
      appearance: {
        themeColor: theme,
      },
    });
    setCheckTheme(!checkTheme);
  };

  const handleSaveInformations = async () => {
    updateSettings({
      appearance: {
        showRestaurantName: true,
        themeColor: theme,
      },
      wifi: {
        networkName: networkName,
        password: password,
      },
      socialNetworks: {
        instagram: instagram,
        facebook: facebook,
        x: twitter,
      },
      review: {
        display: display,
        link: link,
      },
      basic: {
        establishmentName: restaurantName,
        publicMenuURL: restaurantPublicMenuUrl,
        address: restaurantAddress,
        phoneNumber: restaurantPhoneNumber,
        message: message,
        dayHours: dayHours,
      },
      promotionalText: promotionalText,
      image: image,
      customImage: customImage,
    });
    console.log("[checkAllIsSaved][Informations] from handleSaveInformations");
    checkAllIsSaved(false, true);
  };

  const [lastSaveValues, setLastSaveValues] = React.useState(null);

  const checkAllIsSaved = React.useCallback(
    async (initialize = false, save = false) => {
      const newValues = {
        networkName: initialize ? allSettings?.wifi?.networkName : networkName,
        password: initialize ? allSettings?.wifi?.password : password,
        instagram: initialize
          ? allSettings?.socialNetworks?.instagram
          : instagram,
        facebook: initialize ? allSettings?.socialNetworks?.facebook : facebook,
        x: initialize ? allSettings?.socialNetworks?.x : twitter,
        display: initialize ? allSettings?.review?.display : display,
        link: initialize ? allSettings?.review?.link : link,
        establishmentName: initialize
          ? allSettings?.basic?.establishmentName
          : restaurantName,
        publicMenuURL: initialize
          ? allSettings?.basic?.publicMenuURL
          : restaurantPublicMenuUrl,
        address: initialize ? allSettings?.basic?.address : restaurantAddress,
        phoneNumber: initialize
          ? allSettings?.basic?.phoneNumber
          : restaurantPhoneNumber,
        message: initialize ? allSettings?.basic?.message : message,
        dayHours: initialize ? allSettings?.basic?.dayHours : dayHours,
        promotionalText: initialize
          ? allSettings?.promotionalText
          : promotionalText,
        image: initialize ? allSettings?.appearance?.banner : image,
        customImage: initialize ? allSettings?.appearance?.banner : customImage,
      };
      if (lastSaveValues === null && initialize) {
        console.log("[checkAllIsSaved][Informations][1]newValues", newValues);
        console.log(
          "[checkAllIsSaved][Informations][1]lastSaveValues",
          lastSaveValues
        );
        setLastSaveValues(newValues);
        setAllIsSaved(true);
        console.log("[checkAllIsSaved][Informations][2]all set");
      } else if (save) {
        setLastSaveValues(newValues);
        setAllIsSaved(true);
        console.log("[checkAllIsSaved][Informations][3]saved");
      } else if (lastSaveValues !== null && !save && !initialize) {
        console.log(
          "[checkAllIsSaved][Informations][4] checking",
          JSON.stringify(newValues) === JSON.stringify(lastSaveValues)
        );
        setAllIsSaved(
          JSON.stringify(newValues) === JSON.stringify(lastSaveValues)
        );
      }
    }
  );

  const checkBannerChanged = React.useCallback(() => {
    console.log("checkBannerChanged");
    return {
      changed: image !== null || customImage !== null,
      value: {
        image,
        customImage,
      },
    };
  }, [image, customImage]);

  React.useEffect(() => {
    const handleCheckBannerStatus = () => {
      const status = checkBannerChanged();
      eventBus.emit("BANNER_STATUS_RESPONSE", status);
    };

    eventBus.on("CHECK_BANNER_STATUS", handleCheckBannerStatus);

    return () => {
      eventBus.off("CHECK_BANNER_STATUS", handleCheckBannerStatus);
    };
  }, [checkBannerChanged]);

  React.useEffect(() => {
    if (settings) {
      setRestaurantName(settings?.basic?.establishmentName);
      setRestaurantPublicMenuUrl(settings?.basic?.publicMenuURL);
      setRestaurantAddress(settings?.basic?.address);
      setRestaurantPhoneNumber(settings?.basic?.phoneNumber);
      setMessage(settings?.basic?.message);
      setShowRestaurantName(settings?.appearance?.showRestaurantName);
      setTheme(settings?.appearance?.themeColor);
      setDisplay(settings?.review?.display);
      setLink(settings?.review?.link);
      setNetworkName(settings?.wifi?.networkName);
      setPassword(settings?.wifi?.password);
      setInstagram(settings?.socialNetworks?.instagram);
      setFacebook(settings?.socialNetworks?.facebook);
      setTwitter(settings?.socialNetworks?.x);
      setPromotionalText(settings?.promotionalText);
      setDayHours(settings?.basic?.dayHours);
    }
  }, [settings]);
  React.useEffect(() => {
    if (allSettings) {
      setRestaurantName(allSettings?.basic?.establishmentName);
      setRestaurantPublicMenuUrl(allSettings?.basic?.publicMenuURL);
      setRestaurantAddress(allSettings?.basic?.address);
      setRestaurantPhoneNumber(allSettings?.basic?.phoneNumber);
      setMessage(allSettings?.basic?.message);
      setShowRestaurantName(allSettings?.appearance?.showRestaurantName);
      setTheme(allSettings?.appearance?.themeColor);
      setDisplay(allSettings?.review?.display);
      setLink(allSettings?.review?.link);
      setNetworkName(allSettings?.wifi?.networkName);
      setPassword(allSettings?.wifi?.password);
      setInstagram(allSettings?.socialNetworks?.instagram);
      setFacebook(allSettings?.socialNetworks?.facebook);
      setTwitter(allSettings?.socialNetworks?.x);
      setPromotionalText(allSettings?.promotionalText);
      setDayHours(allSettings?.basic?.dayHours);

      console.log("restaurantName:", restaurantName);
      informationsLogs("settingsFetched?", allSettings);
      informationsLogs("settingsFetched.basic?", allSettings.basic);
      informationsLogs(
        "settingsFetched?.basic?.establishmentName",
        allSettings.basic?.establishmentName
      );

      if (image === null) {
        informationsLogs("if (image === null) {");
        informationsLogs("if (image === null) { image", image);
        informationsLogs("if (image === null) { customImage", customImage);
        informationsLogs("if (image === null) { allSettings", allSettings);
        if (customImage === null) {
          informationsLogs("if (customImage === null) {", image);
          if (
            allSettings?.appearance?.banner?.affiliateUrl !== "none" &&
            allSettings?.appearance?.banner?.affiliateUrl !== "" &&
            allSettings?.appearance?.banner?.affiliateUrl
          ) {
            informationsLogs("[image] 1");
            setCustomImage({
              value: allSettings?.appearance?.banner?.affiliateUrl,
              isOur: false,
            });
            setImage(null);
          } else if (
            allSettings?.appearance?.banner?.custom &&
            allSettings?.appearance?.banner?.value
          ) {
            informationsLogs("[image] 2");
            setCustomImage({
              value: allSettings?.appearance?.banner?.value,
              isOur: false,
            });
            setImage(null);
          } else if (
            !allSettings?.appearance?.banner?.custom &&
            allSettings?.appearance?.banner?.value
          ) {
            informationsLogs(
              "[image] 3",
              allSettings?.appearance?.banner?.value
            );
            const imageFound = bannerImagesList.find((img) => {
              return img.name === allSettings?.appearance?.banner?.value;
            });
            setImage({ value: imageFound, isOur: true });
            setCustomImage(null);
          } else {
            informationsLogs("[image] 4");
            setImage(null);
            setCustomImage(null);
          }
        } else {
          informationsLogs("if (customImage !== null) {", customImage);
          setImage(null);
        }
      } else {
        informationsLogs("if (image !== null) {", image);
      }
      if (customImage) {
        informationsLogs("[customImage]", customImage);
        setImage(null);
      }

      checkAllIsSaved(true);
    }
  }, [allSettings, image]);

  React.useEffect(() => {
    const initialize = async () => {
      await handleFetchSettings();
    };

    initialize();
  }, []);

  React.useEffect(() => {
    console.log("[checkAllIsSaved][Informations] from useEffect");
    checkAllIsSaved();
  }, [
    networkName,
    password,
    instagram,
    facebook,
    twitter,
    display,
    link,
    restaurantName,
    restaurantPublicMenuUrl,
    restaurantAddress,
    restaurantPhoneNumber,
    message,
    dayHours,
    promotionalText,
    image,
    customImage,
  ]);

  React.useEffect(() => {
    informationsLogs("check theme");
    if (checkTheme && theme !== settings?.appearance?.themeColor) {
      informationsLogs(
        "if (checkTheme && theme !== settings?.appearance?.themeColor) {"
      );
      handleUpdateTheme();
    }
  }, [checkTheme]);

  React.useEffect(() => {
    setCheckTheme(!checkTheme);
  }, [theme]);

  const contextValue = useMemo(
    () => ({
      restaurantName,
      setRestaurantName,
      restaurantPublicMenuUrl,
      setRestaurantPublicMenuUrl,
      restaurantAddress,
      setRestaurantAddress,
      restaurantPhoneNumber,
      setRestaurantPhoneNumber,
      message,
      setMessage,
      showRestaurantName,
      setShowRestaurantName,
      theme,
      setTheme,
      image,
      setImage,
      customImage,
      setCustomImage,
      display,
      setDisplay,
      link,
      setLink,
      networkName,
      setNetworkName,
      password,
      setPassword,
      instagram,
      setInstagram,
      facebook,
      setFacebook,
      twitter,
      setTwitter,
      promotionalText,
      setPromotionalText,
      dayHours,
      setDayHours,
      handleFetchSettings,
      handleSaveInformations,
      checkBannerChanged,
    }),
    [
      restaurantName,
      setRestaurantName,
      restaurantPublicMenuUrl,
      setRestaurantPublicMenuUrl,
      restaurantAddress,
      setRestaurantAddress,
      restaurantPhoneNumber,
      setRestaurantPhoneNumber,
      message,
      setMessage,
      showRestaurantName,
      setShowRestaurantName,
      theme,
      setTheme,
      image,
      setImage,
      customImage,
      setCustomImage,
      display,
      setDisplay,
      link,
      setLink,
      networkName,
      setNetworkName,
      password,
      setPassword,
      instagram,
      setInstagram,
      facebook,
      setFacebook,
      twitter,
      setTwitter,
      promotionalText,
      setPromotionalText,
      dayHours,
      setDayHours,
      handleFetchSettings,
      handleSaveInformations,
      checkBannerChanged,
    ]
  );

  return (
    <InformationsContext.Provider value={contextValue}>
      {children}
    </InformationsContext.Provider>
  );
};
