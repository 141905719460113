import React from "react";
import Container from "@mui/material/Container";
import TopMembersPlanList from "../common/TopMembersPlanList";
import MemberCard from "./MemberCard";
import PersonMemberCard from "./PersonMemberCard";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";

function Members() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("membersPageTitle")}`);

  const dropdownOptions = [
    {
      value: "OWNER",
      label: t("membersPageTitleRoleOwner"),
    },
    {
      value: "ADMIN",
      label:t("membersPageTitleRoleAdmin"),
    },
    {
      value: "EDITOR",
      label: t("membersPageTitleRoleEditor"),
    },
  ];

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: 850,
        }}
      >
        <TopMembersPlanList
          actualPage={"members"}
          showInviteMemberButton={true}
          pageTitle={t("membersPageTitle")}
        />

        <MemberCard
          isMemberCard={false}
          sx={{
            display: { xs: "none !important", md: "flex !important" },
          }}
          first={true}
          padding={"15px 20px"}
          firstElement={
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
                cursor: "pointer",
                height: "100%",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  fontWeight: "500",
                  color: "var(--product-card-title-color)",
                }}
              >
                {t("membersPageName").toUpperCase()}
              </span>
            </div>
          }
          secondElement={
            <div
              style={{
                fontSize: 12,
                fontWeight: "500",
                color: "var(--product-card-title-color)",
                overflow: "hidden",
                textOverflow: "clip",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
            >
              {t("membersPageTitleRole").toUpperCase()}
            </div>
          }
        />

        {
          // TODO(chris): Map members here
        }{/*
        <PersonMemberCard
          dropdownOptions={dropdownOptions}
          accepted={true}
          name={"Christophe Realini"}
          mail={"rp.chezchris@gmail.com"}
        />
        <PersonMemberCard
          dropdownOptions={dropdownOptions}
          accepted={true}
          name={"Romain Realini"}
          mail={"rp.chezchris@gmail.com"}
          profilePicture={"https://i.pravatar.cc/300"}
        />
        <PersonMemberCard
          dropdownOptions={dropdownOptions}
          last={true}
          name={"Christophe Realini"}
        />
        */}
      </Container>
    </>
  );
}

export default Members;
