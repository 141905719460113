import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CloseIcon from "@mui/icons-material/Close";
import { MenusContext } from "../Context/MenusContext";
import { iconsList } from "../../iconsList";
import ChooseMenuIconModal from "../ChooseMenuIcon/ChooseMenuIconModal";

function EditMenu({
  id,
  actualName,
  actualDescription,
  actualIcon,
  closeModal,
}) {
  console.log("EditMenu", id, actualName, actualDescription, actualIcon);
  const { updateMenu } = React.useContext(MenusContext);
  const [name, setName] = React.useState(actualName);
  const [description, setDescription] = React.useState(actualDescription);
  const [iconChoosed, setIcon] = React.useState(actualIcon ?? "fork");
  const [openChooseIconModal, setOpenChooseIconModal] = React.useState(false);

  const handleOpenChooseIconModal = () => {
    setOpenChooseIconModal(true);
  };

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          gap: 20,
        }}
      >
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
            ":hover": {
              backgroundColor: "var(--white-hover-color)",
              borderRadius: "var(--borderRadiusCard)",
            },
          }}
          onClick={() => handleOpenChooseIconModal()}
          style={{
            marginRight: "17px",
            height: "34px",
            width: "34px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "24px",
              width: "24px",
              display: "flex",
            }}
          >
            {iconsList.find((icon) => icon.name === iconChoosed).icon}
          </div>
        </Container>
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          {name}
        </div>
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
            ":hover": {
              backgroundColor: "var(--white-hover-color)",
              borderRadius: "var(--borderRadiusCard)",
            },
          }}
          onClick={() => handleOpenChooseIconModal()}
          style={{
            marginRight: "17px",
            height: "34px",
            width: "34px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal()}
            />
          </div>
        </Container>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <CustomListButton
            onClick={() => handleOpenChooseIconModal()}
            text={"Choisir une icône"}
            styles={{
              margin: { xs: "0px 16px !important", sm: "0px 24px !important" },
            }}
          />
          <AddItemInputContainer
            name={"Nom du Menu"}
            //description={"Nom du Menu"}
            setter={setName}
            value={name}
            required={true}
          />
          <AddItemInputContainer
            name={"Description du Menu"}
            //description={"Description du Menu"}
            rows={2}
            setter={setDescription}
            value={description}
          />
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => {
              if (name !== actualName || description !== actualDescription) {
                updateMenu(id, name, description, iconChoosed, closeModal);
              } else {
                closeModal();
              }
            }}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
      {openChooseIconModal && (
        <ChooseMenuIconModal
          open={openChooseIconModal}
          handleClose={() => setOpenChooseIconModal(false)}
          id={id}
          name={name}
          description={description}
          actualIcon={iconChoosed}
          setIcon={setIcon}
        />
      )}
    </>
  );
}

export default EditMenu;
