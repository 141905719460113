import React from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import DoliNavigationBasicMainCard from "../common/DoliNavigationBasicMainCard";
import { drawerBasicList } from "../Navigation/DoliBasicNavigation";
import {
  DoliNavigationContext,
  drawerBasicSecondaryListActions,
} from "../Context/DoliNavigationContext";
import DoliNavigationMainCard from "./DoliNavigationMainCard";
import DoliButton from "./DoliButton";

function DoliSidebarNavigation({
  actualPageId = 0,
  hideDetailSidebar = false,
}) {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const { closeDrawer, handleOpenDrawer, drawerBasicSecondaryList } =
    React.useContext(DoliNavigationContext);
  console.log("[DoliSidebarNavigation]hideDetailSidebar", hideDetailSidebar);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleNavigationCardClick = (type) => {
    console.log("[DoliSidebarNavigation] handleProfileButtonClick");
    if (type.isLink) {
      navigate(type.isLink);
    }
    if (type.onClick) {
      type.onClick();
    }
    if (type.action) {
      switch (type.action) {
      }
    }
    if (!type.stayOpen) {
      toggleDrawer();
    }
    handleOpenDrawer();
  };

  return (
    <>
      <Container
        sx={{
          width: {
            sm: "var(--sidebar-toolbar-not-main-width)",
            md:
              hideDetailSidebar === false
                ? "328px"
                : "var(--sidebar-toolbar-not-main-width)",
          },
          margin: "0px !important",
          padding: "0px !important",
          backgroundColor: "var(--white-color)",
          height: "100vh",
          position: "fixed",
          zIndex: 4,
          left: 0,
          display: "flex",
          justifyContent: "flex-start",
          border: "1px solid var(--border-color)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <Container
            sx={{
              width: "content",
              backgroundColor: "var(--purple-color-hover)",
              height: "100vh",
              position: "absolute",
              left: 0,
              opacity: "0.05",
              margin: "0px !important",
              padding: "0px !important",
              zIndex: -1,
            }}
          />
          <Container
            sx={{
              width: "var(--sidebar-toolbar-not-main-width)",
              margin: "0px !important",
              padding: "0px !important",
              height: "100vh",
              position: "fixed",
              zIndex: 1,
              left: 0,
              display: "flex",
              justifyContent: "flex-start",
              border: "1px solid var(--border-color)",
            }}
          >
            <Container
              sx={{
                margin: "16px 0px !important",
                padding: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              {drawerBasicList.map((item) => (
                <DoliNavigationBasicMainCard
                  icon={item.icon}
                  text={item.name}
                  active={item.id === actualPageId}
                  onClick={() =>
                    handleNavigationCardClick({
                      isLink: item.link,
                    })
                  }
                />
              ))}
            </Container>
          </Container>
        </div>
        {hideDetailSidebar === false && !closeDrawer && (
          <Container
            sx={{
              width: "var(--sidebar-not-main-width)",
              margin: "0px !important",
              padding: "0px !important",
              backgroundColor: "var(--white-color)",
              height: "100vh",
              position: "absolute",
              zIndex: 3,
              left: 64,
            }}
          >
            <Container
              sx={{
                width: "content",
                backgroundColor: "var(--purple-color-hover)",
                height: "100vh",
                position: "absolute",
                opacity: "0.05",
                margin: "0px !important",
                padding: "0px !important",
                zIndex: 4,
              }}
            />
            <Container
              sx={{
                width: "var(--sidebar-not-main-width)",
                margin: "0px !important",
                padding: "16px !important",
                height: "100vh",
                position: "fixed",
                zIndex: 5,
                left: 64,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {drawerBasicSecondaryList({
                id: actualPageId,
              }).secondaryList?.map((item, itemIndex) => (
                <>
                  {item.button && (
                    <DoliButton
                      iconBefore={item.icon}
                      minWidth="100%"
                      width="100%"
                      height={"38px"}
                      styles={{
                        whiteSpace: "nowrap",
                        marginRight: "0px !important",
                        justifyContent: "space-between",
                      }}
                      text={
                        <div
                          style={{
                            marginLeft: "auto !important",
                            marginRight: "auto !important",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {item.title}
                        </div>
                      }
                      onClick={() =>
                        handleNavigationCardClick({
                          isLink: item.link,
                          onClick: item.onClick,
                        })
                      }
                    />
                  )}
                  {item.module === true && (
                    <Container
                      sx={{
                        margin: "0px !important",
                        padding: "10px !important",
                        backgroundColor:
                          "var(--sidebar-section-background-color)",
                        borderRadius: "var(--sidebar-section-border-radius)",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "15px",
                          color: "var(--black-text-color)",
                          marginBottom: "5px",
                        }}
                      >
                        {item?.title}
                      </div>
                      {item?.container?.map((moduleitem, index) => (
                        <>
                          {item.module && (
                            <DoliNavigationMainCard
                              id={index}
                              className={index}
                              key={index}
                              active={false}
                              text={<div>{moduleitem.name}</div>}
                              buttonStyles={{
                                gap: "10px",
                              }}
                              icon={moduleitem.icon}
                              onClick={() =>
                                handleNavigationCardClick({
                                  isLink: moduleitem.link,
                                  onClick: moduleitem.onClick,
                                })
                              }
                            />
                          )}
                        </>
                      ))}
                    </Container>
                  )}
                </>
              ))}
            </Container>
          </Container>
        )}
      </Container>
    </>
  );
}
export default DoliSidebarNavigation;
