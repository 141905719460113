import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { QRCodeContext } from "../../Context/QRCodeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function ColorsContent() {
  const { t } = useTranslation("global");
  const { QRColor, setQRColor, QRBackgroundColor, setQRBackgroundColor } =
    React.useContext(QRCodeContext);

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarQrCodeFrameColorsDetailsTitle")}
    >
      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeColorQRColor")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setQRColor} color={QRColor} />
      </AddItemInputContainer>

      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeColorBackgroundColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setQRBackgroundColor}
          color={QRBackgroundColor}
        />
      </AddItemInputContainer>
    </DoliNotMainSidebarDetailContainer>
  );
}

export default ColorsContent;
