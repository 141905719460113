import React from "react";
import CustomListButton from "./CustomListButton";
import ProductListItemContainer from "./ProductListItemContainer";
import { Container } from "@mui/material";
import CheckList from "./CheckList";

function BlankCard({
  cardTitle,
  rightValue,
  primaryBottomButton,
  secondaryBottomButton,
  checkList,
  maxWidth = false,
  cardSx,
  children,
}) {
  return (
    <>
      <ProductListItemContainer
        style={{
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          borderTop: "1px solid var(--border-color)",
          padding: 0,
          maxWidth: maxWidth ? 600 : "",
        }}
        sx={cardSx}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              flex: 5,
              padding: 20,
            }}
          >
            <h2 style={{ margin: 0, fontSize: 20 }}>{cardTitle}</h2>
            {rightValue}
          </div>
          <Container
            sx={{
              ".check-icon > .MuiSvgIcon-root": {
                color: "var(--create-product-primary-button-hover-color)",
              },
            }}
          >
            {checkList && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <CheckList checkList={checkList} gap={3} />
              </div>
            )}
            {children}
          </Container>
          {(primaryBottomButton?.display || secondaryBottomButton?.display) && (
            <Container
              sx={{
                width: { xs: "100%", sm: "100%", md: "calc(100% + 48px)" },
                margin: { sm: "10px -16px !important", md: "10px -24px !important" },
                marginTop: "20px !important",
                marginBotton: "-24px !important",
                display: "flex",
                justifyContent: "flex-start",
                padding: "12px 24px !important",
                backgroundColor: "var(--bottom-background-color)",
                borderEndEndRadius: "8px",
                borderEndStartRadius: "8px",
                gap: 10,
              }}
            >
              {primaryBottomButton?.display && (
                <CustomListButton
                  text={primaryBottomButton.name}
                  onClick={primaryBottomButton.onclick}
                  big={true}
                  type={"submit"}
                  alignButtonLeft={true}
                />
              )}
              {secondaryBottomButton?.display && (
                <CustomListButton
                  text={secondaryBottomButton.name}
                  onClick={secondaryBottomButton.onclick}
                  big={true}
                  primary={false}
                  type={"submit"}
                  alignButtonLeft={false}
                />
              )}
            </Container>
          )}
        </div>
      </ProductListItemContainer>
    </>
  );
}

export default BlankCard;
