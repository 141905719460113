import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import { useTranslation } from "react-i18next";
import { InformationsContext } from "../../Context/InformationsContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function Wifi() {
  const { t } = useTranslation("global");
  const { networkName, setNetworkName, password, setPassword } =
    React.useContext(InformationsContext);
  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarSettingsWifiDetailsTitle")}
    >
      <AddItemInputContainer
        setter={setNetworkName}
        value={networkName}
        name={t("settingsNetworkName")}
        titleFontSize={15}
      />
      <AddItemInputContainer
        setter={setPassword}
        value={password}
        name={t("textPassword")}
        titleFontSize={15}
      />
    </DoliNotMainSidebarDetailContainer>
  );
}

export default Wifi;
