import React from "react";
import InputTemplate from "./InputTemplate";
import CallIcon from "@mui/icons-material/Call";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SocialMedias = ({ socialNetworks }) => {
  const openLink = (link) => {
    if (!link) {
      console.error("No link provided");
      return;
    }
    if (typeof window !== "undefined") {
      window.open(link);
    } else {
      console.error("Cannot open a link in a non-browser environment");
    }
  };

  return (
    <>
      {socialNetworks?.instagram !== (undefined || "") && (
        <InputTemplate
          leftIcon={
            <InstagramIcon
              sx={{ height: 24, padding: "0px 24px" }}
              htmlColor={"var(--icon-color)"}
            />
          }
          rightIcon={{
            icon: (
              <ArrowForwardIosIcon
                sx={{ height: 24, padding: "0px 24px" }}
                htmlColor={"var(--icon-color)"}
              />
            ),
            onClick: () => {
              openLink(`https://instagram.com/${socialNetworks.instagram}`);
            },
          }}
        >
          <div>
            <p
              style={{
                fontSize: "var(--text-size)",
                margin: 0,
                color: "var(--text-color)",
                fontWeight: "500",
                padding: "12px 12px 12px 0px",
              }}
            >
              Instagram
            </p>
          </div>
        </InputTemplate>
      )}
      {socialNetworks?.facebook !== (undefined || "") && (
        <InputTemplate
          leftIcon={
            <FacebookIcon
              sx={{ height: 24, padding: "0px 24px" }}
              htmlColor={"var(--icon-color)"}
            />
          }
          rightIcon={{
            icon: (
              <ArrowForwardIosIcon
                sx={{ height: 24, padding: "0px 24px" }}
                htmlColor={"var(--icon-color)"}
              />
            ),
            onClick: () => {
              openLink(`https://facebook.com/${socialNetworks.facebook}`);
            },
          }}
        >
          <div>
            <p
              style={{
                fontSize: "var(--text-size)",
                margin: 0,
                color: "var(--text-color)",
                fontWeight: "500",
                padding: "12px 12px 12px 0px",
              }}
            >
              Facebook
            </p>
          </div>
        </InputTemplate>
      )}
      {socialNetworks?.x !== (undefined || "") && (
        <InputTemplate
          leftIcon={
            <XIcon
              sx={{ height: 24, padding: "0px 24px" }}
              htmlColor={"var(--icon-color)"}
            />
          }
          rightIcon={{
            icon: (
              <ArrowForwardIosIcon
                sx={{ height: 24, padding: "0px 24px" }}
                htmlColor={"var(--icon-color)"}
              />
            ),
            onClick: () => {
              openLink(`https://x.com/${socialNetworks.x}`);
            },
          }}
        >
          <div>
            <p
              style={{
                fontSize: "var(--text-size)",
                margin: 0,
                color: "var(--text-color)",
                fontWeight: "500",
                padding: "12px 12px 12px 0px",
              }}
            >
              X (Twitter)
            </p>
          </div>
        </InputTemplate>
      )}
    </>
  );
};

export default SocialMedias;
