import React from "react";

const StarIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    fill={color}
    version="1"
    viewBox="0 0 128 128"
  >
    <path
      d="M550 979c-41-83-78-153-82-155-4-3-80-16-169-29s-167-29-173-35c-7-7 27-48 116-138l127-127-30-165c-16-91-29-168-29-172 0-20 49-2 180 67 79 41 146 75 151 75s72-34 150-75c129-69 179-87 179-67 0 4-13 81-29 172l-30 165 127 127c93 94 123 131 115 139s-291 58-336 59c-4 0-42 70-84 155-50 100-83 155-93 155s-42-54-90-151z"
      transform="matrix(.1 0 0 -.1 0 128)"
    ></path>
  </svg>
);

export default StarIcon;
