import * as React from "react";
import { Container } from "@mui/material";
import CustomListButton from "../CustomListButton";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { bannerImagesList } from "../../../bannerImagesList";

export default function ChooseImageHorizonalList({
  image,
  setImage,
  customImageSelected,
  setCustomImageSelected,
}) {
  const [customImage, setCustomImage] = React.useState(null);
  const [position, setPosition] = React.useState(0);

  const handleImageClick = (image) => {
    setCustomImageSelected(null);
    setImage(image);
  };

  const handleCustomImageUpload = (event) => {
    setImage(null);
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop();
      const reader = new FileReader();
      reader.onloadend = () => {
        setCustomImage({
          name: file.name,
          image: reader.result,
          extension: fileExtension,
        });
        setCustomImageSelected({
          name: file.name,
          image: reader.result,
          extension: fileExtension,
        });
        setImage(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCustomImageClick = () => {
    document.getElementById("customImageUpload").click();
  };

  const handleScrollButton = (right = false) => {
    const container = document.getElementById("container");
    const scrollAmount = 120;
    const newPosition = right
      ? position + scrollAmount
      : position - scrollAmount;

    setPosition(newPosition);
    container.scrollTo({
      left: newPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          gap: 20,
          marginTop: 20,
        }}
        sx={{
          width: "100%",
        }}
      >
        <div
          onClick={() => handleScrollButton(false)}
          style={{
            width: 40,
            height: 40,
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            style={{
              color: "var(--gray-icon-color)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: 40,
              height: 40,
            }}
          />
        </div>
        <div
          id="container"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 20,
            overflowX: "hidden",
            overflowY: "hidden",
            height: "140px",
            padding: "0px 20px 0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomListButton
              primary={false}
              onClick={handleCustomImageClick}
              big={true}
              type={"button"}
              styles={{
                width: "100px",
                maxHeight: 100,
                padding: "0px 16px",
                fontSize: 16,
                fontWeight: "500 !important",
                borderStyle: customImageSelected
                  ? "solid"
                  : "dotted !important",
                flexDirection: "column",
                height: "100px",
                width: "100px",
                borderRadius: "10px",
                border: customImageSelected
                  ? "2px solid var(--create-product-primary-button-color) !important"
                  : "",
              }}
              iconBefore={
                customImage !== null ? (
                  <></>
                ) : (
                  <ImageOutlinedIcon
                    style={{
                      color: "var(--gray-icon-color)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: 40,
                      height: 40,
                    }}
                  />
                )
              }
              text={
                customImage !== null ? (
                  <img
                    src={customImage?.image}
                    style={{
                      width: "90px",
                      height: "90px",
                      maxHeight: 90,
                      objectFit: "cover",
                      borderRadius: 10,
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />
            <input
              type="file"
              id="customImageUpload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleCustomImageUpload}
            />
          </div>
          {bannerImagesList.map((imageFromList) => (
            <img
              onClick={() => handleImageClick(imageFromList)}
              key={imageFromList.name}
              src={imageFromList.image}
              alt={imageFromList.name}
              style={{
                width: 100,
                height: 100,
                borderRadius: 10,
                objectFit: "cover",
                cursor: "pointer",
                border: image
                  ? imageFromList.name === image.name
                    ? "2px solid var(--create-product-primary-button-color)"
                    : ""
                  : "",
              }}
            />
          ))}
        </div>
        <div
          onClick={() => handleScrollButton(true)}
          style={{
            width: 40,
            height: 40,
            cursor: "pointer",
          }}
        >
          <ArrowForwardIosIcon
            style={{
              color: "var(--gray-icon-color)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: 40,
              height: 40,
            }}
          />
        </div>
      </Container>
    </>
  );
}
