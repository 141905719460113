import * as React from "react";
import Container from "@mui/material/Container";
import TextField from "./TextField";
import CustomListButton from "./CustomListButton";

export default function AddItemInputContainer({
  name,
  widthName = "auto",
  description,
  placeholder,
  titleFontSize,
  subTitleFontSize,
  type,
  rows,
  value,
  setter,
  price,
  required = false,
  notTextField = false,
  startAdornment,
  endAdornment,
  removeHorizontalSpace = false,
  style,
  onClickTextField,
  inputValueProps,
  rightName,
  heightProps,
  nameProps,
  autoComplete,
  fontSize,
  descriptionColor,
  disabled = false,
  sxStyle,
  reinitializeFunction,
  children,
}) {
  return (
    <Container
      style={{
        margin: removeHorizontalSpace ? 0 : "",
        padding: removeHorizontalSpace ? 0 : "",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
      }}
      sx={{
        width: "100%",
        padding: "0px 5px !important",
        gap: "3px",
        height: "content",
        ...sxStyle,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: widthName,
          }}
        >
          <div
            style={{
              fontSize: titleFontSize ?? 17,
              fontWeight: "500",
              color: "var(--primary-text-color)",
              textAlign: "start",
              marginBottom: 6,
              width: widthName,
            }}
          >
            {name}
          </div>
          {description && (
            <div
              style={{
                fontSize: subTitleFontSize ?? 15,
                fontWeight: "400",
                color: descriptionColor
                  ? descriptionColor
                  : "var(--secondary-text-color)",
                textAlign: "start",
                marginBottom: 5,
                marginTop: -4,
              }}
            >
              {description}
            </div>
          )}
        </div>
        {rightName && rightName}
      </div>
      {reinitializeFunction && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <CustomListButton
            text={"Restaurer la valeur par défaut"}
            primary={true}
            onClick={() => reinitializeFunction()}
            height="30px"
            backgroundColorProps={"transparent"}
            backgroundColorHoverProps={"rgba(0, 0, 0, 0.04)"}
            borderColorProps={"transparent"}
            styles={{
              padding: "0px",
              margin: "-10px 0px 0px 0px",
              fontSize: "14px",
              fontWeight: "500",
              color: "var(--purple-color)",
              "&:hover": {
                padding: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          />
        </div>
      )}
      {!notTextField && (
        <div style={{ width: "100%" }} className={"textfiel-add"}>
          <TextField
            fontSize={fontSize}
            name={nameProps}
            autoComplete={autoComplete}
            onClick={onClickTextField}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            rows={rows}
            setter={setter}
            price={price}
            value={value || inputValueProps}
            required={required}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            inputValueProps={inputValueProps}
            heightProps={heightProps}
          />
        </div>
      )}
      {notTextField && children}
    </Container>
  );
}
