import React from "react";
import Container from "@mui/material/Container";
import { useTitle } from "../../App";
import { useTranslation } from "react-i18next";
import DoliHeaderCard from "../common/DoliHeaderCard";
import { FullPageSxStyle } from "../common/FullPageStyle";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

function Orders() {
  useTitle("DoliQR - Order");
  const { t } = useTranslation("global");

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: { xs: "0px !important", sm: "10px !important" },
          ...FullPageSxStyle,
        }}
      >
        <DoliHeaderCard>
          <Container
            sx={{
              margin: "0px !important",
              padding: "0px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "var(--white-text-color)",
              fontSize: { xs: "1.5rem", sm: "2rem" },
              ...FullPageSxStyle,
            }}
          >
            <div style={{ fontWeight: "600" }}>Vos ventes</div>
            <div style={{ fontWeight: "500", fontSize: 20 }}>
              Reçevez vos commandes et paiments en temps réel.
            </div>
          </Container>
        </DoliHeaderCard>
        <DoliTopPageTitle title="Vos ventes et commandes" />
        <DoliHeaderCard
          style={{
            background: "none",
            backgroundColor: "white",
            border: "1px solid var(--purple-color)",
            padding: "20px 20px !important",
          }}
        >
          <Container
            sx={{
              margin: "0px !important",
              padding: "0px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "var(--purple-color)",
              fontSize: { xs: "1.5rem", sm: "2rem" },
              ...FullPageSxStyle,
            }}
          >
            <div style={{ fontWeight: "500", fontSize: 20 }}>
              Cette fonctionnalité est en cours de développement et sera
              disponible dans les prochaines semaines.
            </div>
          </Container>
        </DoliHeaderCard>
      </Container>
    </>
  );
}

export default Orders;
