import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CloseIcon from "@mui/icons-material/Close";
import { MenusContext } from "../Context/MenusContext";
import { SettingsContext } from "../Context/SettingsContext";
import { useTranslation } from "react-i18next";
//import { MenusContext } from "../Context/MenusContext";

function EditPromotionalText({ closeModal }) {
  const { t } = useTranslation("global");
  const { updatePromotionalText, settings } = React.useContext(SettingsContext);
  const [text, setText] = React.useState(settings.promotionalText);

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          gap: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 20,
            }}
          >
            {t("promotionalModalTitle")}
          </div>
          <div
            style={{
              fontWeight: "500",
              fontSize: 18,
            }}
          >
            {t("promotionalTextSubtitle")}
          </div>
        </div>
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
            ":hover": {
              backgroundColor: "var(--white-hover-color)",
              borderRadius: "var(--borderRadiusCard)",
            },
          }}
          onClick={() => closeModal()}
          style={{
            marginRight: "17px",
            height: "34px",
            width: "34px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal()}
            />
          </div>
        </Container>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <AddItemInputContainer
            name={t("promotionalTextfielTitle")}
            placeholder={t("promotionalTextfielPlaceholder")}
            rows={2}
            setter={setText}
            value={text}
          />
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => {
              updatePromotionalText(text, () => closeModal());
            }}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default EditPromotionalText;
