import React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import AddItemInputContainer from "../common/AddItemInputContainer";
import CustomListButton from "../common/CustomListButton";
import { MenusContext } from "../Context/MenusContext";
import ChooseMenuIconModal from "../ChooseMenuIcon/ChooseMenuIconModal";
import { iconsList } from "../../iconsList";

function CreateMenu({ closeModal, alignButtonLeft = false }) {
  const { addMenu } = React.useContext(MenusContext);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [openChooseIconModal, setOpenChooseIconModal] = React.useState(false);
  const [iconChoosed, setIcon] = React.useState("fork");

  const handleOpenChooseIconModal = () => {
    setOpenChooseIconModal(true);
  };

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
            ":hover": {
              backgroundColor: "var(--white-hover-color)",
              borderRadius: "var(--borderRadiusCard)",
            },
          }}
          onClick={() => handleOpenChooseIconModal()}
          style={{
            marginRight: "17px",
            height: "34px",
            width: "34px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "24px",
              width: "24px",
              display: "flex",
            }}
          >
            {iconsList.find((icon) => icon.name === iconChoosed).icon}
          </div>
        </Container>
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Nouveau Menu
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <CustomListButton
            onClick={() => handleOpenChooseIconModal()}
            text={"Choisir une icône"}
            styles={{
              margin: { xs: "0px 16px !important", sm: "0px 24px !important" },
            }}
          />
          <AddItemInputContainer
            name={"Nom du Menu"}
            value={name}
            setter={setName}
            required={true}
          />
          <AddItemInputContainer
            name={"Description du Menu"}
            value={description}
            rows={2}
            setter={setDescription}
          />
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => {
              addMenu(name, description, iconChoosed, closeModal);
              closeModal();
            }}
            big={true}
            type={"submit"}
          />
        </div>
      </form>

      {openChooseIconModal && (
        <ChooseMenuIconModal
          open={openChooseIconModal}
          handleClose={() => setOpenChooseIconModal(false)}
          saveFunction={true}
          actualIcon={iconChoosed}
          setIcon={setIcon}
        />
      )}
    </>
  );
}

export default CreateMenu;
