import * as React from "react";
import Banner from "./Banner";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { basicsSx } from "../IphonePreviewUtils";

export default function Itemview({ item }) {
  const { t } = useTranslation("global");

  console.log(item.allergens);
  return (
    <Container
      sx={{
        ...basicsSx,
        padding: "0px !important",
        margin: "0px !important",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
        borderBottom: "var(--border)",
        width: "100%",
      }}
    >
      <Container
        sx={{
          ...basicsSx,
          padding: "0px !important",
          margin: "0px !important",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
          borderBottom: "var(--border)",
          width: "100%",
        }}
      >
        <Banner
          height={224}
          restaurantName={item.name}
          bannerUrl={item.illustrationUrl}
          image={item.image}
          isBanner={false}
        />
        <Container
          sx={{
            padding: "0px !important",
            margin: "0px 0px 8px 0px !important",
          }}
        >
          <div style={{ padding: "6px 16px 0px" }}>
            {item.name && (
              <h1
                style={{
                  fontSize: "var(--title-text-size)",
                  fontWeight: "700",
                }}
              >
                {item.name}
              </h1>
            )}
            {item.price && (
              <h2
                style={{
                  fontSize: "var(--text-size)",
                  fontWeight: "700",
                  margin: 0,
                  color: "var(--subtitles-color)",
                }}
              >
                {item.price} €
              </h2>
            )}
            {item.description && (
              <p
                style={{
                  fontSize: "var(--text-size)",
                  margin: 0,
                  marginTop: "8px",
                  color: "var(--text-color)",
                  opacity: 0.8,
                }}
              >
                {item.description}
              </p>
            )}
            {item?.allergens?.length > 0 && (
              <>
                <div
                  style={{
                    fontSize: "var(--allergens-title-font-size)",
                    color: "var(--allergens-title-color)",
                    fontWeight: "400",
                    marginTop: "8px",
                  }}
                >
                  {t("textAllergens")} :
                </div>
                <p
                  style={{
                    fontSize: "var(--allergens-text-font-size)",
                    margin: 0,
                    marginTop: "2px",
                    color: "var(--allergens-text-color)",
                    opacity: 0.8,
                  }}
                >
                  {item.allergens
                    .map((allergen) => t(`${allergen}`))
                    .join(", ")}
                </p>
              </>
            )}
          </div>
        </Container>
      </Container>
    </Container>
  );
}
