import React from "react";
import CustomListButton from "../common/CustomListButton";
import LoginPageTemplate from "./LoginPageTemplate";
import LoginInputTextField from "./LoginInputTextField";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { validateMail } from "../../services/loginService";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("loginPageTitle")}`);

  const { handleSignIn } = React.useContext(AuthContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const inputsChecker = () => {
    console.log("email", email);
    console.log("password", password);
    if (email === "" || password === "") {
      setError("Veuillez saisir un e-mail et un mot de passe");
    }
    const emailValid = validateMail(email);
    const passwordValid = password.length >= 6;
    if (!emailValid) {
      setError("Veuillez saisir un e-mail valide");
    } else if (!passwordValid) {
      setError("Veuillez saisir un mot de passe valide");
    }
    return emailValid && passwordValid;
  };

  const handleSubmit = async () => {
    if (!inputsChecker()) {
      return;
    }
    try {
      await handleSignIn(email, password.toString(), setError);
    } catch (error) {
      console.log("} catch (error) {");
      setError(error.message);
    }
  };

  const handleCreateAccountClick = () => {
    navigate(`/sign-up`);
  };

  const handleForgotPasswordClick = () => {
    navigate(`/forgot-password`);
  };

  return (
    <LoginPageTemplate
      title={t("loginPageTitle")}
      primaryButton={{
        text: t("loginPageAuthText"),
        onClick: () => handleSubmit(),
      }}
      error={error}
      textFields={
        <>
          <LoginInputTextField
            title={t("textEmail")}
            setter={setEmail}
            type={"email"}
            value={email}
          />
          <LoginInputTextField
            forgotTextFiel={true}
            title={t("textPassword")}
            forgotClick={handleForgotPasswordClick}
            setter={setPassword}
            type={"password"}
            value={password}
          />
        </>
      }
      headerButton={
        <>
          <div
            style={{
              fontSize: 15,
              color: "var(--primary-text-color)",
            }}
          >
            {t("loginPageQuestion")}
          </div>
          <CustomListButton
            styles={{
              width: "fit-content",
              fontSize: 16,
              paddingLeft: "16px",
              paddingRight: "16px",
              color: "var(--primary-text-color)",
              fontWeight: "500 !important",
              maxHeight: 36,
            }}
            onClick={handleCreateAccountClick}
            text={t("loginPageRegisterText")}
            type={"submit"}
            primary={false}
          />
        </>
      }
    />
  );
}

export default Login;
