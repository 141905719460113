import TacosImage from "./components/assets/imagesAvailable/tacos.jpg";

// Desserts
import BabaRhum from "./components/assets/imagesAvailable/desserts/baba-rhum.jpg";
import CremeBrulee from "./components/assets/imagesAvailable/desserts/creme-brulee.jpg";
import FondantAuChocolat from "./components/assets/imagesAvailable/desserts/fondant-au-chocolat.jpg";
import NouggatGlace from "./components/assets/imagesAvailable/desserts/nouggat-glace.jpg";
import PannaCottaFruitsRouges from "./components/assets/imagesAvailable/desserts/panna-cotta-fruits-rouges.jpg";
import Profiteroles from "./components/assets/imagesAvailable/desserts/profiteroles.jpg";
import Tiramisu2 from "./components/assets/imagesAvailable/desserts/tiramisu-2.jpg";
import Tiramisu from "./components/assets/imagesAvailable/desserts/tiramisu.jpg";

// Gnocchi
import GnocciFromage from "./components/assets/imagesAvailable/gnocci/gnocci-fromage.jpg";
import GnocciTomatePiment from "./components/assets/imagesAvailable/gnocci/gnocci-tomate-piment.jpg";
import GnocciTomate from "./components/assets/imagesAvailable/gnocci/gnocci-tomate.jpg";
import GnocciTruffe from "./components/assets/imagesAvailable/gnocci/gnocci-truffe.jpg";

// Italien
import AuberginesAllaParmigiana from "./components/assets/imagesAvailable/italien/aubergines-alla-parmigiana.jpg";
import Cannelloni2 from "./components/assets/imagesAvailable/italien/cannelloni-2.jpg";
import Cannelloni from "./components/assets/imagesAvailable/italien/cannelloni.jpg";
import EscalopeMilanaise from "./components/assets/imagesAvailable/italien/escalope-milanaise.jpg";
import EscalopeValdostana from "./components/assets/imagesAvailable/italien/escalope-valdostana.jpg";
import Lasagne from "./components/assets/imagesAvailable/italien/lasagne.jpg";

// Pâtes - Linguine
import LinguineArrabiata from "./components/assets/imagesAvailable/pates/linguine/linguine-arrabiata.jpg";
import LinguineBolognaise from "./components/assets/imagesAvailable/pates/linguine/linguine-bolognaise.jpg";
import LinguineCarbonara from "./components/assets/imagesAvailable/pates/linguine/linguine-carbonara.jpg";
import LinguineFruitsDeMer from "./components/assets/imagesAvailable/pates/linguine/linguine-fruits-de-mer.jpg";
import LinguineGorgonzola from "./components/assets/imagesAvailable/pates/linguine/linguine-gorgonzola.jpg";
import LinguineMozza from "./components/assets/imagesAvailable/pates/linguine/linguine-mozza.jpg";
import LinguinePesto from "./components/assets/imagesAvailable/pates/linguine/linguine-pesto.jpg";
import LinguineSaumon from "./components/assets/imagesAvailable/pates/linguine/linguine-saumon.jpg";
import LinguineTomate from "./components/assets/imagesAvailable/pates/linguine/linguine-tomate.jpg";
import LinguineTruffe from "./components/assets/imagesAvailable/pates/linguine/linguine-truffe.jpg";

// Pâtes - Mezzaluna
import MezzalunaPesto from "./components/assets/imagesAvailable/pates/mezzaluna/mezzaluna-pesto.jpg";

// Pâtes - Penne
import PenneArrabiata from "./components/assets/imagesAvailable/pates/penne/penne-arrabiata.jpg";
import PenneBolognaise from "./components/assets/imagesAvailable/pates/penne/penne-bolognaise.jpg";
import PenneCarbonara from "./components/assets/imagesAvailable/pates/penne/penne-carbonnara.jpg";
import PenneFromage from "./components/assets/imagesAvailable/pates/penne/penne-fromage.jpg";
import PenneFruitsDeMer from "./components/assets/imagesAvailable/pates/penne/penne-fruits-de-mer.jpg";
import PenneMozza from "./components/assets/imagesAvailable/pates/penne/penne-mozza.jpg";
import PennePesto from "./components/assets/imagesAvailable/pates/penne/penne-pesto.jpg";
import PenneSaumon from "./components/assets/imagesAvailable/pates/penne/penne-saumon.jpg";
import PenneTomate from "./components/assets/imagesAvailable/pates/penne/penne-tomate.jpg";
import PenneTruffe from "./components/assets/imagesAvailable/pates/penne/penne-truffe.jpg";

// Pâtes - Spaghetti
import SpaghettiTomate from "./components/assets/imagesAvailable/pates/spaghetti/spaghetti-tomate.jpg";

// Pâtes - Tagliatelle
import TagliatelleBolognaise from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-bolognaise.jpg";
import TagliatelleCarbonara from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-carbonara.jpg";
import TagliatelleCrevette from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-crevette.jpg";
import TagliatelleFruitsDeMer from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-fruits-de-mer.jpg";
import TagliatellePesto from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-pesto.jpg";
import TagliatellePiment from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-piment.jpg";
import TagliatelleSaumon from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-saumon.jpg";
import TagliatelleTomateMozza from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-tomate-mozza.jpg";
import TagliatelleTomato from "./components/assets/imagesAvailable/pates/tagliatelle/tagliatelle-tomate.jpg";

// Ravioli
import RavioliTruffe from "./components/assets/imagesAvailable/ravioli/ravioli-truffe.jpg";

export const imagesList = [
  {
    name: "tacos",
    image: TacosImage,
    category: ["mexicain"],
    subcategory: ["tacos"],
  },
  // Desserts
  {
    name: "baba-rhum",
    image: BabaRhum,
    category: ["dessert", "francais"],
    subcategory: ["patisserie"],
  },
  {
    name: "creme-brulee",
    image: CremeBrulee,
    category: ["dessert", "francais"],
    subcategory: ["creme"],
  },
  {
    name: "fondant-au-chocolat",
    image: FondantAuChocolat,
    category: ["dessert"],
    subcategory: ["chocolat"],
  },
  {
    name: "nouggat-glace",
    image: NouggatGlace,
    category: ["dessert", "glace"],
    subcategory: ["glace"],
  },
  {
    name: "panna-cotta-fruits-rouges",
    image: PannaCottaFruitsRouges,
    category: ["dessert", "italien"],
    subcategory: ["creme"],
  },
  {
    name: "profiteroles",
    image: Profiteroles,
    category: ["dessert", "francais"],
    subcategory: ["patisserie"],
  },
  {
    name: "tiramisu-2",
    image: Tiramisu2,
    category: ["dessert", "italien"],
    subcategory: ["italien"],
  },
  {
    name: "tiramisu",
    image: Tiramisu,
    category: ["dessert", "italien"],
    subcategory: ["italien"],
  },
  // Gnocchi
  {
    name: "gnocci-fromage",
    image: GnocciFromage,
    category: ["pates", "italien"],
    subcategory: ["gnocci"],
  },
  {
    name: "gnocci-tomate-piment",
    image: GnocciTomatePiment,
    category: ["pates", "italien"],
    subcategory: ["gnocci"],
  },
  {
    name: "gnocci-tomate",
    image: GnocciTomate,
    category: ["pates", "italien"],
    subcategory: ["gnocci"],
  },
  {
    name: "gnocci-truffe",
    image: GnocciTruffe,
    category: ["pates", "italien"],
    subcategory: ["gnocci"],
  },
  // Italien
  {
    name: "aubergines-alla-parmigiana",
    image: AuberginesAllaParmigiana,
    category: ["italien"],
    subcategory: ["legumes"],
  },
  {
    name: "cannelloni-2",
    image: Cannelloni2,
    category: ["pates", "italien"],
    subcategory: ["cannelloni"],
  },
  {
    name: "cannelloni",
    image: Cannelloni,
    category: ["pates", "italien"],
    subcategory: ["cannelloni"],
  },
  {
    name: "escalope-milanaise",
    image: EscalopeMilanaise,
    category: ["italien"],
    subcategory: ["viande"],
  },
  {
    name: "escalope-valdostana",
    image: EscalopeValdostana,
    category: ["italien"],
    subcategory: ["viande"],
  },
  {
    name: "lasagne",
    image: Lasagne,
    category: ["pates", "italien"],
    subcategory: ["lasagne"],
  },
  // Pâtes - Linguine
  {
    name: "linguine-arrabiata",
    image: LinguineArrabiata,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-bolognaise",
    image: LinguineBolognaise,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-carbonara",
    image: LinguineCarbonara,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-fruits-de-mer",
    image: LinguineFruitsDeMer,
    category: ["pates", "italien", "fruits de mer"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-gorgonzola",
    image: LinguineGorgonzola,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-mozza",
    image: LinguineMozza,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-pesto",
    image: LinguinePesto,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-saumon",
    image: LinguineSaumon,
    category: ["pates", "italien", "poisson"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-tomate",
    image: LinguineTomate,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  {
    name: "linguine-truffe",
    image: LinguineTruffe,
    category: ["pates", "italien"],
    subcategory: ["linguine"],
  },
  // Pâtes - Mezzaluna
  {
    name: "mezzaluna-pesto",
    image: MezzalunaPesto,
    category: ["pates", "italien"],
    subcategory: ["mezzaluna"],
  },
  // Pâtes - Penne
  {
    name: "penne-arrabiata",
    image: PenneArrabiata,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-bolognaise",
    image: PenneBolognaise,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-carbonara",
    image: PenneCarbonara,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-fromage",
    image: PenneFromage,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-fruits-de-mer",
    image: PenneFruitsDeMer,
    category: ["pates", "italien", "fruits de mer"],
    subcategory: ["penne"],
  },
  {
    name: "penne-mozza",
    image: PenneMozza,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-pesto",
    image: PennePesto,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-saumon",
    image: PenneSaumon,
    category: ["pates", "italien", "poisson"],
    subcategory: ["penne"],
  },
  {
    name: "penne-tomate",
    image: PenneTomate,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  {
    name: "penne-truffe",
    image: PenneTruffe,
    category: ["pates", "italien"],
    subcategory: ["penne"],
  },
  // Pâtes - Spaghetti
  {
    name: "spaghetti-tomate",
    image: SpaghettiTomate,
    category: ["pates", "italien"],
    subcategory: ["spaghetti"],
  },
  // Pâtes - Tagliatelle
  {
    name: "tagliatelle-bolognaise",
    image: TagliatelleBolognaise,
    category: ["pates", "italien"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-carbonara",
    image: TagliatelleCarbonara,
    category: ["pates", "italien"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-crevette",
    image: TagliatelleCrevette,
    category: ["pates", "italien", "fruits de mer"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-fruits-de-mer",
    image: TagliatelleFruitsDeMer,
    category: ["pates", "italien", "fruits de mer"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-pesto",
    image: TagliatellePesto,
    category: ["pates", "italien"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-piment",
    image: TagliatellePiment,
    category: ["pates", "italien"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-saumon",
    image: TagliatelleSaumon,
    category: ["pates", "italien", "poisson"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-tomate-mozza",
    image: TagliatelleTomateMozza,
    category: ["pates", "italien"],
    subcategory: ["tagliatelle"],
  },
  {
    name: "tagliatelle-tomate",
    image: TagliatelleTomato,
    category: ["pates", "italien"],
    subcategory: ["tagliatelle"],
  },
  // Ravioli
  {
    name: "ravioli-truffe",
    image: RavioliTruffe,
    category: ["pates", "italien"],
    subcategory: ["ravioli"],
  },
];
