import * as React from "react";
import CustomListButton from "../CustomListButton";
import { Container } from "@mui/material";
import ChooseImageLibrary from "./ChooseImageLibrary";
import ImageIcon from "@mui/icons-material/Image";

export default function UploadFolderButton({
  image,
  customImage,
  saveFunction,
  imageListToUse,
  showCategory,
}) {
  const [displayChooseImageModal, setDisplayChooseImageModal] =
    React.useState(false);
  const [imageToDisplay, setImageToDisplay] = React.useState(null);

  React.useEffect(() => {
    setImageToDisplay(handleImageToUse());
  }, [image, customImage]);

  const handleImageToUse = () => {
    console.log("handleImageToUse", customImage, image);
    if (customImage) {
      console.log("handleImageToUse in if");
      if (customImage?.value && customImage?.isOur) {
        console.log("handleImageToUse in if 1");
        return imageListToUse.find((img) => img.name === customImage.value)
          ?.image;
      } else if (!customImage?.value && !customImage?.image && image?.image) {
        console.log("handleImageToUse in if 2", customImage, image);
        if (image?.image) {
          return image.image;
        }
      } else if (!customImage?.value && customImage?.image) {
        console.log("handleImageToUse in if 3");
        return customImage?.image;
      } else if (customImage?.value && !customImage?.isOur) {
        console.log("handleImageToUse in if 4");
        return customImage?.value;
      } else if (!customImage?.value && !customImage?.isOur && !image) {
        console.log("handleImageToUse in if 5");
        return null;
      } else if (image?.isOur && image?.value?.name) {
        console.log("handleImageToUse in if 6");
        return imageListToUse.find((img) => img.name === image?.value?.name)
          ?.image;
      }
    } else if (image) {
      console.log("handleImageToUse in else");
      if (image?.isOur && image?.value?.name) {
        return imageListToUse.find((img) => img.name === image?.value?.name)
          ?.image;
      } else if (image?.image) {
        return image.image;
      }
    } else if (!image && !customImage) {
      return null;
    }
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "0px !important",
          height: "auto",
        }}
      >
        <CustomListButton
          height="auto"
          onClick={() => setDisplayChooseImageModal(true)}
          styles={{
            width: "100%",
            maxHeight: 200,
            padding: image ? "0px !important" : "30px !important",
            fontSize: 16,
            fontWeight: "500 !important",
            borderStyle: "dotted !important",
            flexDirection: "column",
          }}
          text={
            imageToDisplay !== null ? (
              <img
                src={imageToDisplay}
                style={{
                  width: "200px",
                  height: "200px",
                  maxHeight: 200,
                  objectFit: "cover",
                  borderRadius: 4,
                }}
                alt="Selected"
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: 12,
                }}
              >
                <ImageIcon
                  style={{
                    fontSize: "var(--icon-size)",
                    color: "var(--purple-color)",
                    marginRight: "8px",
                  }}
                />
                <div
                  style={{
                    fontSize: 14,
                    color: "var(--purple-color)",
                  }}
                >
                  Parcourir ou mettre en ligne une image
                </div>
              </div>
            )
          }
          primary={false}
        />
      </Container>
      {displayChooseImageModal && (
        <ChooseImageLibrary
          saveFunction={saveFunction}
          open={displayChooseImageModal}
          handleClose={() => setDisplayChooseImageModal(false)}
          imageListToUse={imageListToUse}
          showCategory={showCategory}
          imageToDisplay={imageToDisplay}
        />
      )}
    </>
  );
}
