import { motion, AnimatePresence } from "framer-motion";
import styles from "./Modal.module.css";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ isVisible, onClose, children }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={styles.modalBackdrop}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={styles.modalContent}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                padding: "8px",
              }}
            >
              <div
                onClick={() => onClose()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "var(--background-color)",
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  sx={{ height: 18, width: 18 }}
                  htmlColor={"var(--icon-color)"}
                />
              </div>
            </div>
            <div className={styles.modalBody}>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
