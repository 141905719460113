import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const OpeningStatus = ({ dayHours }) => {
  const { t } = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    const checkOpenStatus = () => {
      const now = new Date();
      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const currentDay = daysOfWeek[now.getDay()];
      const currentTime = now.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      if (!dayHours) {
        setIsOpen(false);
        return;
      }
      const todayHours = dayHours[currentDay];

      if (todayHours) {
        const openTime = todayHours.open;
        const closeTime = todayHours.close;

        if (closeTime < openTime) {
          setIsOpen(
            (currentTime >= openTime && currentTime <= "23:59") ||
              (currentTime >= "00:00" && currentTime < closeTime)
          );
        } else {
          setIsOpen(currentTime >= openTime && currentTime < closeTime);
        }
      } else {
        setIsOpen(false);
      }
    };
    checkOpenStatus();
  }, [dayHours]);

  if (!isClient) {
    return <></>;
  }

  return (
    <>
      {isOpen ? (
        <div
          style={{
            color: "var(--open-text-color)",
            fontSize: "var(--opening-text-font-size)",
            fontWeight: "300",
          }}
        >
          {t("textOpen")}
        </div>
      ) : (
        <div
          style={{
            color: "red",
            fontSize: "var(--close-text-color)",
            fontWeight: "300",
          }}
        >
          {t("textClose")}
        </div>
      )}
    </>
  );
};

export default OpeningStatus;
