const logs = (file, string, args) => {
  const disable = false;
  if (disable) return;
  console.log(`[${file}] ${string}`, args);
};

export const settingsLogs = (string, args) => {
  const file = "SettingsContext";
  logs(file, string, args);
};

export const informationsLogs = (string, args) => {
  const file = "InformationsContext";
  logs(file, string, args);
};

export const navigationContextLogs = (string, args) => {
    const file = "DoliNavigationContext";
    logs(file, string, args);
  };
  