import * as React from "react";
import AddMember from "./AddMember";
import CustomModal from "../../Modal/Modal";

export default function AddMemberModal({ open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <AddMember closeModal={handleClose} />
    </CustomModal>
  );
}
