import React from "react";
import Container from "@mui/material/Container";
import { ClickAwayListener, Tooltip } from "@mui/material";

function DoliNotificationPopup({
  title,
  message,
  open,
  setOpen,
  showArrow = true,
}) {
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow={showArrow}
        title={
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "white",
                textAlign: "center",
              }}
            >
              {title}
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "white",
                textAlign: "center",
              }}
            >
              {message}
            </div>
          </Container>
        }
      ></Tooltip>
    </ClickAwayListener>
  );
}

export default DoliNotificationPopup;
