import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import { SettingsContext } from "../Context/SettingsContext";
import PreviewPageContent from "../Preview/PreviewPageContent";

export const handlePersonalizeSliderChange = (event, newValue, setter) => {
  setter(newValue);
};

function Personalize() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("personalizePageTitle")}`);

  const { settings } = React.useContext(SettingsContext);
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    if (settings !== null) setDisplay(true);
    if (settings === null) setDisplay(false);
  }, [settings]);

  return <>{display === true && <PreviewPageContent />}</>;
}

export default Personalize;
