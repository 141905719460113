import * as React from "react";
import CustomModal from "../Modal/Modal";
import EditProduct from "./EditProduct";
import { imagesList } from "../../imagesList";

export default function EditProductModal({
  open,
  handleClose,
  id,
  name,
  description,
  price,
  allergensList,
  image,
  customImage,
  categoryId,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <EditProduct
        closeModal={handleClose}
        categoryId={categoryId}
        actualName={name}
        actualDescription={description}
        actualPrice={price}
        actualImage={
          image && image !== "none"
            ? imagesList.find((img) => img.name === image)
            : null
        }
        actualAllergensList={allergensList ?? []}
        actualCustomImage={customImage ? { image: customImage } : null}
        id={id}
      />
    </CustomModal>
  );
}
