import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  onBoardingInformationsStep,
  onBoardingThemeStep,
} from "../../services/onboardingService";
import { UserContext } from "../Context/UserContext";
import { AuthContext } from "../Context/AuthContext";
import {
  createInformation,
  createMerchant,
} from "../../services/merchantService";
import { updateUser } from "../../services/userService";
import { SettingsContext } from "../Context/SettingsContext";
import OnboardingInformations from "../Onboarding/OnboardingInformations";
import OnboardingTheme from "../Onboarding/OnboardingTheme";
import OnboardingQR from "../Onboarding/OnboardingQR";
import SelectBanner from "../Onboarding/SelectBanner";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";

function AddNewRestaurant() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("addNewRestaurantPageTitle")}`);

  const { firebaseUser, checkOnboarding2 } = React.useContext(AuthContext);
  const { setDoliQrUser, changeMerchant } = React.useContext(UserContext);
  const { checkPublicMenuUrl } = React.useContext(SettingsContext);
  const navigate = useNavigate();
  const { stepId } = useParams();
  const [step, setStep] = React.useState(1);
  const [affiliateDropdown, setAffiliateDropdown] = React.useState(
    localStorage.getItem("affiliateDropdown")
  );
  const [establishmentName, setEstablishmentName] = React.useState(
    localStorage.getItem("establishmentName")
  );
  const [publicMenuURL, setPublicMenuURL] = React.useState(
    localStorage.getItem("publicMenuURL")
  );
  const [theme, setTheme] = React.useState(
    localStorage.getItem("theme") ?? "LIGHT_THEME"
  );
  const [image, setImage] = React.useState(null);
  const [customImageSelected, setCustomImageSelected] = React.useState(null);
  const [error, setError] = React.useState("");

  const handleNextStep = async () => {
    if (step === 1) {
      const { isValid, error } = await onBoardingInformationsStep(
        establishmentName,
        publicMenuURL,
        affiliateDropdown,
        checkPublicMenuUrl,
        true
      );
      if (isValid) {
        setStep(2);
        navigate("/user/new-restaurant/2");
        setError("");
      } else {
        setError(error);
      }
    } else if (step === 2) {
      const { isValid, error } = await onBoardingThemeStep(theme);
      if (isValid) {
        setStep(3);
        setError("");
        navigate("/user/new-restaurant/3");
      } else {
        setError(error);
      }
    } else if (step === 3) {
      if (image !== null || customImageSelected !== null) {
        setStep(4);
        setError("");
        await createMerchant(establishmentName, firebaseUser.uid, "OWNER").then(
          async (merchant) => {
            console.log("merchant created", merchant, firebaseUser.uid);
            await updateUser(firebaseUser.uid, [merchant._id])
              .then(async (doliQrUser) => {
                setDoliQrUser(doliQrUser);
                await createInformation(merchant._id, {
                  establishmentName,
                  address: "",
                  publicMenuURL,
                  theme,
                  customImage: customImageSelected,
                  image,
                });
              })
              .then(async () => {
                await changeMerchant(merchant._id, firebaseUser.uid);
              });
          }
        );
      } else {
        setError("Veuillez sélectionner une image de bannière.");
      }
    } else if (step === 4) {
      console.log("} else if (step === 3) {", firebaseUser);
      await checkOnboarding2(firebaseUser).then(() => {
        navigate("/user/dashboard");
      });
    }
  };

  const initialize = React.useCallback(async () => {
    if (stepId === undefined) {
      navigate("/user/new-restaurant/1");
    }
    if (stepId === "4") {
      if (
        !localStorage.getItem("establishmentName") ||
        !localStorage.getItem("publicMenuURL") ||
        !localStorage.getItem("affiliateDropdown")
      ) {
        navigate("/user/new-restaurant/1");
        setStep(1);
      } else if (!localStorage.getItem("theme")) {
        navigate("/user/new-restaurant/2");
        setStep(2);
      } else if (image === null || customImageSelected === null) {
        navigate("/onboarding/3");
        setStep(3);
      }
    } else if (stepId === "3") {
      if (
        !localStorage.getItem("establishmentName") ||
        !localStorage.getItem("publicMenuURL") ||
        !localStorage.getItem("affiliateDropdown")
      ) {
        navigate("/user/new-restaurant/1");
        setStep(1);
      } else if (!localStorage.getItem("theme")) {
        navigate("/user/new-restaurant/2");
        setStep(2);
      }
    } else if (stepId === "2") {
      if (
        !localStorage.getItem("establishmentName") ||
        !localStorage.getItem("publicMenuURL") ||
        !localStorage.getItem("affiliateDropdown")
      ) {
        navigate("/user/new-restaurant/1");
        setStep(1);
      } else {
        setStep(2);
      }
    } else if (stepId === "1") {
      setStep(1);
    }
  }, [stepId, navigate]);

  useEffect(() => {
    console.log("}, [stepId, navigate]);", stepId);
    initialize();
  }, [stepId, navigate, initialize]);

  return (
    <>
      {step === 1 && (
        <OnboardingInformations
          affiliateDropdown={affiliateDropdown}
          setAffiliateDropdown={setAffiliateDropdown}
          establishmentName={establishmentName}
          setEstablishmentName={setEstablishmentName}
          publicMenuURL={publicMenuURL}
          setPublicMenuURL={setPublicMenuURL}
          onPrimaryButtonClick={handleNextStep}
          title={t("addNewRestaurantPageTitle")}
          subtitle={t("addNewRestaurantPageSubTitle")}
          showAffiliateDropdown={false}
          error={error}
        />
      )}
      {step === 2 && (
        <OnboardingTheme
          setTheme={setTheme}
          theme={theme}
          onPrimaryButtonClick={handleNextStep}
          error={error}
        />
      )}
      {step === 3 && (
        <SelectBanner
          error={error}
          image={image}
          setImage={setImage}
          customImageSelected={customImageSelected}
          setCustomImageSelected={setCustomImageSelected}
          onPrimaryButtonClick={handleNextStep}
        />
      )}
      {step === 4 && (
        <OnboardingQR
          affiliateDropdown={affiliateDropdown}
          setAffiliateDropdown={setAffiliateDropdown}
          establishmentName={establishmentName}
          setEstablishmentName={setEstablishmentName}
          publicMenuURL={publicMenuURL}
          setPublicMenuURL={setPublicMenuURL}
          onPrimaryButtonClick={handleNextStep}
          error={error}
        />
      )}
    </>
  );
}

export default AddNewRestaurant;
