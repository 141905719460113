export async function onBoardingInformationsStep(
  establishmentName,
  publicMenuURL,
  affiliateDropdown,
  checkPublicMenuUrl,
  ignoreAffiliateDropdown = false
) {
  let isMenuUrlAvailable = false;
  if (establishmentName && publicMenuURL && publicMenuURL.length >= 3) {
    const { available } = await checkPublicMenuUrl(publicMenuURL);
    isMenuUrlAvailable = available;
  }

  if (!establishmentName) {
    return { isValid: false, error: "Le nom de l'établissement est requis" };
  } else if (!publicMenuURL) {
    return { isValid: false, error: "L'URL du menu public est requise" };
  } else if (publicMenuURL.length < 3) {
    return {
      isValid: false,
      error: "L'URL du menu public doit contenir au moins 3 caractères",
    };
  } else if (!isMenuUrlAvailable) {
    return {
      isValid: false,
      error: "L'URL du menu est déjà utilisée",
    };
  } else if (!ignoreAffiliateDropdown && !affiliateDropdown) {
    return {
      isValid: false,
      error: "Dites-nous comment vous avez entendu parler de DoliQR",
    };
  } else {
    localStorage.setItem("establishmentName", establishmentName);
    localStorage.setItem("publicMenuURL", publicMenuURL);
    localStorage.setItem("affiliateDropdown", affiliateDropdown);
    return { isValid: true };
  }
}

export async function onBoardingThemeStep(theme) {
  if (!theme) {
    return { isValid: false, error: "La sélection du thème est requise" };
  }
  if (theme !== "LIGHT_THEME" && theme !== "DARK_THEME") {
    return { isValid: false, error: "La sélection du thème est invalide" };
  }
  localStorage.setItem("theme", theme);
  console.log("theme step done", theme);
  return { isValid: true };
}

export function clearLocalStorage() {
  localStorage.removeItem("establishmentName");
  localStorage.removeItem("publicMenuURL");
  localStorage.removeItem("affiliateDropdown");
  localStorage.removeItem("theme");
}
