import { Container } from "@mui/material";
import React from "react";
import CustomListButton from "../common/CustomListButton";

function OnboardingTemplate({ inputs, subtitle, title, primaryButton, error }) {
  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px !important",
          maxWidth: 650, // TODO(chris) : Change it to change width eg: for banner
          height: "100%",
        }}
        sx={{
          padding: "34px 0px !important",
          maxWidth: 650, // TODO(chris) : Change it to change width eg: for banner
        }}
      >
        <Container
          sx={{
            ".textfiel-add > input": {
              padding: "0px important!",
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
              marginTop: "auto",
              justifyContent: "center",
              minHeight: { xs: "none", sm: "calc(100vh - 84px)" },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <h1
                style={{
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                {title}
              </h1>

              <div
                style={{
                  padding: 0,
                  margin: 0,
                  marginTop: 16,
                  color: "var(--category-secondary-text-color)",
                  textAlign: "center",
                }}
              >
                {subtitle}
              </div>
              <div
                style={{
                  padding: 0,
                  marginRight: "auto",
                  marginTop: 26,
                  color: "red",
                  textAlign: "left",
                }}
              >
                {error}
              </div>
            </div>
            {inputs}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <CustomListButton
                onClick={() => primaryButton.onClick()}
                styles={{
                  width: "100%",
                  height: 46,
                  fontSize: 16,
                  fontWeight: "600 !important",
                }}
                text={primaryButton.text}
              />
            </div>
          </Container>
          <Container
            sx={{
              display: { xs: "flex", sm: "none" },
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              padding: "32px 0px",
            }}
          ></Container>
        </Container>
      </Container>
    </div>
  );
}

export default OnboardingTemplate;
