import * as React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import CustomListButton from "../common/CustomListButton";
import { useTranslation } from "react-i18next";

export default function CancelSubscription({
  closeModal,
  cancelSubscriptionFunction,
}) {
  const { t } = useTranslation("global");

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          {t("cancelSubscriptionModalTitle")}
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{
            fontWeight: "400",
            fontSize: 20,
          }}
        >
          {t("cancelSubscriptionModalDescription")}
        </div>
      </Container>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          padding: 24,
          gap: 10,
          backgroundColor: "var(--default-background-color)",
        }}
      >
        <CustomListButton
          text={"Annuler"}
          big={true}
          type={"submit"}
          primary={false}
          onClick={() => closeModal()}
        />
        <CustomListButton
          onClick={() => cancelSubscriptionFunction(closeModal)}
          text={t("cancelSubscriptionModalButton")}
          big={true}
          type={"submit"}
          backgroundColorHoverProps={"var(--unwanted-button-color)"}
          backgroundColorProps={"var(--unwanted-button-color)"}
          borderColorProps={"var(--unwanted-button-color)"}
        />
      </div>
    </>
  );
}
