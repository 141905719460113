import * as React from "react";
import { basicsSx } from "../IphonePreviewUtils";
import ProductCard from "../components/ProductCard/ProductCard";
import CategoryHeader from "../components/CategoryHeader/CategoryHeader";
import TopNavigation from "../components/Navigation";
import Itemview from "../components/ItemView";
import Modal from "./Modal";

export default function ItemListView({
  establishmentName,
  menuName,
  categories,
  onBack,
  hideCategories,
}) {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [isModalVisible, setModalVisible] = React.useState(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    openModal();
  };

  return (
    <>
      <div
        style={{
          //paddingTop: "-223px",
        }}
      >
        <TopNavigation
          establishmentName={establishmentName}
          menuName={menuName}
          onBack={onBack}
        />
      </div>
      <div
        style={{
          marginTop: 70,
        }}
      >
        {categories &&
          categories
            ?.sort((a, b) => a.order - b.order)
            .map((category, categoryIndex) => (
              <div
                key={categoryIndex}
                style={{
                  marginBottom: !hideCategories ? 30 : 0,
                }}
              >
                {category.name !== "all_dishes" && !hideCategories && (
                  <CategoryHeader
                    title={category.name}
                    description={category.description}
                    basicsSx={basicsSx}
                  />
                )}
                {category.plats
                  ?.sort((a, b) => a.order - b.order)
                  .map((product) => (
                    <ProductCard
                      onClick={() =>
                        handleItemClick({
                          ...product,
                          illustrationUrl: product.image.custom
                            ? product.image.value
                            : null,
                          image: !product.image.custom
                            ? product.image.value
                            : null,
                        })
                      }
                      key={product._id}
                      name={product.name}
                      description={product.description}
                      price={product.price}
                      basicsSx={basicsSx}
                      illustrationUrl={
                        product.image.custom ? product.image.value : null
                      }
                      image={!product.image.custom ? product.image.value : null}
                    />
                  ))}
              </div>
            ))}
      </div>

      {selectedItem && (
        <Modal isVisible={isModalVisible} onClose={closeModal}>
          <Itemview item={selectedItem} />
        </Modal>
      )}
    </>
  );
}
