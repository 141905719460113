import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CloseIcon from "@mui/icons-material/Close";
import { MenusContext } from "../Context/MenusContext";

function ImportMenu({ closeModal }) {
  const [code, setCode] = React.useState("");
  const { importMenu } = React.useContext(MenusContext);

  const handleClose = () => {
    closeModal();
  }

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Importer un Menu
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <AddItemInputContainer
            name={"Code du Menu"}
            setter={setCode}
            value={code}
            required={true}
          />
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => importMenu(code, handleClose)}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default ImportMenu;
