import * as React from "react";
import Container from "@mui/material/Container";
import { HoverTransitionStyle } from "./FullPageStyle";

export default function ProductListItemContainer({ style, sx, id, children }) {
  return (
    <Container
      sx={{
        margin: "0px !important",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor:
          style?.backgroundColor ?? "var(--product-card-background-color)",
        padding: "20px",
        borderLeft: "1px solid var(--border-color)",
        borderRight: "1px solid var(--border-color)",
        borderBottom: "1px solid var(--border-color)",
        borderTop: "1px solid var(--border-color)",
        borderRadius: "var(--button-border-radius)",
        ...HoverTransitionStyle,
        ...style,
        ...sx,
      }}
      id={id}
    >
      {children}
    </Container>
  );
}
