import * as React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import CustomListButton from "../common/CustomListButton";
import AddItemInputContainer from "../common/AddItemInputContainer";
import { AuthContext } from "../Context/AuthContext";

export default function DeleteAccount({ closeModal, deleteAccount }) {
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [step, setStep] = React.useState(1);
  const { firebaseUser, reauthenticateUser } = React.useContext(AuthContext);

  const handleDeleteAccount = async () => {
    if (step === 1) {
      setStep(2);
    } else {
      const response = await reauthenticateUser(
        firebaseUser.providerData[0].providerId,
        password
      );
      if (!response.success) {
        setErrorMessage(response.error);
        setStep(1);
      } else {
        await deleteAccount();
      }
    }
  };

  React.useEffect(() => {
    if (firebaseUser.providerData[0].providerId !== "password") {
      setStep(2);
    }
  }, [step]);

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Supprimer le compte
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <>
          {step === 1 && (
            <>
              {errorMessage && (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    color: "var(--unwanted-button-color)",
                  }}
                >
                  {errorMessage}
                </div>
              )}
              <AddItemInputContainer
                value={password}
                setter={setPassword}
                removeHorizontalSpace={true}
                name={"Mot de passe"}
                heightProps={40}
                titleFontSize={"16px"}
                fontSize={"18px"}
                type={"password"}
              />
            </>
          )}
          {step === 2 && (
            <>
              {errorMessage && (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    color: "var(--unwanted-button-color)",
                  }}
                >
                  {errorMessage}
                </div>
              )}
              <div
                style={{
                  fontWeight: "400",
                  fontSize: 20,
                }}
              >
                Nous sommes tristes de vous voir partir. Voulez-vous vraiment
                supprimer votre compte ?
              </div>
            </>
          )}
        </>
      </Container>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          padding: 24,
          gap: 10,
          backgroundColor: "var(--default-background-color)",
        }}
      >
        <CustomListButton
          text={"Annuler"}
          big={true}
          type={"submit"}
          primary={false}
          onClick={() => closeModal()}
        />
        <CustomListButton
          onClick={() => handleDeleteAccount()}
          text={step === 1 ? "Continuer" : "Oui je suis sûr"}
          big={true}
          type={"submit"}
          backgroundColorHoverProps={"var(--unwanted-button-color)"}
          backgroundColorProps={"var(--unwanted-button-color)"}
          borderColorProps={"var(--unwanted-button-color)"}
        />
      </div>
    </>
  );
}
