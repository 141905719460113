import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../components/Context/AuthContext";

const PublicRoute = ({ element, ...rest }) => {
  React.useEffect(() => {
    console.log("PublicRoute");
  }, []);
  const { firebaseUser } = useContext(AuthContext);

  return firebaseUser ? <Navigate to="/user/dashboard" replace /> : element;
};

export default PublicRoute;
