import { Container } from "@mui/material";

export default function HomeButton({
  basicsSx,
  leftIcon,
  rightIcon,
  title,
  description,
  propsStyles,
  onClick,
  isMenu = false,
  menuButtonSize,
  menuButtonAlign,
  menuButtonContentAlign,
}) {
  return (
    <Container
      onClick={onClick}
      sx={{
        ...basicsSx,
        padding: isMenu ? "16px !important" : "0px !important",
        backgroundColor: isMenu ? "var(--card-color)" : "transparent",
        borderRadius: isMenu ? "8px" : "0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        cursor: "pointer",
        margin: isMenu
          ? menuButtonAlign === "CENTER"
            ? "auto"
            : menuButtonAlign === "LEFT"
            ? "0px auto 0px 0px"
            : menuButtonAlign === "RIGHT"
            ? "0px 0px 0px auto"
            : "0px"
          : "0px",
        width: isMenu
          ? menuButtonSize === "LARGE"
            ? "100%"
            : menuButtonSize === "MEDIUM"
            ? "50%"
            : "auto"
          : "100%",
        justifyContent: isMenu
          ? menuButtonContentAlign === "CENTER"
            ? "center"
            : menuButtonContentAlign === "LEFT"
            ? "flex-start"
            : menuButtonContentAlign === "RIGHT"
            ? "flex-end"
            : "flex-start"
          : "flex-start",
        ...propsStyles,
      }}
    >
      {leftIcon && (
        <div style={{ marginRight: "17px", height: "100%", display: "flex" }}>
          {leftIcon}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          maxWidth: "280px",overflowWrap: "break-word",
        }}
      >
        {title && (
          <h2
            style={{
              margin: 0,
              fontSize: isMenu
                ? "var(--text-size)"
                : "var(--important-text-size)",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontWeight: "500",
            }}
          >
            {title}
          </h2>
        )}
        {description && (
          <p
            style={{
              margin: 0,
              fontSize: "var(--text-size)",
              color: "var(--subtitles-color)",
              fontWeight: "400",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {description}
          </p>
        )}
      </div>
      {rightIcon && rightIcon}
    </Container>
  );
}
