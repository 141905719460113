import React, { createContext, useState } from "react";
import { API_URL } from "../../consts";
import { UserContext } from "./UserContext";
import { dataURLtoFile } from "../../services/uploadFileService";
import { settingsLogs } from "../../services/logs";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const { currentMerchantId } = React.useContext(UserContext);
  const [changesModal, setChangesModal] = useState(false);
  const [changesStyleModal, setChangesStyleModal] = useState(false);
  const [settings, setSettings] = useState(null);
  const [allIsSaved, setAllIsSaved] = React.useState(true);

  React.useEffect(() => {
    fetchSettings();
  }, []);

  const getSettings = () => {
    settingsLogs("getSettings", settings);
    return settings;
  };

  const fetchSettings = React.useCallback(async () => {
    if (!currentMerchantId) {
      settingsLogs("[fetchSettings] NO currentMerchantId");
      return;
    }
    const response = await fetch(
      `${API_URL}api/marchants/${currentMerchantId}/informations`
    );
    const data = await response.json();
    if (data?.marchantId) {
      settingsLogs("[fetchSettings]", data);
      setSettings(data);
      return data;
    } else {
      settingsLogs("no");
    }
  });

  const updateSettings = async (updatedSettings) => {
    console.log(
      "[SettingsContext][updateSettings] updatedSettings",
      updatedSettings
    );
    if (!currentMerchantId) return;
    const formData = new FormData();

    formData.append(
      "basic",
      JSON.stringify({
        establishmentName:
          updatedSettings.basic?.establishmentName ??
          settings?.basic?.establishmentName,
        publicMenuURL:
          updatedSettings.basic?.publicMenuURL ??
          settings?.basic?.publicMenuURL,
        address: updatedSettings.basic?.address ?? settings?.basic?.address,
        phoneNumber:
          updatedSettings.basic?.phoneNumber ?? settings?.basic?.phoneNumber,
        message: updatedSettings.basic?.message ?? settings?.basic?.message,
        dayHours: updatedSettings.basic?.dayHours ?? settings?.basic?.dayHours,
      })
    );

    formData.append(
      "appearance",
      JSON.stringify({
        showRestaurantName:
          updatedSettings.appearance?.showRestaurantName ??
          settings?.appearance?.showRestaurantName,
        themeColor:
          updatedSettings.appearance?.themeColor ??
          settings?.appearance?.themeColor,
      })
    );

    formData.append(
      "wifi",
      JSON.stringify({
        networkName:
          updatedSettings.wifi?.networkName ?? settings?.wifi?.networkName,
        password: updatedSettings.wifi?.password ?? settings?.wifi?.password,
      })
    );

    formData.append(
      "socialNetworks",
      JSON.stringify({
        instagram:
          updatedSettings.socialNetworks?.instagram ??
          settings?.socialNetworks?.instagram,
        facebook:
          updatedSettings.socialNetworks?.facebook ??
          settings?.socialNetworks?.facebook,
        x: updatedSettings.socialNetworks?.x ?? settings?.socialNetworks?.x,
      })
    );

    formData.append(
      "review",
      JSON.stringify({
        display: updatedSettings.review?.display ?? settings?.review?.display,
        link: updatedSettings.review?.link ?? settings?.review?.link,
      })
    );

    settingsLogs("updatedSettings.customImage", updatedSettings.customImage);
    if (
      updatedSettings.customImage &&
      updatedSettings.customImage !== "none" &&
      updatedSettings.customImage.name &&
      updatedSettings.customImage.image &&
      updatedSettings.customImage !== undefined
    ) {
      console.log(
        "[updatedSettings]updatedSettings.dataURLtoFile",
        updatedSettings.customImage
      );
      const fileToUpload = dataURLtoFile(
        updatedSettings.customImage.image,
        updatedSettings.customImage.name
      );
      formData.append("customImage", fileToUpload);
    } else if (updatedSettings.image) {
      console.log(
        "[updatedSettings]updatedSettings.image",
        updatedSettings?.image?.value?.name
      );
      formData.append("imageValue", updatedSettings?.image?.value?.name);
    }

    try {
      const options = {
        method: "PUT",
        body: formData,
      };

      const response = await fetch(
        `${API_URL}api/marchants/${currentMerchantId}/informations`,
        options
      );

      const data = await response.json();
      setSettings(data);
      setChangesModal(true);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const checkPublicMenuUrl = async (url) => {
    if (!url) return { available: false };
    try {
      const response = await fetch(
        `${API_URL}api/marchants/check-public-menu-url/${url}`
      );
      const data = await response.json();
      return { available: data.available };
    } catch (error) {
      console.error("[checkPublicMenuUrl] error:", error);
      return { available: false };
    }
  };

  const updateStyle = async (styles, backgroundImageChanged) => {
    settingsLogs("styles", styles);
    console.error("[SettingsContext][updateStyle] styles", styles);
    console.error(
      "[SettingsContext][updateStyle] backgroundImageChanged",
      backgroundImageChanged
    );
    if (!currentMerchantId) return;
    const formData = new FormData();

    formData.append(
      "styles",
      JSON.stringify({
        styles,
      })
    );

    settingsLogs("backgroundImageChanged", backgroundImageChanged);

    if (
      styles.backgroundImage &&
      backgroundImageChanged &&
      styles.backgroundImage.value &&
      styles.backgroundImage.isOur === false
    ) {
      settingsLogs("in styles.backgroundImage", styles.backgroundImage);
      const fileToUpload = dataURLtoFile(
        styles.backgroundImage.image,
        styles.backgroundImage.name
      );
      formData.append("customImage", fileToUpload);
    }

    try {
      const options = {
        method: "PUT",
        body: formData,
      };

      const response = await fetch(
        `${API_URL}api/marchants/${currentMerchantId}/styles`,
        options
      );

      const data = await response.json();
      console.error("[SettingsContext][updateStyle] data", data);
      //settingsLogs("data", data);
      setChangesStyleModal(true);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const updatePromotionalText = async (text, callback) => {
    if (!currentMerchantId) return;

    try {
      const response = await fetch(
        `${API_URL}api/marchants/${currentMerchantId}/promotionnal-text`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promotionalText: text,
            marchantId: currentMerchantId,
          }),
        }
      );

      const data = await response.json();
      console.error("[SettingsContext][updatePromotionalText] data", data);
      settingsLogs("data", data);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const getStyles = async () => {
    if (!currentMerchantId) {
      settingsLogs("getStyles if (!currentMerchantId) {");
      return;
    }
    const response = await fetch(
      `${API_URL}api/marchants/${currentMerchantId}/styles`
    );
    const data = await response.json();
    if (data) {
      settingsLogs("yes", data);
      return data;
    } else {
      settingsLogs("no");
    }
  };

  const contextValue = React.useMemo(
    () => ({
      changesModal,
      changesStyleModal,
      settings,
      updateSettings,
      setChangesModal,
      setSettings,
      fetchSettings,
      checkPublicMenuUrl,
      updateStyle,
      getStyles,
      setChangesStyleModal,
      updatePromotionalText,
      getSettings,
      allIsSaved,
      setAllIsSaved,
    }),
    [
      changesModal,
      changesStyleModal,
      settings,
      updateSettings,
      setChangesModal,
      setSettings,
      fetchSettings,
      checkPublicMenuUrl,
      updateStyle,
      getStyles,
      setChangesStyleModal,
      updatePromotionalText,
      getSettings,
      allIsSaved,
      setAllIsSaved,
    ]
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};
