import React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import CustomModal from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import CustomListButton from "../common/CustomListButton";
import { AuthContext } from "../Context/AuthContext";
import { UserContext } from "../Context/UserContext";
import { API_URL } from "../../consts";
import { useSearchParams } from "react-router-dom";

function BillingFail({ open, closeModal }) {
  const { t } = useTranslation("global");

  const [queryParameters] = useSearchParams();

  const { firebaseUser } = React.useContext(AuthContext);
  const { currentMerchantName, currentMerchantId } = React.useContext(UserContext);

  const handlePaylick = () => {
    document.getElementById("checkout-and-portal-button-retry").click();
  };

  return (
    <>
      <CustomModal open={open} handleClose={closeModal}>
        <Container
          style={{
            borderBottom: "1px solid var(--border-color)",
            paddingBottom: 24,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 20,
            }}
          >
            {t("billingFailedTitle")}
          </div>
          <div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal()}
            />
          </div>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 10,
            marginTop: 20,
          }}
        >
          <div
            style={{
              fontWeight: "400",
              fontSize: 20,
            }}
          >
            {t("billingFailedSubTitle")}
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: 20,
            }}
          >
            {t("billingFailedDetails")}
          </div>
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 10,
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <form
            action={`${API_URL}api/user/pay`}
            method="POST"
            style={{ display: "none" }}
          >
            <input name="userEmail" value={firebaseUser.email} required />
            <input name="restaurantName" value={currentMerchantName} required />
            <input name="marchantId" value={currentMerchantId} required />
            <input name="isMonthly" value={queryParameters.get("isMonthly")} required />
            <button id="checkout-and-portal-button-retry" type="submit" />
          </form>
          <CustomListButton
            isPrimary={false}
            text={t("billingFailedButton")}
            onClick={handlePaylick}
            big={true}
            type={"submit"}
          />
        </div>
      </CustomModal>
    </>
  );
}

export default BillingFail;
