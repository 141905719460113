import * as React from "react";
import { Container } from "@mui/material";
import Banner from "../components/Banner";
import HeaderInformations from "../components/HeaderInformations";
import HomeButton from "../components/HomeButton";
import { iconsList } from "../../../iconsList";
import { basicsSx } from "../IphonePreviewUtils";

export default function HomeView({
  handleMenuClick,
  menuButtonSize = "LARGE",
  menuButtonAlign = "LEFT",
  menuButtonContentAlign = "LEFT",
  promotionalText,
  establishmentName,
  dayHours,
  message,
  informationText,
  informationSubtitleText,
  custom,
  address,
  networkName,
  password,
  phoneNumber,
  instagram,
  facebook,
  x,
  banner,
  menus,
  setDisplayInformationModal,
}) {
  const [bannerHeight, setBannerHeight] = React.useState(0);

  const handleBannerLoad = React.useCallback((height) => {
    setBannerHeight(height);
  }, []);

  return (
    <div
      style={{
        paddingBottom: "100px",
      }}
    >
      <div>
        <Banner
          restaurantName={establishmentName ?? ""}
          bannerUrl={banner}
          onLoad={handleBannerLoad}
          isHome={true}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: `${bannerHeight}px`,
          left: 0,
          width: "100%",
        }}
      >
        <HeaderInformations
          establishmentName={establishmentName}
          dayHours={dayHours}
          message={message}
          informationText={informationText}
          informationSubtitleText={informationSubtitleText}
          custom={custom}
          address={address}
          networkName={networkName}
          password={password}
          phoneNumber={phoneNumber}
          instagram={instagram}
          facebook={facebook}
          x={x}
          basicsSx={basicsSx}
          setDisplayInformationModal={setDisplayInformationModal}
        />
        {promotionalText && (
          <Container
            sx={{
              ...basicsSx,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding:
                "10px calc(var(--preview-screen-padding-horizontal) + 16px) 10px calc(var(--preview-screen-padding-horizontal) + 16px) !important",
              gap: "12px",
            }}
          >
            <Container
              sx={{
                ...basicsSx,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "16px !important",
                gap: "12px",
                backgroundColor: "var(--promotional-background-color)",
                border: "1px solid var(--promotional-border-color)",
                borderRadius: "8px",
                margin: "0px !important",
              }}
            >
              <div
                style={{
                  color: "var(--promotional-text-color)",
                  fontSize: "var(--promotional-text-size)",
                  overflowWrap: "break-word",
                }}
              >
                {promotionalText}
              </div>
            </Container>
          </Container>
        )}
        <Container
          sx={{
            ...basicsSx,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding:
              "16px calc(var(--preview-screen-padding-horizontal) + 16px) !important",
            gap: "12px",
          }}
        >
          {menus
            ?.sort((a, b) => a.order - b.order)
            .map((menu, index) => (
              <HomeButton
                onClick={() => handleMenuClick(menu._id)}
                key={index}
                leftIcon={
                  iconsList.find((icon) => icon.name === menu.icon ?? "fork")
                    .icon
                }
                title={menu.name}
                description={menu.description}
                propsStyles={{
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "var(--card-color)",
                }}
                isMenu={true}
                menuButtonSize={menuButtonSize}
                menuButtonAlign={menuButtonAlign}
                menuButtonContentAlign={menuButtonContentAlign}
              />
            ))}
        </Container>
      </div>
    </div>
  );
}
