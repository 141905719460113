import React from "react";
import CustomListButton from "../common/CustomListButton";
import LoginPageTemplate from "./LoginPageTemplate";
import LoginInputTextField from "./LoginInputTextField";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { validateMail } from "../../services/loginService";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";

function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("forgotPasswordPageTitle")}`);

  const { forgotPassword } = React.useContext(AuthContext);

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  const inputsChecker = () => {
    if (email === "") {
      setError(t("errorAddEmailAdress"));
      return false;
    }
    const emailValid = validateMail(email);
    if (!emailValid) {
      setError(t("errorAddValidEmailAdress"));
      return false;
    }

    return emailValid;
  };

  const handleSubmit = async () => {
    if (!inputsChecker()) {
      return;
    }
    try {
      forgotPassword(email, setSuccess, setError);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCreateAccountClick = () => {
    navigate(`/sign-up`);
  };

  const handleSignInClick = () => {
    navigate(`/sign-in`);
  };
  return (
    <LoginPageTemplate
      signInGoogle={false}
      divider={false}
      error={error}
      success={success}
      title={t("forgotPasswordPageTitle")}
      titleAlignLeft={true}
      description={t("forgotPasswordPageSubTitle")}
      primaryButton={{
        text: t("textSend"),
        onClick: () => handleSubmit(),
      }}
      textFields={
        <LoginInputTextField
          title={t("textEmail")}
          setter={setEmail}
          value={email}
        />
      }
      headerButton={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
          }}
        >
          <CustomListButton
            styles={{
              width: "fit-content",
              fontSize: 16,
              paddingLeft: "16px",
              paddingRight: "16px",
              fontWeight: "500 !important",
              maxHeight: 36,
            }}
            text={t("forgotPasswordPageSignIn")}
            onClick={handleSignInClick}
            primary={false}
          />
          <CustomListButton
            styles={{
              width: "fit-content",
              fontSize: 16,
              paddingLeft: "16px",
              paddingRight: "16px",
              fontWeight: "500 !important",
              maxHeight: 36,
            }}
            onClick={handleCreateAccountClick}
            text={t("loginPageRegisterText")}
            primary={false}
          />
        </div>
      }
    />
  );
}

export default ForgotPassword;
