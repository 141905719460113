import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../components/Context/AuthContext";
import Onboarding from "../../components/Onboarding/Onboarding";
import { UserContext } from "../../components/Context/UserContext";
import ChooseRestaurant from "../../components/ChooseRestaurant/ChooseRestaurant";
import Loading from "../../components/Loading/Loading";

const PrivateRoute = ({ element }) => {
  const { firebaseUser, onboarding, loading, isCheckingOnboarding } =
    React.useContext(AuthContext);
  const {
    doliQrUser,
    hasChosenRestaurant,
    setHasChosenRestaurant,
  } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!hasChosenRestaurant) {
      const hasChosen = JSON.parse(localStorage.getItem("hasChosenRestaurant"));
      setHasChosenRestaurant(hasChosen);
    }
    console.log(hasChosenRestaurant);
  }, [hasChosenRestaurant, setHasChosenRestaurant]);

  if (loading || isCheckingOnboarding) {
    return <Loading />;
  } else if (!firebaseUser) {
    return <Navigate to="/sign-in" replace />;
  } else if (firebaseUser && onboarding.onboarding === false) {
    return <Onboarding />;
  } else if (doliQrUser && doliQrUser.merchantsList.length === 0) {
    return <Loading />;
  } else if (!hasChosenRestaurant) {
    return <ChooseRestaurant />;
  } else {
    return element;
  }
};

export default PrivateRoute;
