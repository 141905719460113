import React, { createContext, useMemo } from "react";
import { SettingsContext } from "./SettingsContext";
import { useTranslation } from "react-i18next";
import eventBus from "../../services/eventBus";

export const PersonalizeContext = createContext();

const DARK_THEME = {
  textsColor: "white",
  backgroundColor: "#000",
  cardColor: "#333",
  borderColor: "#333",
  informationBorderColor: "#333",
  iconColor: "white",
  greenText: "green",
  reverseBackgroundColor: "#fff",
  reverseTextColor: "black",
  fontFamily: "Roboto, sans-serif",
  goldColor: "#ffd700",
  textFontSize: 16,
  titleTextSize: 28,
  categoryTextSize: 24,
  importantTextSize: 18,
  subtitlesColor: "#757575",
  promotionalBackgroundColor: "rgb(7, 92, 15)",
  promotionalTextColor: "white",
  promotionalTextSize: 16,
  promotionalBorderColor: "rgb(11, 161, 21)",
  allergensTitleFontSize: 18,
  allergensTitleColor: "white",
  allergensTextFontSize: 16,
  allergensTextColor: "#757575",
};

const LIGHT_THEME = {
  textsColor: "black",
  backgroundColor: "#fff",
  cardColor: "#f3f3f3",
  borderColor: "#f3f3f3",
  informationBorderColor: "#f3f3f3",
  iconColor: "black",
  greenText: "green",
  reverseBackgroundColor: "#000",
  reverseTextColor: "white",
  fontFamily: "Roboto, sans-serif",
  goldColor: "#ffd700",
  textFontSize: 16,
  titleTextSize: 28,
  categoryTextSize: 24,
  importantTextSize: 18,
  subtitlesColor: "#757575",
  promotionalBackgroundColor: "rgb(234, 242, 234)",
  promotionalTextColor: "black",
  promotionalTextSize: 16,
  promotionalBorderColor: "rgb(11, 161, 21)",
  allergensTitleFontSize: 18,
  allergensTitleColor: "black",
  allergensTextFontSize: 16,
  allergensTextColor: "#757575",
};

export const PersonalizeProvider = ({ children }) => {
  const {
    updateStyle,
    getStyles,
    getSettings,
    setAllIsSaved,
    settings,
    updateSettings,
  } = React.useContext(SettingsContext);
  const [stylesFetched, setStylesFetched] = React.useState(false);
  const [canLoad, setCanLoad] = React.useState(false);
  const [showContent, setShowContent] = React.useState("");
  const [personalizeActivated, setPersonalizeActivated] = React.useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState(null);
  const [backgroundImageChanged, setBackgroundImageChanged] =
    React.useState(false);
  const [backgroundImageFetched, setBackgroundImageFetched] =
    React.useState(null);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [ourBackgroundImage, setOurBackgroundImage] = React.useState(null);
  const [backgroundImageOpacity, setBackgroundImageOpacity] =
    React.useState(null);
  const [buttonsColor, setButtonsColor] = React.useState(null);
  const [linesColor, setLinesColor] = React.useState(null);
  const [iconsColor, setIconsColor] = React.useState(null);
  const [menuButtonSize, setMenuButtonSize] = React.useState(null);
  const [menuButtonAlign, setMenuButtonAlign] = React.useState(null);
  const [menuButtonContentAlign, setMenuButtonContentAlign] =
    React.useState(null);
  const [textsColor, setTextsColor] = React.useState(null);
  const [secondaryTextsColor, setSecondaryTextsColor] = React.useState(null);
  const [titlesFontSize, setTitlesFontSize] = React.useState(null);
  const [textsFontSize, setTextsFontSize] = React.useState(null);
  const [categoriesFontSize, setCategoriesFontSize] = React.useState(null);
  const [importantTextsFontSize, setImportantTextsFontSize] =
    React.useState(null);
  const [informationText, setInformationText] = React.useState("Informations");
  const [informationSubtitleText, setInformationSubtitleText] = React.useState(
    "Téléphone, adresse et réseaux sociaux"
  );
  const [informationsLinesColor, setInformationsLinesColor] =
    React.useState(null);
  const [informationCopiedTextColor, setInformationCopiedTextColor] =
    React.useState(null);
  const [reviewButtonTextColor, setReviewButtonTextColor] =
    React.useState(null);
  const [reviewButtonText, setReviewButtonText] = React.useState(null);
  const [reviewPageTitleText, setReviewPageTitleText] = React.useState(null);
  const [reviewFeedbackText, setReviewFeedbackText] = React.useState(null);
  const [reviewCloseButtonText, setReviewCloseButtonText] =
    React.useState(null);
  const [reviewSendButtonText, setReviewSendButtonText] = React.useState(null);
  const [reviewButtonColor, setReviewButtonColor] = React.useState(null);
  const [activeStarColor, setActiveStarColor] = React.useState(null);
  const [promotionalBackgroundColor, setPromotionalBackgroundColor] =
    React.useState(null);
  const [promotionalTextColor, setPromotionalTextColor] = React.useState(null);
  const [promotionalTextSize, setPromotionalTextSize] = React.useState(null);
  const [promotionalBorderColor, setPromotionalBorderColor] =
    React.useState(null);
  const [allergensTitleFontSize, setAllergensTitleFontSize] =
    React.useState(null);
  const [allergensTitleColor, setAllergensTitleColor] = React.useState(null);
  const [allergensTextFontSize, setAllergensTextFontSize] =
    React.useState(null);
  const [allergensTextColor, setAllergensTextColor] = React.useState(null);
  const [checkTheme, setCheckTheme] = React.useState(false);
  const [personalizeTheme, setPersonalizeTheme] = React.useState(null);

  const handleSetStylesValues = async (forcePersonalize = false) => {
    const styles = await getStyles();

    console.log("[handleSetStylesValues] styles", styles);
    const settings = await getSettings();
    if (!settings?.appearance?.themeColor) {
      console.log("if (!settings?.appearance?.themeColor) {", settings);
      return;
    }
    const theme = settings?.appearance?.themeColor;
    console.log("[handleSetStylesValues] theme", theme);
    const themeColor = theme === "LIGHT_THEME" ? LIGHT_THEME : DARK_THEME;
    console.log("[handleSetStylesValues] theme", theme);
    let isPersonnalize = null;
    if (
      personalizeActivated === null &&
      isPersonnalize === null &&
      !forcePersonalize
    ) {
      isPersonnalize = settings?.style?.custom;
    } else {
      isPersonnalize = personalizeActivated;
    }
    if (forcePersonalize) {
      isPersonnalize = true;
    }
    console.error("isPersonnalize", isPersonnalize);
    if (!styles || !isPersonnalize) {
      console.log(
        "handleSetStylesValues]  if (!styles || !personalizeActivated) {"
      );
      console.log(
        "[handleSetStylesValues] no styles isPersonnalize",
        isPersonnalize
      );
      setPersonalizeActivated(isPersonnalize);
      setBackgroundColor(themeColor.backgroundColor);
      setBackgroundImage(null);
      setBackgroundImageOpacity(0.5);
      setButtonsColor(themeColor.cardColor);
      setLinesColor(themeColor.borderColor);
      setIconsColor(themeColor.iconColor);
      setMenuButtonSize("LARGE");
      setMenuButtonAlign("LEFT");
      setMenuButtonContentAlign("LEFT");
      setTextsColor(themeColor.textsColor);
      setSecondaryTextsColor(themeColor.subtitlesColor);
      setTitlesFontSize(themeColor.titleTextSize);
      setTextsFontSize(themeColor.textFontSize);
      setCategoriesFontSize(themeColor.categoryTextSize);
      setImportantTextsFontSize(themeColor.importantTextSize);
      setInformationText("Informations");
      setInformationSubtitleText("Téléphone, adresse et réseaux sociaux");
      setInformationsLinesColor(themeColor.borderColor);
      setInformationCopiedTextColor(themeColor.greenText);
      setReviewButtonTextColor(themeColor.reverseTextColor);
      setReviewButtonColor(themeColor.reverseBackgroundColor);
      setActiveStarColor(themeColor.goldColor);
      setReviewButtonText("Noter l'établissement");
      setReviewPageTitleText("Noter l'établissement");
      setReviewFeedbackText("Merci, votre avis est important pour nous !");
      setReviewCloseButtonText("Fermer");
      setReviewSendButtonText("Envoyer");
      setPromotionalBackgroundColor(themeColor.promotionalBackgroundColor);
      setPromotionalTextColor(themeColor.promotionalTextColor);
      setPromotionalTextSize(themeColor.promotionalTextSize);
      setPromotionalBorderColor(themeColor.promotionalBorderColor);
      setStylesFetched(true);
      setAllergensTitleFontSize(themeColor.allergensTitleFontSize);
      setAllergensTitleColor(themeColor.allergensTitleColor);
      setAllergensTextFontSize(themeColor.allergensTextFontSize);
      setAllergensTextColor(themeColor.allergensTextColor);
      setBackgroundImageChanged(false);

      console.log(
        "[checkAllIsSaved][Personaize] from handleSetStylesValues (!styles || !isPersonnalize)"
      );
      checkAllIsSaved(isPersonnalize);
    } else {
      console.log(
        "handleSetStylesValues]  else (!styles || !personalizeActivated) {",
        isPersonnalize
      );
      console.log("[handleSetStylesValues] styles", styles);
      setPersonalizeActivated(isPersonnalize);
      setBackgroundColor(styles.backgroundColor || themeColor.backgroundColor);
      setBackgroundImage({
        value: styles.backgroundImage || null,
        isOur: styles.backgroundImageIsOur,
      });
      setBackgroundImageFetched(styles.backgroundImage || null);
      setBackgroundImageOpacity(styles.backgroundImageOpacity || 0.5);
      setButtonsColor(styles.buttonsColor || themeColor.cardColor);
      setLinesColor(styles.linesColor || themeColor.borderColor);
      setIconsColor(styles.iconsColor || themeColor.iconColor);
      setMenuButtonSize(styles.menuButtonSize || "LARGE");
      setMenuButtonAlign(styles.menuButtonAlign || "LEFT");
      setMenuButtonContentAlign(styles.menuButtonContentAlign || "LEFT");
      setTextsColor(styles.textsColor || themeColor.textsColor);
      setSecondaryTextsColor(
        styles.secondaryTextsColor || themeColor.subtitlesColor
      );
      setTitlesFontSize(styles.titlesFontSize || themeColor.titleTextSize);
      setTextsFontSize(styles.textsFontSize || themeColor.textFontSize);
      setCategoriesFontSize(
        styles.categoriesFontSize || themeColor.categoryTextSize
      );
      setImportantTextsFontSize(
        styles.importantTextsFontSize || themeColor.importantTextSize
      );
      setInformationText(styles.informationText || "Informations");
      setInformationSubtitleText(
        styles.informationSubtitleText ||
          "Téléphone, adresse et réseaux sociaux"
      );
      setInformationsLinesColor(
        styles.informationsLinesColor || themeColor.borderColor
      );
      setInformationCopiedTextColor(
        styles.informationCopiedTextColor || themeColor.greenText
      );
      setReviewButtonTextColor(
        styles.reviewButtonTextColor || themeColor.reverseTextColor
      );
      setReviewButtonColor(
        styles.reviewButtonColor || themeColor.reverseBackgroundColor
      );
      setReviewButtonText(styles.reviewButtonText || "Noter l'établissement");
      setReviewPageTitleText(
        styles.reviewPageTitleText || "Noter l'établissement"
      );
      setReviewFeedbackText(
        styles.reviewFeedbackText ||
          "Merci, votre avis est important pour nous !"
      );
      setReviewCloseButtonText(styles.reviewCloseButtonText || "Fermer");
      setReviewSendButtonText(styles.reviewSendButtonText || "Envoyer");
      setActiveStarColor(styles.activeStarColor || themeColor.goldColor);
      setPromotionalBackgroundColor(
        styles.promotionalBackgroundColor ||
          themeColor.promotionalBackgroundColor
      );
      setPromotionalTextColor(
        styles.promotionalTextColor || themeColor.promotionalTextColor
      );
      setPromotionalTextSize(
        styles.promotionalTextSize || themeColor.promotionalTextSize
      );
      setPromotionalBorderColor(
        styles.promotionalBorderColor || themeColor.promotionalBorderColor
      );
      setAllergensTitleFontSize(
        styles.allergensTitleFontSize || themeColor.allergensTitleFontSize
      );
      setAllergensTitleColor(
        styles.allergensTitleColor || themeColor.allergensTitleColor
      );
      setAllergensTextFontSize(
        styles.allergensTextFontSize || themeColor.allergensTextFontSize
      );
      setAllergensTextColor(
        styles.allergensTextColor || themeColor.allergensTextColor
      );
      setStylesFetched(true);
      setBackgroundImageChanged(false);

      console.log(
        "[checkAllIsSaved][Personaize] from handleSetStylesValues (!styles || !isPersonnalize) else"
      );
    }
  };

  React.useEffect(() => {
    console.log("[PersonalizeContext] useEffect");
    handleSetStylesValues();
  }, [settings]);

  const changePreviewStyle = async (
    isActivate,
    savePersonalization = false
  ) => {
    // isActivate = personalizeActivated
    // savePersonalization = called by handleChangePersonalizeActivation
    // personalizeActivated has already setted
    const settings = await getSettings();
    const theme = settings?.appearance?.themeColor;
    console.log("[changePreviewStyle] theme", theme);
    const themeColor = theme === "LIGHT_THEME" ? LIGHT_THEME : DARK_THEME;
    if (!isActivate) {
      // if theme will be with personalizeActivated desactivated
      if (savePersonalization) {
        setPersonalizeTheme({
          backgroundColor: backgroundColor || themeColor.backgroundColor,
          backgroundImage: backgroundImage || null,
          backgroundImageOpacity: backgroundImageOpacity || 0.5,
          buttonsColor: buttonsColor || themeColor.cardColor,
          linesColor: linesColor || themeColor.borderColor,
          iconsColor: iconsColor || themeColor.iconColor,
          menuButtonSize: menuButtonSize || "LARGE",
          menuButtonAlign: menuButtonAlign || "LEFT",
          menuButtonContentAlign: menuButtonContentAlign || "LEFT",
          textsColor: textsColor || themeColor.textsColor,
          secondaryTextsColor: secondaryTextsColor || themeColor.subtitlesColor,
          titlesFontSize: titlesFontSize || themeColor.titleTextSize,
          textsFontSize: textsFontSize || themeColor.textFontSize,
          categoriesFontSize: categoriesFontSize || themeColor.categoryTextSize,
          importantTextsFontSize:
            importantTextsFontSize || themeColor.importantTextSize,
          informationText: informationText || "Informations",
          informationSubtitleText:
            informationSubtitleText || "Téléphone, adresse et réseaux sociaux",
          informationsLinesColor:
            informationsLinesColor || themeColor.borderColor,
          informationCopiedTextColor:
            informationCopiedTextColor || themeColor.greenText,
          reviewButtonTextColor:
            reviewButtonTextColor || themeColor.reverseTextColor,
          reviewButtonText: reviewButtonText || "Noter l'établissement",
          reviewPageTitleText: reviewPageTitleText || "Noter l'établissement",
          reviewFeedbackText:
            reviewFeedbackText || "Merci, votre avis est important pour nous !",
          reviewCloseButtonText: reviewCloseButtonText || "Fermer",
          reviewSendButtonText: reviewSendButtonText || "Envoyer",
          reviewButtonColor:
            reviewButtonColor || themeColor.reverseBackgroundColor,
          activeStarColor: activeStarColor || themeColor.goldColor,
          promotionalBackgroundColor:
            promotionalBackgroundColor || themeColor.promotionalBackgroundColor,
          promotionalTextColor:
            promotionalTextColor || themeColor.promotionalTextColor,
          promotionalTextSize:
            promotionalTextSize || themeColor.promotionalTextSize,
          promotionalBorderColor:
            promotionalBorderColor || themeColor.promotionalBorderColor,
          allergensTitleFontSize:
            allergensTitleFontSize || themeColor.allergensTitleFontSize,
          allergensTitleColor:
            allergensTitleColor || themeColor.allergensTitleColor,
          allergensTextFontSize:
            allergensTextFontSize || themeColor.allergensTextFontSize,
          allergensTextColor:
            allergensTextColor || themeColor.allergensTextColor,
        });
      }
      setBackgroundColor(themeColor.backgroundColor);
      setBackgroundImage(null);
      setOurBackgroundImage(null);
      setBackgroundImageOpacity(0.5);
      setButtonsColor(themeColor.cardColor);
      setLinesColor(themeColor.borderColor);
      setIconsColor(themeColor.iconColor);
      setMenuButtonSize("LARGE");
      setMenuButtonAlign("LEFT");
      setMenuButtonContentAlign("LEFT");
      setTextsColor(themeColor.textsColor);
      setSecondaryTextsColor(themeColor.subtitlesColor);
      setTitlesFontSize(themeColor.titleTextSize);
      setTextsFontSize(themeColor.textFontSize);
      setCategoriesFontSize(themeColor.categoryTextSize);
      setImportantTextsFontSize(themeColor.importantTextSize);
      setInformationText("Informations");
      setInformationSubtitleText("Téléphone, adresse et réseaux sociaux");
      setInformationsLinesColor(themeColor.borderColor);
      setInformationCopiedTextColor(themeColor.greenText);
      setReviewButtonTextColor(themeColor.reverseTextColor);
      setReviewButtonColor(themeColor.reverseBackgroundColor);
      setActiveStarColor(themeColor.goldColor);
      setReviewButtonText("Noter l'établissement");
      setReviewPageTitleText("Noter l'établissement");
      setReviewFeedbackText("Merci, votre avis est important pour nous !");
      setReviewCloseButtonText("Fermer");
      setReviewSendButtonText("Envoyer");
      setPromotionalBackgroundColor(themeColor.promotionalBackgroundColor);
      setPromotionalTextColor(themeColor.promotionalTextColor);
      setPromotionalTextSize(themeColor.promotionalTextSize);
      setPromotionalBorderColor(themeColor.promotionalBorderColor);
      setStylesFetched(true);
      setAllergensTitleFontSize(themeColor.allergensTitleFontSize);
      setAllergensTitleColor(themeColor.allergensTitleColor);
      setAllergensTextFontSize(themeColor.allergensTextFontSize);
      setAllergensTextColor(themeColor.allergensTextColor);
      setBackgroundImageChanged(false);
    } else {
      // if theme will be with personalizeActivated activated
      if (personalizeTheme) {
        // If personalizeTheme was pre-saved before reset all value to the last changes
        setBackgroundColor(personalizeTheme.backgroundColor);
        setBackgroundImage({
          value: personalizeTheme.backgroundImage.value || null,
          isOur: personalizeTheme.backgroundImage.isOur,
        });
        setBackgroundImageOpacity(personalizeTheme.backgroundImageOpacity);
        setButtonsColor(personalizeTheme.buttonsColor);
        setLinesColor(personalizeTheme.linesColor);
        setIconsColor(personalizeTheme.iconsColor);
        setMenuButtonSize(personalizeTheme.menuButtonSize);
        setMenuButtonAlign(personalizeTheme.menuButtonAlign);
        setMenuButtonContentAlign(personalizeTheme.menuButtonContentAlign);
        setTextsColor(personalizeTheme.textsColor);
        setSecondaryTextsColor(personalizeTheme.secondaryTextsColor);
        setTitlesFontSize(personalizeTheme.titlesFontSize);
        setTextsFontSize(personalizeTheme.textsFontSize);
        setCategoriesFontSize(personalizeTheme.categoriesFontSize);
        setImportantTextsFontSize(personalizeTheme.importantTextsFontSize);
        setInformationText(personalizeTheme.informationText);
        setInformationSubtitleText(personalizeTheme.informationSubtitleText);
        setInformationsLinesColor(personalizeTheme.informationsLinesColor);
        setInformationCopiedTextColor(
          personalizeTheme.informationCopiedTextColor
        );
        setReviewButtonTextColor(personalizeTheme.reviewButtonTextColor);
        setReviewButtonColor(personalizeTheme.reviewButtonColor);
        setActiveStarColor(personalizeTheme.activeStarColor);
        setReviewButtonText(personalizeTheme.reviewButtonText);
        setReviewPageTitleText(personalizeTheme.reviewPageTitleText);
        setReviewFeedbackText(personalizeTheme.reviewFeedbackText);
        setReviewCloseButtonText(personalizeTheme.reviewCloseButtonText);
        setReviewSendButtonText(personalizeTheme.reviewSendButtonText);
        setPromotionalBackgroundColor(
          personalizeTheme.promotionalBackgroundColor
        );
        setPromotionalTextColor(personalizeTheme.promotionalTextColor);
        setPromotionalTextSize(personalizeTheme.promotionalTextSize);
        setPromotionalBorderColor(personalizeTheme.promotionalBorderColor);
        setAllergensTitleFontSize(personalizeTheme.allergensTitleFontSize);
        setAllergensTitleColor(personalizeTheme.allergensTitleColor);
        setAllergensTextFontSize(personalizeTheme.allergensTextFontSize);
        setAllergensTextColor(personalizeTheme.allergensTextColor);
      } else {
        // If there is no personalizeTheme fetch the personalize theme saved
        handleSetStylesValues(true);
      }
    }
  };

  const handleLoad = async () => {
    console.error("[handleLoad]");
    await handleSetStylesValues();
  };

  const handlePromise = async (newPersonalizeValue) => {
    Promise.resolve(
      new Promise(async (resolve) => {
        console.log("[handlePromise] newPersonalizeValue", newPersonalizeValue);
        setPersonalizeActivated(newPersonalizeValue);
        resolve();
      })
    );
  };

  const handleChangePersonalizeActivation = async () => {
    console.log("handleChangePersonalizeActivation");
    const newPersonalizeValue = personalizeActivated;
    await handlePromise(!newPersonalizeValue);
    changePreviewStyle(!newPersonalizeValue, true);
  };

  const handleShowContent = (title) => {
    setShowContent(title);
  };

  const [lastSaveValues, setLastSaveValues] = React.useState(null);

  const handleSaveStyle = React.useCallback(async () => {
    console.warn("handleSaveStyle", personalizeActivated);
    let bannerStatus = null;

    const bannerPromise = new Promise((resolve) => {
      const handleBannerResponse = (status) => {
        bannerStatus = status;
        eventBus.off("BANNER_STATUS_RESPONSE", handleBannerResponse);
        resolve();
      };
      eventBus.on("BANNER_STATUS_RESPONSE", handleBannerResponse);
    });

    eventBus.emit("CHECK_BANNER_STATUS");
    await bannerPromise;

    console.log("bannerStatus", bannerStatus);

    if (bannerStatus?.changed) {
      await updateSettings({
        customImage: bannerStatus?.value?.customImage,
        image: bannerStatus?.value?.image,
      });
    }
    if (personalizeActivated) {
      const newValues = {
        custom: personalizeActivated,
        backgroundColor,
        backgroundImage: backgroundImage || null,
        ourBackgroundImage: ourBackgroundImage || null,
        backgroundImageOpacity,
        buttonsColor,
        linesColor,
        iconsColor,
        menuButtonSize,
        menuButtonAlign,
        menuButtonContentAlign,
        textsColor,
        secondaryTextsColor,
        titlesFontSize: titlesFontSize,
        textsFontSize: textsFontSize,
        categoriesFontSize: categoriesFontSize,
        importantTextsFontSize: importantTextsFontSize,
        informationText,
        informationSubtitleText,
        informationsLinesColor,
        informationCopiedTextColor,
        reviewButtonTextColor,
        reviewButtonText,
        reviewPageTitleText,
        reviewFeedbackText,
        reviewCloseButtonText,
        reviewSendButtonText,
        reviewButtonColor,
        activeStarColor,
        promotionalBackgroundColor,
        promotionalTextColor,
        promotionalTextSize,
        promotionalBorderColor,
        allergensTitleFontSize,
        allergensTitleColor,
        allergensTextFontSize,
        allergensTextColor,
        setCanLoad,
      };
      //await checkBannerChanged();
      await updateStyle(newValues, backgroundImageChanged);
      console.log("[checkAllIsSaved][Personaize] from handleSaveStyle");
      checkAllIsSaved(personalizeActivated, true);
      setBackgroundImageChanged(false);
    } else {
      console.log("[checkAllIsSaved][Personaize] from handleSaveStyle");
      await updateStyle({
        custom: personalizeActivated,
      });
      checkAllIsSaved(personalizeActivated, true);
    }
    setCheckTheme(!checkTheme);
  });

  const checkAllIsSaved = React.useCallback(
    async (isPersonalizeActivated, save = false) => {
      /**
       * 1. 1 call from handleSetStylesValues
       *    from handleSetStylesValues if is personalize = true
       *      set all values in setLastSaveValues
       *    if init = setAllIsSaved to true
       *    if handleSetStylesValues is call from changePreviewStyle
       *
       * 2. 2 calls from handleSaveStyle
       *    from handleSaveStyle if personalizeActivated set all values setLastSaveValues
       *    give isPersonalizeActivated value and force saving
       *
       * 3. 1 calls from useEffect
       *    from useEffect if a value change
       *    give isPersonalizeActivated value
       */
      if (isPersonalizeActivated) {
        const newValues = {
          custom: isPersonalizeActivated,
          backgroundImage: backgroundImage || null,
          ourBackgroundImage: ourBackgroundImage || null,
          backgroundColor: backgroundColor.toString(),
          backgroundImageOpacity: backgroundImageOpacity.toString(),
          buttonsColor: buttonsColor.toString(),
          linesColor: linesColor.toString(),
          iconsColor: iconsColor.toString(),
          menuButtonSize: menuButtonSize.toString(),
          menuButtonAlign: menuButtonAlign.toString(),
          menuButtonContentAlign: menuButtonContentAlign.toString(),
          textsColor: textsColor.toString(),
          secondaryTextsColor: secondaryTextsColor.toString(),
          titlesFontSize: titlesFontSize.toString(),
          textsFontSize: textsFontSize.toString(),
          categoriesFontSize: categoriesFontSize.toString(),
          importantTextsFontSize: importantTextsFontSize.toString(),
          informationText: informationText.toString(),
          informationSubtitleText: informationSubtitleText.toString(),
          informationsLinesColor: informationsLinesColor.toString(),
          informationCopiedTextColor: informationCopiedTextColor.toString(),
          reviewButtonTextColor: reviewButtonTextColor.toString(),
          reviewButtonText: reviewButtonText.toString(),
          reviewPageTitleText: reviewPageTitleText.toString(),
          reviewFeedbackText: reviewFeedbackText.toString(),
          reviewCloseButtonText: reviewCloseButtonText.toString(),
          reviewSendButtonText: reviewSendButtonText.toString(),
          reviewButtonColor: reviewButtonColor.toString(),
          activeStarColor: activeStarColor.toString(),
          promotionalBackgroundColor: promotionalBackgroundColor.toString(),
          promotionalTextColor: promotionalTextColor.toString(),
          promotionalTextSize: promotionalTextSize.toString(),
          promotionalBorderColor: promotionalBorderColor.toString(),
          allergensTitleFontSize: allergensTitleFontSize.toString(),
          allergensTitleColor: allergensTitleColor.toString(),
          allergensTextFontSize: allergensTextFontSize.toString(),
          allergensTextColor: allergensTextColor.toString(),
        };
        console.log("[checkAllIsSaved][Personaize] isPersonalizeActivated ");
        console.log("[checkAllIsSaved][Personaize]newValues", newValues);
        console.log(
          "[checkAllIsSaved][Personaize]lastSaveValues",
          lastSaveValues
        );
        if (lastSaveValues === null) {
          setLastSaveValues(newValues);
          setAllIsSaved(true);
          console.log("[checkAllIsSaved][Personaize]all set");
        } else if (save) {
          setLastSaveValues(newValues);
          console.log(
            "[checkAllIsSaved][Personaize] is equal ",
            JSON.stringify(newValues) === JSON.stringify(lastSaveValues)
          );
          setAllIsSaved(true);
        } else {
          console.log(
            "[checkAllIsSaved][Personaize] checking",
            JSON.stringify(newValues) === JSON.stringify(lastSaveValues)
          );
          setAllIsSaved(
            JSON.stringify(newValues) === JSON.stringify(lastSaveValues)
          );
        }
      } else {
        console.log("[checkAllIsSaved][Personaize][notpersonalize]");
        if (!save) {
          if (lastSaveValues === null) {
            console.log(
              "[checkAllIsSaved][Personaize][notpersonalize] lastSaveValues === null"
            );

            setAllIsSaved(true);
          } else {
            console.log(
              "[checkAllIsSaved][Personaize][notpersonalize] lastSaveValues !== null"
            );
            if (lastSaveValues?.custom === false) {
              console.log(
                "[checkAllIsSaved][Personaize][notpersonalize] lastSaveValues?.custom === false"
              );
              setAllIsSaved(true);
            } else {
              console.log(
                "[checkAllIsSaved][Personaize][notpersonalize] lastSaveValues?.custom === true"
              );
              setAllIsSaved(false);
            }
          }
        } else {
          const newValues = {
            custom: isPersonalizeActivated,
            backgroundColor:
              lastSaveValues?.backgroundColor.toString() ||
              personalizeTheme?.backgroundColor.toString() ||
              backgroundColor.toString(),
            backgroundImage: backgroundImage || null,
            ourBackgroundImage: ourBackgroundImage || null,
            backgroundImageOpacity:
              lastSaveValues?.backgroundImageOpacity.toString() ||
              personalizeTheme?.backgroundImageOpacity.toString() ||
              backgroundImageOpacity.toString(),
            buttonsColor:
              lastSaveValues?.buttonsColor.toString() ||
              personalizeTheme?.buttonsColor.toString() ||
              buttonsColor.toString(),
            linesColor:
              lastSaveValues?.linesColor.toString() ||
              personalizeTheme?.linesColor.toString() ||
              linesColor.toString(),
            iconsColor:
              lastSaveValues?.iconsColor.toString() ||
              personalizeTheme?.iconsColor.toString() ||
              iconsColor.toString(),
            menuButtonSize:
              lastSaveValues?.menuButtonSize.toString() ||
              personalizeTheme?.menuButtonSize.toString() ||
              menuButtonSize.toString(),
            menuButtonAlign:
              lastSaveValues?.menuButtonAlign.toString() ||
              personalizeTheme?.menuButtonAlign.toString() ||
              menuButtonAlign.toString(),
            menuButtonContentAlign:
              lastSaveValues?.menuButtonContentAlign.toString() ||
              personalizeTheme?.menuButtonContentAlign.toString() ||
              menuButtonContentAlign.toString(),
            textsColor:
              lastSaveValues?.textsColor.toString() ||
              personalizeTheme?.textsColor.toString() ||
              textsColor.toString(),
            secondaryTextsColor:
              lastSaveValues?.secondaryTextsColor.toString() ||
              personalizeTheme?.secondaryTextsColor.toString() ||
              secondaryTextsColor.toString(),
            titlesFontSize:
              lastSaveValues?.titlesFontSize.toString() ||
              personalizeTheme?.titlesFontSize.toString() ||
              titlesFontSize.toString(),
            textsFontSize:
              lastSaveValues?.textsFontSize.toString() ||
              personalizeTheme?.textsFontSize.toString() ||
              textsFontSize.toString(),
            categoriesFontSize:
              lastSaveValues?.categoriesFontSize.toString() ||
              personalizeTheme?.categoriesFontSize.toString() ||
              categoriesFontSize.toString(),
            importantTextsFontSize:
              lastSaveValues?.importantTextsFontSize.toString() ||
              personalizeTheme?.importantTextsFontSize.toString() ||
              importantTextsFontSize.toString(),
            informationText:
              lastSaveValues?.informationText.toString() ||
              personalizeTheme?.informationText.toString() ||
              informationText.toString(),
            informationSubtitleText:
              lastSaveValues?.informationSubtitleText.toString() ||
              personalizeTheme?.informationSubtitleText.toString() ||
              informationSubtitleText.toString(),
            informationsLinesColor:
              lastSaveValues?.informationsLinesColor.toString() ||
              personalizeTheme?.informationsLinesColor.toString() ||
              informationsLinesColor.toString(),
            informationCopiedTextColor:
              lastSaveValues?.informationCopiedTextColor.toString() ||
              personalizeTheme?.informationCopiedTextColor.toString() ||
              informationCopiedTextColor.toString(),
            reviewButtonTextColor:
              lastSaveValues?.reviewButtonTextColor.toString() ||
              personalizeTheme?.reviewButtonTextColor.toString() ||
              reviewButtonTextColor.toString(),
            reviewButtonText:
              lastSaveValues?.reviewButtonText.toString() ||
              personalizeTheme?.reviewButtonText.toString() ||
              reviewButtonText.toString(),
            reviewPageTitleText:
              lastSaveValues?.reviewPageTitleText.toString() ||
              personalizeTheme?.reviewPageTitleText.toString() ||
              reviewPageTitleText.toString(),
            reviewFeedbackText:
              lastSaveValues?.reviewFeedbackText.toString() ||
              personalizeTheme?.reviewFeedbackText.toString() ||
              reviewFeedbackText.toString(),
            reviewCloseButtonText:
              lastSaveValues?.reviewCloseButtonText.toString() ||
              personalizeTheme?.reviewCloseButtonText.toString() ||
              reviewCloseButtonText.toString(),
            reviewSendButtonText:
              lastSaveValues?.reviewSendButtonText.toString() ||
              personalizeTheme?.reviewSendButtonText.toString() ||
              reviewSendButtonText.toString(),
            reviewButtonColor:
              lastSaveValues?.reviewButtonColor.toString() ||
              personalizeTheme?.reviewButtonColor.toString() ||
              reviewButtonColor.toString(),
            activeStarColor:
              lastSaveValues?.activeStarColor.toString() ||
              personalizeTheme?.activeStarColor.toString() ||
              activeStarColor.toString(),
            promotionalBackgroundColor:
              lastSaveValues?.promotionalBackgroundColor.toString() ||
              personalizeTheme?.promotionalBackgroundColor.toString() ||
              promotionalBackgroundColor.toString(),
            promotionalTextColor:
              lastSaveValues?.promotionalTextColor.toString() ||
              personalizeTheme?.promotionalTextColor.toString() ||
              promotionalTextColor.toString(),
            promotionalTextSize:
              lastSaveValues?.promotionalTextSize.toString() ||
              personalizeTheme?.promotionalTextSize.toString() ||
              promotionalTextSize.toString(),
            promotionalBorderColor:
              lastSaveValues?.promotionalBorderColor.toString() ||
              personalizeTheme?.promotionalBorderColor.toString() ||
              promotionalBorderColor.toString(),
            allergensTitleFontSize:
              lastSaveValues?.allergensTitleFontSize.toString() ||
              personalizeTheme?.allergensTitleFontSize.toString() ||
              allergensTitleFontSize.toString(),
            allergensTitleColor:
              lastSaveValues?.allergensTitleColor.toString() ||
              personalizeTheme?.allergensTitleColor.toString() ||
              allergensTitleColor.toString(),
            allergensTextFontSize:
              lastSaveValues?.allergensTextFontSize.toString() ||
              personalizeTheme?.allergensTextFontSize.toString() ||
              allergensTextFontSize.toString(),
            allergensTextColor:
              lastSaveValues?.allergensTextColor.toString() ||
              personalizeTheme?.allergensTextColor.toString() ||
              allergensTextColor.toString(),
          };
          console.log(
            "[checkAllIsSaved][Personaize][notpersonalize] else",
            newValues
          );
          setLastSaveValues(newValues);
          console.log(
            "[checkAllIsSaved][Personaize][notpersonalize] is equal ",
            JSON.stringify(newValues) === JSON.stringify(lastSaveValues)
          );
          setAllIsSaved(true);
        }
      }
    }
  );

  React.useEffect(() => {
    console.log("[checkAllIsSaved][Personaize] from useEffect");
    checkAllIsSaved(personalizeActivated);
  }, [
    personalizeActivated,
    backgroundColor,
    backgroundImage,
    ourBackgroundImage,
    backgroundImageOpacity,
    buttonsColor,
    linesColor,
    iconsColor,
    menuButtonSize,
    menuButtonAlign,
    menuButtonContentAlign,
    textsColor,
    secondaryTextsColor,
    titlesFontSize,
    textsFontSize,
    categoriesFontSize,
    importantTextsFontSize,
    informationText,
    informationSubtitleText,
    informationsLinesColor,
    informationCopiedTextColor,
    reviewButtonTextColor,
    reviewButtonText,
    reviewPageTitleText,
    reviewFeedbackText,
    reviewCloseButtonText,
    reviewSendButtonText,
    reviewButtonColor,
    activeStarColor,
    promotionalBackgroundColor,
    promotionalTextColor,
    promotionalTextSize,
    promotionalBorderColor,
    allergensTitleFontSize,
    allergensTitleColor,
    allergensTextFontSize,
    allergensTextColor,
  ]);

  React.useEffect(() => {
    console.log(
      "backgroundImage, ourBackgroundImage",
      backgroundImage,
      ourBackgroundImage,
      backgroundImageFetched
    );
    if (stylesFetched && backgroundImage?.value !== backgroundImageFetched) {
      setBackgroundImageChanged(true);
    }
  }, [backgroundImage, ourBackgroundImage]);

  React.useEffect(() => {
    if (canLoad === true) {
      console.error("canLoad", canLoad);
      handleLoad();
    }
  }, [canLoad]);

  React.useEffect(() => {
    changePreviewStyle(personalizeActivated);
  }, [checkTheme]);

  const reinitializeSpecificFieldFromTheme = (field) => {
    const settings = getSettings();
    const theme = settings?.appearance?.themeColor;
    const themeColor = theme === "LIGHT_THEME" ? LIGHT_THEME : DARK_THEME;

    const fieldMap = {
      backgroundColor: () => setBackgroundColor(themeColor.backgroundColor),
      backgroundImage: () => setBackgroundImage(null),
      backgroundImageOpacity: () => setBackgroundImageOpacity(0.5),
      buttonsColor: () => setButtonsColor(themeColor.cardColor),
      linesColor: () => setLinesColor(themeColor.borderColor),
      iconsColor: () => setIconsColor(themeColor.iconColor),
      menuButtonSize: () => setMenuButtonSize("LARGE"),
      menuButtonAlign: () => setMenuButtonAlign("LEFT"),
      menuButtonContentAlign: () => setMenuButtonContentAlign("LEFT"),
      textsColor: () => setTextsColor(themeColor.textsColor),
      secondaryTextsColor: () =>
        setSecondaryTextsColor(themeColor.subtitlesColor),
      titlesFontSize: () => setTitlesFontSize(themeColor.titleTextSize),
      textsFontSize: () => setTextsFontSize(themeColor.textFontSize),
      categoriesFontSize: () =>
        setCategoriesFontSize(themeColor.categoryTextSize),
      importantTextsFontSize: () =>
        setImportantTextsFontSize(themeColor.importantTextSize),
      informationText: () => setInformationText("Informations"),
      informationSubtitleText: () =>
        setInformationSubtitleText("Téléphone, adresse et réseaux sociaux"),
      informationsLinesColor: () =>
        setInformationsLinesColor(themeColor.borderColor),
      informationCopiedTextColor: () =>
        setInformationCopiedTextColor(themeColor.greenText),
      reviewButtonColor: () =>
        setReviewButtonColor(themeColor.reverseBackgroundColor),
      reviewButtonTextColor: () =>
        setReviewButtonTextColor(themeColor.reverseTextColor),
      reviewButtonText: () => setReviewButtonText("Noter l'établissement"),
      reviewPageTitleText: () =>
        setReviewPageTitleText("Noter l'établissement"),
      reviewFeedbackText: () =>
        setReviewFeedbackText("Merci, votre avis est important pour nous !"),
      reviewCloseButtonText: () => setReviewCloseButtonText("Fermer"),
      reviewSendButtonText: () => setReviewSendButtonText("Envoyer"),
      activeStarColor: () => setActiveStarColor(themeColor.goldColor),
      promotionalBackgroundColor: () =>
        setPromotionalBackgroundColor(themeColor.promotionalBackgroundColor),
      promotionalTextColor: () =>
        setPromotionalTextColor(themeColor.promotionalTextColor),
      promotionalTextSize: () =>
        setPromotionalTextSize(themeColor.promotionalTextSize),
      promotionalBorderColor: () =>
        setPromotionalBorderColor(themeColor.promotionalBorderColor),
      allergensTitleFontSize: () =>
        setAllergensTitleFontSize(themeColor.allergensTitleFontSize),
      allergensTitleColor: () =>
        setAllergensTitleColor(themeColor.allergensTitleColor),
      allergensTextFontSize: () =>
        setAllergensTextFontSize(themeColor.allergensTextFontSize),
      allergensTextColor: () =>
        setAllergensTextColor(themeColor.allergensTextColor),
    };

    const resetFunction = fieldMap[field];
    if (resetFunction) {
      resetFunction();
    } else {
      console.warn(
        `Field "${field}" not found in reinitializeSpecificFieldFromTheme`
      );
    }
  };

  const contextValue = useMemo(
    () => ({
      personalizeActivated,
      setPersonalizeActivated,
      backgroundColor,
      setBackgroundColor,
      backgroundImage,
      setBackgroundImage,
      backgroundImageOpacity,
      setBackgroundImageOpacity,
      buttonsColor,
      setButtonsColor,
      linesColor,
      setLinesColor,
      iconsColor,
      setIconsColor,
      menuButtonSize,
      setMenuButtonSize,
      menuButtonAlign,
      setMenuButtonAlign,
      menuButtonContentAlign,
      setMenuButtonContentAlign,
      ourBackgroundImage,
      setOurBackgroundImage,
      textsColor,
      setTextsColor,
      secondaryTextsColor,
      setSecondaryTextsColor,
      titlesFontSize,
      setTitlesFontSize,
      textsFontSize,
      setTextsFontSize,
      categoriesFontSize,
      setCategoriesFontSize,
      importantTextsFontSize,
      setImportantTextsFontSize,
      informationText,
      setInformationText,
      informationSubtitleText,
      setInformationSubtitleText,
      informationsLinesColor,
      setInformationsLinesColor,
      informationCopiedTextColor,
      setInformationCopiedTextColor,
      reviewButtonTextColor,
      setReviewButtonTextColor,
      reviewButtonText,
      setReviewButtonText,
      reviewPageTitleText,
      setReviewPageTitleText,
      reviewFeedbackText,
      setReviewFeedbackText,
      reviewCloseButtonText,
      setReviewCloseButtonText,
      reviewSendButtonText,
      setReviewSendButtonText,
      reviewButtonColor,
      setReviewButtonColor,
      activeStarColor,
      setActiveStarColor,
      promotionalBackgroundColor,
      setPromotionalBackgroundColor,
      promotionalTextColor,
      setPromotionalTextColor,
      promotionalTextSize,
      setPromotionalTextSize,
      promotionalBorderColor,
      setPromotionalBorderColor,
      allergensTitleFontSize,
      setAllergensTitleFontSize,
      allergensTitleColor,
      setAllergensTitleColor,
      allergensTextFontSize,
      setAllergensTextFontSize,
      allergensTextColor,
      setAllergensTextColor,
      handleSaveStyle,
      handleChangePersonalizeActivation,
      handleShowContent,
      showContent,
      stylesFetched,
      personalizeActivated,
      checkTheme,
      setCheckTheme,
      setCanLoad,
      reinitializeSpecificFieldFromTheme,
    }),
    [
      personalizeActivated,
      setPersonalizeActivated,
      backgroundColor,
      setBackgroundColor,
      backgroundImage,
      setBackgroundImage,
      backgroundImageOpacity,
      setBackgroundImageOpacity,
      buttonsColor,
      setButtonsColor,
      linesColor,
      setLinesColor,
      iconsColor,
      setIconsColor,
      menuButtonSize,
      setMenuButtonSize,
      menuButtonAlign,
      setMenuButtonAlign,
      menuButtonContentAlign,
      setMenuButtonContentAlign,
      ourBackgroundImage,
      setOurBackgroundImage,
      textsColor,
      setTextsColor,
      secondaryTextsColor,
      setSecondaryTextsColor,
      titlesFontSize,
      setTitlesFontSize,
      textsFontSize,
      setTextsFontSize,
      categoriesFontSize,
      setCategoriesFontSize,
      importantTextsFontSize,
      setImportantTextsFontSize,
      informationText,
      setInformationText,
      informationSubtitleText,
      setInformationSubtitleText,
      informationsLinesColor,
      setInformationsLinesColor,
      informationCopiedTextColor,
      setInformationCopiedTextColor,
      reviewButtonTextColor,
      setReviewButtonTextColor,
      reviewButtonText,
      setReviewButtonText,
      reviewPageTitleText,
      setReviewPageTitleText,
      reviewFeedbackText,
      setReviewFeedbackText,
      reviewCloseButtonText,
      setReviewCloseButtonText,
      reviewSendButtonText,
      setReviewSendButtonText,
      reviewButtonColor,
      setReviewButtonColor,
      activeStarColor,
      setActiveStarColor,
      promotionalBackgroundColor,
      setPromotionalBackgroundColor,
      promotionalTextColor,
      setPromotionalTextColor,
      promotionalTextSize,
      setPromotionalTextSize,
      promotionalBorderColor,
      setPromotionalBorderColor,
      allergensTitleFontSize,
      setAllergensTitleFontSize,
      allergensTitleColor,
      setAllergensTitleColor,
      allergensTextFontSize,
      setAllergensTextFontSize,
      allergensTextColor,
      setAllergensTextColor,
      handleSaveStyle,
      handleChangePersonalizeActivation,
      handleShowContent,
      showContent,
      stylesFetched,
      personalizeActivated,
      checkTheme,
      setCheckTheme,
      reinitializeSpecificFieldFromTheme,
    ]
  );

  return (
    <PersonalizeContext.Provider value={contextValue}>
      {children}
    </PersonalizeContext.Provider>
  );
};
