import React from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import InputTemplate from "./InputTemplate";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import CheckIcon from "@mui/icons-material/Check";

const Wifi = ({ wifiName, password }) => {
  const [copied, setCopied] = React.useState(false);

  function copyToClipboard(text, message) {
    function showMessage() {
      requestAnimationFrame(() => {
        setCopied(true);
      });

      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          showMessage();
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.style.zIndex = "999";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          showMessage();
        } else {
          console.error("Fallback: Oops, unable to copy");
        }
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  }

  return (
    <InputTemplate
      leftIcon={
        <WifiOutlinedIcon
          sx={{ height: 24, padding: "0px 24px" }}
          htmlColor={"var(--icon-color)"}
        />
      }
      rightIcon={{
        icon: (
          <ContentCopyOutlinedIcon
            sx={{ height: 24, padding: "0px 24px" }}
            htmlColor={"var(--icon-color)"}
          />
        ),
        onClick: () => {
          copyToClipboard(password, "Password copied to clipboard");
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p
          style={{
            fontSize: "var(--text-size)",
            margin: 0,
            color: "var(--text-color)",
            fontWeight: "500",
            padding: "12px 12px 12px 0px",
          }}
        >
          {wifiName ?? ""}
        </p>

        <div
          style={{
            opacity: copied ? 1 : 0,
            transition: "opacity 0.5s",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginLeft: "auto",
          }}
        >
          <p
            style={{
              fontSize: "var(--text-size)",
              margin: 0,
              color: "var(--green-text)",
              fontWeight: "500",
              padding: "12px 12px 12px 0px",
            }}
          >
            Mot de passe copié
          </p>
          <CheckIcon
            sx={{ height: 24, color: "var(--green-text)" }}
            color="var(--green-text)"
          />
        </div>
      </div>
    </InputTemplate>
  );
};

export default Wifi;
