import * as React from "react";
import CustomModal from "../Modal/Modal";
import EditOpeningHours from "./EditOpeningHours";

export default function EditOpeningHoursModal({ open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <EditOpeningHours closeModal={handleClose} />
    </CustomModal>
  );
}
