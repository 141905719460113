import React from "react";
import { Container, Slider } from "@mui/material";

function DoliSlider({ value, onChange, max, step }) {
  return (
    <Container>
      <Slider
        step={step}
        aria-label="Temperature"
        defaultValue={0.5}
        max={max}
        value={value}
        onChange={(e, value) => onChange(e, value)}
        style={{
          color: "var(--purple-color)",
        }}
        sx={{
          width: "100%",
          color: "var(--purple-color)",
          "& .MuiSlider-thumb": {},
          "& .MuiSlider-thumb:hover": {
            boxShadow:
              "0px 0px 0px 8px var(--primary-purple-color-transparent)",
          },
          "& .MuiSlider-thumb.Mui-focusVisible": {
            boxShadow:
              "0px 0px 0px 8px var(--primary-purple-color-transparent)",
          },
        }}
      />
    </Container>
  );
}

export default DoliSlider;
