import { Container } from "@mui/material";
import * as React from "react";
import { imagesList } from "../../../../imagesList";
import Image from "../../next/Image";
export default function ProductCard({
  name,
  description,
  price,
  basicsSx,
  illustrationUrl,
  image,
  onClick,
}) {
  const imageLoader = ({ src, width, quality }) => {
    return illustrationUrl;
  };

  return (
    <Container
      onClick={() => onClick()}
      sx={{
        ...basicsSx,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 24px",
        cursor: "pointer",
        borderBottom: "var(--border)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          width: "100%",
        }}
      >
        {name && (
          <h2
            style={{
              margin: 0,
              fontSize: "var(--important-text-size)",
              fontWeight: "500",
              color: "var(--text-color)",
            }}
          >
            {name}
          </h2>
        )}
        {price && (
          <p
            style={{
              margin: 0,
              fontSize: 'calc(var(--text-size) - 1px)',
              fontWeight: "400",
              color: "var(--subtitles-color)",
            }}
          >
            {`${price} €`}
          </p>
        )}
        {description && (
          <p
            style={{
              margin: 0,
              fontSize: 'calc(var(--text-size) - 1px)',
              color: "var(--text-color)",
              fontWeight: "400",
              opacity: 0.8,
            }}
          >
            {description}
          </p>
        )}
      </div>

      <Container
        sx={{
          padding: 0,
          height: 112,
          marginLeft: "auto",
          display: "flex",
          justifyContent: "flex-end",
          padding: '0px !important',
        }}
      >
        {illustrationUrl && (
          <Image
            loader={imageLoader}
            src="banner.png"
            alt={`${name} banner`}
            sizes="100vw"
            style={{
              width: "112px",
              objectFit: "cover",
              height: 112,
              overflow: "clip",
              overflowClipMargin: "content-box",
              borderRadius: 8,
            }}
            width={112}
            height={112}
          />
        )}
        {image && imagesList.find((img) => img.name === image) && (
          <Image
            src={imagesList.find((img) => img.name === image).image}
            alt={`${name} banner`}
            sizes="100vw"
            style={{
              width: "112px",
              objectFit: "cover",
              height: 112,
              overflow: "clip",
              overflowClipMargin: "content-box",
              borderRadius: 8,
            }}
            width={112}
            height={112}
          />
        )}
      </Container>
    </Container>
  );
}
