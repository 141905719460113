export const handleRetriveImage = async (
  image,
  ourImage,
  imageList,
  callback
) => {
  if (image !== null) {
    console.log(
      "[ImageService][handleRetriveImage] if backgroundImage",
      image,
      ourImage
    );
    if (image?.isOur) {
      console.log("[ImageService][handleRetriveImage] if isOur");
      const imageFound = imageList.find(
        (img) => img.name === image.value
      ).image;
      console.log("if (backgroundImage.isOur) {");
      return callback(imageFound);
    } else {
      console.log("[ImageService][handleRetriveImage] if !isOur");
      if (image?.image) {
        console.log(
          "[ImageService][handleRetriveImage] image.image",
          image.image
        );
        return callback(image.image);
      } else if (image?.value) {
        console.log("[ImageService][handleRetriveImage] image.value");
        return callback(image?.value);
      } else {
        console.log("[ImageService][handleRetriveImage] !image.image");
        return callback(image);
      }
    }
  } else if (ourImage) {
    console.log("[ImageService][handleRetriveImage] elseif ourImage", ourImage);

    const imageFound = ourImage?.value?.name
      ? imageList.find((img) => img.name === ourImage?.value?.name).image
      : imageList.find((img) => img.name === ourImage).image;
    console.log("[ImageService][handleRetriveImage] ourImage", imageFound);
    return callback(imageFound);
  }
};
