import React from "react";
import { useTranslation } from "react-i18next";
import { PersonalizeContext } from "../Context/PersonalizeContext";
import CustomListButton from "../common/CustomListButton";
import DoliNotMainSidebarDetailContainer from "../common/DoliNotMainSidebarDetailContainer";
import { Container } from "@mui/material";

function ActivatePersonalization({ children }) {
  const { t } = useTranslation("global");
  const { personalizeActivated, handleChangePersonalizeActivation } =
    React.useContext(PersonalizeContext);
  return (
    <>
      {!personalizeActivated && (
        <DoliNotMainSidebarDetailContainer title={""}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              textAlign: "center",
              gap: "15px",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "var(--black-text-color)",
              }}
            >
              La personnalisation est désactivée.
            </div>
            <div>
              Vous devez activer la personnalisation afin de pouvoir faire des changements
              en temps réel.
            </div>

            <CustomListButton
              text={"Activer la personnalisation"}
              primary={true}
              onClick={() => handleChangePersonalizeActivation()}
              minWidth="50%"
            />
          </Container>
        </DoliNotMainSidebarDetailContainer>
      )}
      {personalizeActivated && children}
    </>
  );
}

export default ActivatePersonalization;
