import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import UploadFolderButton from "../../common/UploadFolder/UploadFolderButton";
import { bannerImagesList } from "../../../bannerImagesList";
import { InformationsContext } from "../../Context/InformationsContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import CustomListButton from "../../common/CustomListButton";
import DoliNotificationPopup from "../../common/DoliNotificationPopup";
import { useNotification } from "../../../services/NotificationPopup";

function Appearance() {
  const { t } = useTranslation("global");
  const { theme, setTheme, image, setImage, customImage, setCustomImage } =
    React.useContext(InformationsContext);
  const [isNotificationOpen, setNotificationOpen, showNotification] =
    useNotification();

  const handleThemeChange = React.useCallback(
    (newTheme) => {
      console.log("[Appearance] changeTheme", newTheme);
      setTheme(newTheme);
      showNotification();
    },
    [setTheme, showNotification]
  );

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarSettingsApparenceDetailsTitle")}
      style={{ height: "100%" }}
    >
      <div
        style={{
          gap: 15,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddItemInputContainer
          name={t("settingsCoverImageName")}
          description={t("settingsCoverImageNameInformation")}
          titleFontSize={15}
          subTitleFontSize={15}
          notTextField={true}
        >
          <UploadFolderButton
            imageListToUse={bannerImagesList}
            showCategory={false}
            image={image}
            //setImage={setImage}
            customImage={
              customImage && customImage.image !== null
                ? customImage
                : { image: customImage }
            }
            //setCustomImage={setCustomImage}
            saveFunction={(image, isCustom) => {
              console.log("[Apparence]saveFunction", image, isCustom);
              let customImageEnd = null;
              let imageEnd = null;
              if (image?.extension) {
                console.log("[Apparence]saveFunction isCustom");
                customImageEnd = image;
                imageEnd = null;
                setCustomImage(image);
              } else {
                console.log("[Apparence]saveFunction not Custom", image.name);
                customImageEnd = null;
                imageEnd = {
                  value: { name: image.name, image: image.image },
                  isOur: true,
                };
              }
              setImage(imageEnd);
              setCustomImage(customImageEnd);
              console.log("[Apparence] image", imageEnd, customImageEnd);
            }}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("sidebarSettingsThemeText")}
          notTextField={true}
          style={{ boxSixing: "content-box !important" }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "6px 0px 0px 0px !important",
              gap: "15px",
              width: "100%",
              boxSizing: "content-box !important",
            }}
          >
            {isNotificationOpen && (
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                }}
              >
                <DoliNotificationPopup
                  open={isNotificationOpen}
                  setOpen={setNotificationOpen}
                  title={t("themeChangeNotificationTitle")}
                  message={t("themeChangeNotificationSubTitle")}
                  showArrow={false}
                />
              </div>
            )}

            <Container
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: "6px 0px 0px 0px !important",
                gap: "15px",
                width: "100%",
                boxSizing: "content-box !important",
              }}
            >
              <CustomListButton
                text={t("textLightTheme")}
                primary={theme === "LIGHT_THEME"}
                onClick={() => handleThemeChange("LIGHT_THEME")}
                minWidth="30%"
              />
              <CustomListButton
                text={t("textDarkTheme")}
                primary={theme === "DARK_THEME"}
                onClick={() => handleThemeChange("DARK_THEME")}
                minWidth="30%"
              />
            </Container>
          </Container>
        </AddItemInputContainer>
      </div>
    </DoliNotMainSidebarDetailContainer>
  );
}

export default Appearance;
