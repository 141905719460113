import React, { useState, useRef } from "react";
import { Document, Page, View, Image, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import {
  downloadPNG,
  generatePNGDataURL,
} from "../../../services/qrCodeDownloadService";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import { Container, MenuItem, OutlinedInput, Select } from "@mui/material";
import CustomListButton from "../../common/CustomListButton";
import { qrCodeSVGPreview } from "./QRCodeSVGPreview";
import { qrCodeDropdownOptions } from "../../../consts";
import { styles } from "./QRCodePdfStyles";
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import { QRCodeContext } from "../../Context/QRCodeContext";

function QRCodePreview() {
  const { t } = useTranslation("global");
  const { currentMerchantId } = React.useContext(UserContext);
  const {
    QRBackgroundColor,
    QRColor,
    frameBackgroundColor,
    menuText,
    menuTextColor,
    fontSize,
  } = React.useContext(QRCodeContext);

  const [selectedFormat, setSelectedFormat] = useState("svg");
  const svgRef = useRef(null);

  const svgCode = qrCodeSVGPreview({
    QRBackgroundColor,
    QRColor,
    FrameBackgroundColor: frameBackgroundColor,
    menuTextColor,
    menuText,
    fontSize,
    currentMerchantId,
  });

  const generatePDF = async (
    length,
    qrCodeStyle,
    qrCodeImageStyle,
    filename
  ) => {
    const pngDataURLs = await Promise.all(
      Array.from({ length }).map(() => generatePNGDataURL(svgRef))
    );

    const QRCodePDF = () => (
      <Document>
        <Page
          size="A4"
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          {pngDataURLs.map((dataURL, index) => (
            <View key={index} style={qrCodeStyle}>
              <Image src={dataURL} style={qrCodeImageStyle} />
            </View>
          ))}
        </Page>
      </Document>
    );
    const pdfBlob = await pdf(<QRCodePDF />).toBlob();
    saveAs(pdfBlob, filename);
  };

  const handleDownload = async () => {
    switch (selectedFormat) {
      case "pdf-16":
        await generatePDF(
          16,
          styles.qrCodeLittle,
          styles.qrCodeImageLittle,
          "qr-codes-16.pdf"
        );
        break;
      case "pdf-9":
        await generatePDF(
          9,
          styles.qrCodeMedium,
          styles.qrCodeImageMedium,
          "qr-codes-9.pdf"
        );
        break;
      case "pdf-4":
        await generatePDF(
          4,
          styles.qrCodeBig,
          styles.qrCodeImageBig,
          "qr-codes-4.pdf"
        );
        break;
      case "svg":
        const svgElement = svgRef.current;
        const data = new XMLSerializer().serializeToString(svgElement);
        const blob = new Blob([data], { type: "image/svg+xml" });
        saveAs(blob, "qr-code.svg");
        break;
      case "png":
        downloadPNG(svgRef);
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 6,
      }}
    >
      <Container
        ref={svgRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          margin: "0px",
        }}
        sx={{
          zoom: { xs: "80%", sm: "73%", md: "73%", lg: "80%" },
        }}
        dangerouslySetInnerHTML={{ __html: svgCode }}
      />
      <AddItemInputContainer
        name={""}
        notTextField={true}
        titleFontSize={"15px"}
        fontSize={"18px"}
        heightProps={40}
        style={{
          padding: "0px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "start",
            width: "100%",
          }}
        >
          <Select
            displayEmpty
            input={<OutlinedInput />}
            value={selectedFormat}
            onChange={(e) => setSelectedFormat(e.target.value)}
            style={{
              width: "100%",
              height: 40,
              fontSize: 15,
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            {qrCodeDropdownOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </AddItemInputContainer>

      <CustomListButton
        onClick={() => handleDownload()}
        styles={{
          width: "100%",
          height: 46,
          fontSize: 16,
          fontWeight: "600 !important",
        }}
        text={t("textDownload")}
      />
    </div>
  );
}

export default QRCodePreview;
