import { Container, FormControlLabel, Radio } from "@mui/material";
import React from "react";

function ThemeCard({ value, label }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "var(--borderRadiusCard)",
        border: "1px solid var(--border-color)",
      }}
    >
      <Container
        sx={{
          padding: {
            xs: "14px 14px 0px 14px",
            sm: "24px 24px 0px 24px",
            md: "24px 34px 0px 34px",
          },
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src="https://via.placeholder.com/50"
          style={{
            width: "170px",
            height: "180px",
          }}
          alt="dark theme"
        />
      </Container>
      <div
        style={{
          borderTop: "1px solid var(--border-color)",
          padding: "0px 14px",
        }}
      >
        <FormControlLabel
          value={value}
          control={<Radio size={"30px"} />}
          label={label}
          sx={
            ({ ".MuiTypography-root": { fontSize: 17 } },
            { "span > span": { fontSize: 20 } })
          }
        />
      </div>
    </div>
  );
}

export default ThemeCard;
