import React from "react";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import Divider from "../common/Divider";
import GoogleIcon from "../assets/googleIcon.png";
import DoliLogo from "../assets/navLogo.png";
import { AuthContext } from "../Context/AuthContext";
import SuccessModal from "../common/SuccessModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoginPageTemplate({
  headerButton,
  title,
  description,
  titleAlignLeft = false,
  textFields,
  signInGoogle = true,
  divider = true,
  primaryButton,
  error,
  success,
}) {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const { handleSignInWithGoogle } = React.useContext(AuthContext);

  const handleGoogleSignIn = async () => {
    await handleSignInWithGoogle();
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px !important",
          maxWidth: 650,
          height: "100%",
        }}
        sx={{
          padding: "24px 0px !important",
        }}
      >
        <Container
          sx={{
            ".textfiel-add > input": {
              padding: "0px important!",
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container
              sx={{
                padding: { xs: "0px 32px 32px 32px", sm: "0px 0px 0px 42px" },
                width: { xs: "100%", sm: "auto" },
                marginRight: { xs: "auto", sm: "0px" },
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    width: 50,
                    height: 50,
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${DoliLogo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: 35,
                      height: 35,
                    }}
                  />
                </div>
                <Container
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    padding: "0px 0px 0px 0px !important",
                    margin: "0px !important",
                  }}
                >
                  <div
                    style={{
                      fontSize: 25,
                      textAlign: "left",
                      color: "var(--black-color)",
                      fontWeight: "800",
                    }}
                  >
                    DoliQR
                  </div>
                </Container>
              </div>
            </Container>
            <Container
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                padding: "0px 40px 0px 0px !important",
              }}
            >
              {headerButton && headerButton}
            </Container>
          </div>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
              marginTop: "auto",
              justifyContent: "center",
              minHeight: { xs: "none", sm: "calc(100vh - 84px)" },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <h1
                style={{
                  padding: 0,
                  margin: 0,
                  marginRight: titleAlignLeft ? "auto" : "",

                }}
              >
                {title}
              </h1>
              {description && (
                <div
                  style={{
                    padding: 0,
                    margin: 0,
                    marginTop: 16,
                    color: "var(--category-secondary-text-color)",
                    marginRight: 'auto'
                  }}
                >
                  {description}
                </div>
              )}
            </div>
            {signInGoogle && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <CustomListButton
                  onClick={() => handleGoogleSignIn()}
                  backgroundColorProps={"var(--google-button-background-color)"}
                  iconBefore={
                    <div
                      style={{
                        backgroundImage: `url(${GoogleIcon})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: 25,
                        height: 25,
                        marginRight: 10,
                      }}
                    />
                  }
                  styles={{
                    width: "100%",
                    height: 46,
                    fontSize: 16,
                    fontWeight: "500 !important",
                  }}
                  text={t("loginPageContinueWithGoogle")}
                  primary={false}
                />
              </div>
            )}
            {divider && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <Divider />
                <div
                  style={{
                    color: "var(--category-secondary-text-color)",
                    fontSize: 12,
                    fontWeight: "700",
                    padding: "0px 10px",
                  }}
                >
                  {t("textOr").toUpperCase()}
                </div>
                <Divider />
              </div>
            )}
            {error && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                  padding: "0px 16px",
                  marginTop: -20,
                  marginBottom: -20,
                }}
              >
                <p style={{ color: "red" }}>{error}</p>
              </div>
            )}
            {textFields}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <CustomListButton
                onClick={primaryButton.onClick}
                styles={{
                  width: "100%",
                  height: 46,
                  fontSize: 16,
                  fontWeight: "600 !important",
                }}
                text={primaryButton.text}
              />
            </div>
          </Container>
          <Container
            sx={{
              display: { xs: "flex", sm: "none" },
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              padding: "32px 0px",
            }}
          >
            {headerButton && headerButton}
          </Container>
        </Container>
      </Container>
      {success && (
        <SuccessModal
          open={true}
          handleClose={() => navigate(`/sign-in`)}
          successMessage={success}
        />
      )}
    </div>
  );
}

export default LoginPageTemplate;
