import React from "react";
import InputTemplate from "./InputTemplate";
import CallIcon from "@mui/icons-material/Call";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const PhoneNumber = ({ number }) => {
  const callNumber = (phoneNumber) => {
    if (!phoneNumber) {
      console.error("No phone number provided");
      return;
    }
    if (typeof window !== "undefined") {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      console.error("Cannot make a call in a non-browser environment");
    }
  };
  return (
    <InputTemplate
      leftIcon={
        <CallIcon
          sx={{ height: 24, padding: "0px 24px" }}
          htmlColor={"var(--icon-color)"}
        />
      }
      rightIcon={{
        icon: (
          <ArrowForwardIosIcon
            sx={{ height: 24, padding: "0px 24px" }}
            htmlColor={"var(--icon-color)"}
          />
        ),
        onClick: () => {
          callNumber(number);
        },
      }}
    >
      <div>
        <p
          style={{
            fontSize: "var(--text-size)",
            margin: 0,
            color: "var(--text-color)",
            fontWeight: "500",
            padding: "12px 12px 12px 0px",
          }}
        >
          {number ?? ""}
        </p>
      </div>
    </InputTemplate>
  );
};

export default PhoneNumber;
