import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import { useTranslation } from "react-i18next";

function LoginInputTextField({
  forgotTextFiel = false,
  value,
  setter,
  title,
  placeholder,
  forgotClick,
  autoComplete,
  padding = "0px 16px",
  type = "text",
  name,
  startAdornment,
  endAdornment = <></>,
  disabled = false,
}) {
  const { t } = useTranslation("global");

  return (
    <>
      {!forgotTextFiel && (
        <AddItemInputContainer
          type={type}
          autoComplete={autoComplete}
          placeholder={placeholder}
          value={value}
          inputValueProps={value}
          disabled={disabled}
          setter={setter}
          style={{
            padding: padding,
          }}
          nameProps={name ?? title}
          name={title}
          heightProps={40}
          titleFontSize={"15px"}
          fontSize={"18px"}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
        />
      )}
      {forgotTextFiel && (
        <AddItemInputContainer
          autoComplete={autoComplete}
          type={type}
          nameProps={name ?? title}
          value={value}
          inputValueProps={value}
          disabled={disabled}
          setter={setter}
          style={{
            padding: padding,
          }}
          rightName={
            <div
              onClick={forgotClick}
              style={{
                fontSize: 15,
                fontWeight: "500",
                color: "var(--blue-text-color)",
                textAlign: "start",
                marginBottom: 6,
                cursor: "pointer",
              }}
            >
              {t("loginPageForgotPassword")}
            </div>
          }
          name={title}
          heightProps={40}
          titleFontSize={"15px"}
          fontSize={"18px"}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
        />
      )}
    </>
  );
}

export default LoginInputTextField;
