import Beach1 from "./components/assets/backgroundImages/beach1.jpg";
import Beach2 from "./components/assets/backgroundImages/beach2.jpg";
import Italy1 from "./components/assets/backgroundImages/italy1.jpg";
import Italy2 from "./components/assets/backgroundImages/italy2.jpg";
import Lavande1 from "./components/assets/backgroundImages/lavande1.jpg";
import Nice1 from "./components/assets/backgroundImages/nice1.jpg";
import Nice2 from "./components/assets/backgroundImages/nice2.jpg";
import Nice3 from "./components/assets/backgroundImages/nice3.jpg";
import Nice4 from "./components/assets/backgroundImages/nice4.jpg";
import Sea1 from "./components/assets/backgroundImages/sea1.jpg";
import Sea2 from "./components/assets/backgroundImages/sea2.jpg";

export const backgroundList = [
  {
    name: "beach1",
    image: Beach1,
    category: ["beach"],
  },
  {
    name: "beach2",
    image: Beach2,
    category: ["beach"],
  },
  {
    name: "italy1",
    image: Italy1,
    category: ["italy"],
  },
  {
    name: "italy2",
    image: Italy2,
    category: ["italy"],
  },
  {
    name: "lavande1",
    image: Lavande1,
    category: ["lavande"],
  },
  {
    name: "nice1",
    image: Nice1,
    category: ["nice"],
  },
  {
    name: "nice2",
    image: Nice2,
    category: ["nice"],
  },
  {
    name: "nice3",
    image: Nice3,
    category: ["nice"],
  },
  {
    name: "nice4",
    image: Nice4,
    category: ["nice"],
  },
  {
    name: "sea1",
    image: Sea1,
    category: ["sea"],
  },
  {
    name: "sea2",
    image: Sea2,
    category: ["sea"],
  },
];
