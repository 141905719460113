import { AnimatePresence, motion } from "framer-motion";
import styles from "./NotFullPageModal.module.css";
import CloseIcon from "@mui/icons-material/Close";

const NotFullPageModal = ({
  isVisible,
  onClose,
  showCloseButton = true,
  children,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={`${styles.Overlay} ${isVisible ? styles.Show : ""}`}
          onClick={() => onClose()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={styles.Modal}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 40 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {showCloseButton && (
                <div
                  style={{
                    width: "100%",
                    position: "fixed",
                  }}
                >
                  <div
                    onClick={() => onClose()}
                    style={{
                      margin: "8px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "var(--card-color)",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                  >
                    <CloseIcon
                      sx={{ height: 18, width: 18 }}
                      htmlColor={"var(--icon-color)"}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  padding: "8px 0px",
                  width: "100% !important",
                }}
              >
                {children}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NotFullPageModal;
