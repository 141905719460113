import React from "react";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import Divider from "../common/Divider";
import { UserContext } from "../Context/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";

// TODO(chris): Clean warrings
function UserDropdown({
  anchorElUser,
  handleCloseUserMenu,
  handleCloseDrawer,
  isMobile = false,
}) {
  const navigate = useNavigate();
  const { logout, extractFirebaseProfileInformations } =
    React.useContext(AuthContext);
  const { currentMerchantName, doliQrUser, changeMerchant, currentMerchantId } =
    React.useContext(UserContext);

  const [showChangeMerchant, setShowChangeMerchant] = React.useState(false);

  const settings = [
    { title: true, name: "Compte", id: "title-compte" },
    { name: "Paramètres", link: "/user/profile", id: 0, stayOpen: false },
    { divider: true },
    { title: true, name: "Restaurant", id: "title-restaurant" },
    {
      name: "Facturation & Forfait",
      link: "/user/plans",
      id: 1,
      stayOpen: false,
    },
    //{ name: "Membres", link: "/user/members", id: 2 },
    {
      name:
        doliQrUser?.merchantsList?.length > 1
          ? "Changer de restaurant"
          : "Ajouter un restaurant",
      onClick: () =>
        doliQrUser?.merchantsList?.length > 1
          ? setShowChangeMerchant(true)
          : navigate("/user/new-restaurant/1"),
      stayOpen: doliQrUser?.merchantsList?.length > 1,
      id: 3,
    },
    { divider: true },
    {
      name: "Déconnexion",
      onClick: () => logout(),
      color: "var(--unwanted-button-color)",
      id: 4,
    },
  ];

  const handleProfileButtonClick = (link, onclick, stayOpen = false) => {
    if (link) {
      navigate(link);
    }
    if (onclick) {
      onclick();
    }
    if (!stayOpen) {
      handleCloseUserMenu();
      if (handleCloseDrawer) {
        handleCloseDrawer();
      }
    }
  };

  return (
    <div>
      <Menu
        sx={{
          ".MuiList-root": {
            padding: "10px 0px !important",
            width: 300,
          },
          ".MuiMenu-root": {
            borderRadius: 50,
          },
          ".MuiMenu-list": { borderRadius: 50 },
          ".MuiMenu-paper": { borderRadius: "12px" },
          ".MuiButtonBase-root, .MuiMenuItem-root": {
            padding: "0px 10px !important",
          },
          mt: "45px",
          padding: "0px !important",
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <div>
          {!showChangeMerchant && (
            <div>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                sx={{ padding: "10px 0px !important" }}
              >
                <div
                  style={{
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  {extractFirebaseProfileInformations()?.photoURL ? (
                    <div
                      alt="profile"
                      style={{
                        height: 64,
                        width: 64,
                        borderRadius: 100,
                        background: `url(${
                          extractFirebaseProfileInformations()?.photoURL
                        }`,
                        backgroundSize: 40,
                      }}
                    />
                  ) : (
                    <Avatar
                      alt={extractFirebaseProfileInformations()?.firstName.toUpperCase()}
                      sx={{
                        width: "64px",
                        height: "64px",
                      }}
                      src="/static/images/avatar/2.jpg"
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: 19,
                    }}
                  >
                    {extractFirebaseProfileInformations()?.firstName}
                  </div>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: 17,
                      color: "var(--category-secondary-text-color)",
                    }}
                  >
                    {currentMerchantName}
                  </div>
                </div>
              </Container>
              <Divider propsStyle={{ margin: "10px 0px" }} />
              {settings.map((setting, index) => (
                <div key={index}>
                  {setting.title && (
                    <div
                      style={{
                        fontWeight: "600",
                        padding: "5px 10px",
                        fontSize: 12,
                      }}
                      key={setting.name}
                    >
                      {setting.name}
                    </div>
                  )}
                  {setting.divider && (
                    <Divider
                      key={setting.name}
                      propsStyle={{ margin: "10px 0px" }}
                    />
                  )}
                  {!setting.divider && !setting.title && (
                    <MenuItem
                      className={"navbarItem"}
                      sx={{ padding: "0px 10px !important" }}
                      key={setting.name}
                      onClick={() =>
                        handleProfileButtonClick(
                          setting.link,
                          setting.onClick ? setting.onClick : () => {},
                          setting.stayOpen
                        )
                      }
                    >
                      <div
                        style={{
                          padding: "5px 0px",
                          fontSize: 15,
                          color: setting.color ?? "",
                        }}
                      >
                        {setting.name}
                      </div>
                    </MenuItem>
                  )}
                </div>
              ))}
            </div>
          )}
          {showChangeMerchant && (
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              sx={{
                padding: "10px 0px 0px !important",
                ".check-icon > .MuiSvgIcon-root": {
                  color: "var(--create-product-primary-button-hover-color)",
                },
              }}
            >
              <div
                style={{
                  padding: "0px 10px !important",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => setShowChangeMerchant(false)}
              >
                <ArrowBackIcon sx={{ marginLeft: "10px", height: 20 }} />
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: 17,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Changer de restaurant
                </div>
                <div style={{ width: "30px" }} />
              </div>
              <Divider propsStyle={{ margin: "20px 0px 10px 0px" }} />
              {doliQrUser.merchantsList.map((merchant) => (
                <MenuItem
                  className={"navbarItem"}
                  sx={{
                    padding: "0px 10px !important",
                    width: "100%",
                    backgroundColor:
                      merchant._id === currentMerchantId
                        ? "var(--create-product-secondary-button-border-color) !important"
                        : "",
                  }}
                  key={merchant._id}
                  onClick={() => {
                    handleProfileButtonClick(
                      "/user/dashboard",
                      () => {
                        handleCloseUserMenu();
                        setShowChangeMerchant(false);
                        changeMerchant(merchant._id);
                      },
                      true
                    );
                  }}
                >
                  <div
                    style={{
                      padding: "5px 0px",
                      fontSize: 15,
                    }}
                  >
                    {merchant.establishmentName}
                  </div>
                  {merchant._id === currentMerchantId && (
                    <div
                      className="check-icon"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <CheckIcon id="check-icon" fontSize="small" />
                    </div>
                  )}
                </MenuItem>
              ))}
              <Divider propsStyle={{ margin: "10px 0px 10px 0px" }} />
              <MenuItem
                className={"navbarItem"}
                sx={{
                  padding: "0px 10px !important",
                  width: "100%",
                }}
                onClick={() => {
                  navigate("/user/new-restaurant/1");
                }}
              >
                <div
                  style={{
                    padding: "5px 0px",
                    fontSize: 15,
                  }}
                >
                  Ajouter un restaurant
                </div>
              </MenuItem>
            </Container>
          )}
        </div>
      </Menu>
    </div>
  );
}
export default UserDropdown;
