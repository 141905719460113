import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { QRCodeContext } from "../../Context/QRCodeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import DoliSlider from "../../common/DoliSlider";

function FrameContent() {
  const { t } = useTranslation("global");
  const {
    frameBackgroundColor,
    setFrameBackgroundColor,
    menuTextColor,
    setMenuTextColor,
    menuText,
    setMenuText,
    fontSize,
    setFontSize,
  } = React.useContext(QRCodeContext);

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarQrCodeFrameTypeDetailsTitle")}
    >
      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeFrameType")}
        notTextField={true}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeFrameBackgroundColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setFrameBackgroundColor}
          color={frameBackgroundColor}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeFrameText")}
        setter={setMenuText}
        value={menuText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeFrameTextColor")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setMenuTextColor} color={menuTextColor} />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("qrCodeFrameFontSize")}
        notTextField={true}
      >
        <DoliSlider
          value={fontSize}
          onChange={(e, value) => setFontSize(value)}
          max={100}
        />
      </AddItemInputContainer>
    </DoliNotMainSidebarDetailContainer>
  );
}

export default FrameContent;
