import {
  StyleSheet,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  qrCodeLittle: {
    width: "25%",
    alignItems: "center",
    padding: 1,
  },
  qrCodeImageLittle: {
    width: 140,
    height: "auto",
  },
  qrCodeMedium: {
    width: "33%",
    alignItems: "center",
    padding: 1,
  },
  qrCodeImageMedium: {
    width: 170,
    height: "auto",
  },
  qrCodeBig: {
    width: "50%",
    alignItems: "center",
    padding: 1,
  },
  qrCodeImageBig: {
    width: 240,
    height: "auto",
  },
});