import React from "react";
import Container from "@mui/material/Container";
import { HoverTransitionStyle } from "./FullPageStyle";

function DoliIcon({
  color = "var(--black-icon-color)",
  iconSize = "var(--icon-size)",
  backgroundColorHoverProps,
  backgroundColor = "transparent",
  iconStyles,
  primary = false,
  onClick = () => {},
  children,
}) {
  return (
    <Container
      onClick={onClick}
      sx={{
        height: "auto",
        width: "auto",
        margin: "0px !important",
        padding: "8px !important",
        display: "flex",
        alignItems: "center",
        borderRadius: "var(--button-border-radius)",
        cursor: "pointer",
        backgroundColor: backgroundColor,
        ...HoverTransitionStyle,
        ".MuiSvgIcon-fontSizeMedium": {
          fontSize: `${iconSize} !important`,
          color: color,
        },
        "&:hover": {
          backgroundColor: backgroundColorHoverProps
            ? backgroundColorHoverProps
            : primary
            ? "var(--purple-color-hover)"
            : "var(--white-button-hover-color)",
        },
        ...iconStyles,
      }}
    >
      {children}
    </Container>
  );
}
export default DoliIcon;
