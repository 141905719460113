import * as React from "react";
import CustomModal from "../Modal/Modal";
import EditPromotionalText from "./EditPromotionalText";

export default function EditPromotionalTextModal({ open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <EditPromotionalText closeModal={handleClose} />
    </CustomModal>
  );
}
