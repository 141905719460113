import * as React from "react";
import EuroIcon from "@mui/icons-material/Euro";
import { Container, InputAdornment } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { Portal } from "react-portal";
import dayjs from "dayjs";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TimePickerSelection from "./time-picker/TimePickerSelection";
import "../common/time-picker/styles/react-ios-time-picker.css";

export default function TimeField({
  rows = 1,
  price,
  value,
  borderRadiusProps,
  startAdornment,
  endAdornment,
  onChange,
  heightProps = 30,
  disabled = false,
  name,
  fontSize,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div id={`${name}-timepicker-container`}>
      <Container
        sx={{
          padding: "0px !important",
          margin: "0px !important",
          display: "flex",
          flex: 3,
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: "10px",
        }}
      >
        <Container
          sx={{
            padding: "0px !important",
            margin: "0px !important",
            display: "flex",
            flex: 1,
          }}
        >
          <TimePicker
            ampm={false}
            showToolbar={true}
            openPickerButton={true}
            onOpen={() => {
              console.log("open");
              setIsOpen(true);
            }}
            onClose={() => setIsOpen(false)}
            onChange={(value) => {
              const newTime = dayjs(value, "HH:mm");
              let stringTime = "";
              if (newTime.hour() < 10) {
                stringTime += `${0}${newTime.hour()}`;
              } else {
                stringTime += newTime.hour();
              }
              stringTime += ":";
              if (newTime.minute() < 10) {
                stringTime += `${0}${newTime.minute()}`;
              } else {
                stringTime += newTime.minute();
              }
              onChange(stringTime);
            }}
            value={dayjs(value, "HH:mm")}
            id={name ?? "fullWidth"}
            name={name}
            disableOpenPicker={true}
            fullWidth
            disabled={disabled}
            sx={{
              ".MuiInputBase-root": {
                borderRadius: borderRadiusProps ?? "4px",
                height: rows * heightProps,
                padding: "0px 0px 5px 0px !important",
                width: "66px !important",
                /*
                    TODO(chris): is here the description bug but affect other textfield
                    padding: "0px 0px 0px 0px !important",
                  */
              },
              "> *": { height: rows * heightProps },
              ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                fontSize: 12,
                height: rows * heightProps,
                position: "absolute",
                "-webkit-transform": "none !important",
                paddingTop: "8px !important",
                paddingLeft: "8px !important",
              },
              fieldset: {
                height: rows * heightProps,
                borderColor: "var(--border-color)",
                width: "66px !important",
                textAlign: "center",
              },
              input: {
                width: "66px !important",
                padding: "0px !important",
                textAlign: "center",
              },
            }}
            slots={{
              dialog: <></>,
              popper: () => {
                return <></>;
              },
            }}
            inputAdornment={{
              startAdornment: (
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              ),
            }}
            componentsProps={{
              dialog: {
                timeIcon: <></>,
              },
            }}
            InputProps={{
              endAdornment:
                endAdornment || price ? (
                  price ? (
                    <InputAdornment position="end">
                      <EuroIcon />
                    </InputAdornment>
                  ) : (
                    endAdornment
                  )
                ) : (
                  <></>
                ),
              startAdornment: startAdornment,
            }}
          />
        </Container>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            alignItems: "center",
            marginTop: -7,
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(true)}
        >
          <ScheduleIcon />
        </div>

        {isOpen && (
          <Portal>
            <div className="react-ios-time-picker-popup">
              <div
                className={`react-ios-time-picker-popup-overlay`}
                onClick={() => setIsOpen(!isOpen)}
              />
              <TimePickerSelection
                onChange={onChange}
                height={28}
                onSave={(e) => {
                  setIsOpen(false);
                }}
                onCancel={(e) => {
                  setIsOpen(false);
                }}
                cancelButtonText="Cancel"
                saveButtonText="Save"
                controllers={true}
                setInputValue={onChange}
                setIsOpen={() => {}}
                seperator={true}
                use12Hours={false}
                onAmPmChange={() => {}}
                initialValue={value ?? "10:00"}
                pickerDefaultValue={value ?? "10:00"}
              />
            </div>
          </Portal>
        )}
      </Container>
    </div>
  );
}
