import React, { useEffect, useState, useRef } from "react";
import Container from "@mui/material/Container";
import ProductListItemContainer from "../common/ProductListItemContainer";
import { BarChart } from "@mui/x-charts";
import { MenuItem, Select, OutlinedInput } from "@mui/material";
import AddItemInputContainer from "../common/AddItemInputContainer";
import { getDateRanges, processData } from "../../services/statsService";
import { API_URL } from "../../consts";
import { UserContext } from "../Context/UserContext";
import { useTitle } from "../../App";
import { useTranslation } from "react-i18next";
import { FullPageSxStyle } from "../common/FullPageStyle";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

const previousPeriodMap = {
  "last week": "last month",
  "last month": "last year",
};

function Stats() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("statsPageTitle")}`);
  const { currentMerchantId } = React.useContext(UserContext);

  const [period, setPeriod] = useState("last week");
  const [dataMap, setDataMap] = useState({
    "last week": { labels: [], data: [] },
    "last month": { labels: [], data: [] },
    "last year": { labels: [], data: [] },
  });
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseGet = await fetch(
          `${API_URL}api/stats/${currentMerchantId}`
        );
        const response = await responseGet.json();
        const visits = response.visits;
        const dateRanges = getDateRanges();
        const newDataMap = {
          "last week": processData(visits, dateRanges.lastWeek, false, false),
          "last month": processData(visits, dateRanges.lastMonth, true, false),
          "last year": processData(visits, dateRanges.lastYear, false, true),
        };
        setDataMap(newDataMap);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  const currentData = dataMap[period]?.data || [];
  const previousData = dataMap[previousPeriodMap[period]]?.data || [];

  const totalVisitsCurrentPeriod = currentData.reduce(
    (sum, value) => sum + value,
    0
  );
  const totalVisitsPreviousPeriod = previousData.reduce(
    (sum, value) => sum + value,
    0
  );

  const percentageChange =
    ((totalVisitsCurrentPeriod - totalVisitsPreviousPeriod) /
      totalVisitsPreviousPeriod) *
    100;
  const percentageText =
    percentageChange > 0
      ? `+${percentageChange.toFixed(2)}%`
      : `${percentageChange.toFixed(2)}%`;

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartWidth(chartContainerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px !important",
          gap: 10,
          marginBottom: 56,
        }}
        sx={{
          //maxWidth: { md: "auto", lg: 1136 },
          padding: { xs: "0px !important", md: "0px 24px !important" },
          "--border-radius-card": {
            xs: "0px",
            md: "8px",
          },
          ...FullPageSxStyle,
        }}
      >
        <Container
          sx={{
            padding: { xs: "0px 16px !important", md: "0px !important" },
            "--border-radius-card": {
              xs: "0px",
              md: "8px",
              ...FullPageSxStyle,
            },
          }}
        >
          <DoliTopPageTitle title={"Statistiques"} />
          <div
            ref={chartContainerRef}
            style={{
              ...FullPageSxStyle,
            }}
          >
            <ProductListItemContainer
              id="stats-container"
              sx={{
                padding: "0px !important",
                ...FullPageSxStyle,
              }}
              style={{
                borderRadius: 6,
                borderBottomLeft: "1px solid var(--border-color) !important",
                padding: "0px !important",
                margin: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        color: "var(--product-card-title-color)",
                        whiteSpace: "nowrap",
                        marginBottom: 4,
                        fontSize: 20,
                      }}
                    >
                      Visites totale : {totalVisitsCurrentPeriod}
                    </span>
                    {/*TODO(chris): Fix percentage issue
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          color: "var(--product-card-title-color)",
                          whiteSpace: "nowrap",
                          fontSize: 20,
                          marginRight: 6,
                        }}
                      >
                        {totalVisitsCurrentPeriod}
                      </span>
                        <span
                        style={{
                          fontWeight: "400",
                          color:
                            percentageChange > 0
                              ? "var(--billing-promotional-text-color)"
                              : "var(--unwanted-button-color)",
                          whiteSpace: "nowrap",
                          fontSize: 15,
                        }}
                      >
                        {percentageText}
                      </span>
                      <Container
                        sx={{
                          padding: "0px !important",
                          margin: "0px 6px !important",
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "400",
                            color: "var(--category-secondary-text-color)",
                            whiteSpace: "nowrap",
                            fontSize: 14,
                          }}
                        >
                          par rapprt à la dernière période
                        </span>
                      </Container>
                    </div>
                      */}
                  </div>
                  <Container
                    sx={{
                      padding: "0px !important",
                      width: { sm: "auto", md: "auto" },
                      margin: "0px !important",
                    }}
                  >
                    <AddItemInputContainer
                      notTextField={true}
                      titleFontSize={"15px"}
                      fontSize={"18px"}
                      heightProps={40}
                      style={{
                        padding: "0px 16px",
                        marginLeft: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "start",
                          width: "100%",
                        }}
                      >
                        <Select
                          displayEmpty
                          input={<OutlinedInput />}
                          value={period}
                          onChange={handleChange}
                          style={{
                            width: "100%",
                            height: 40,
                            fontSize: 18,
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250,
                              },
                            },
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="last week">
                            Dernière Semaine
                          </MenuItem>
                          <MenuItem value="last month">Dernier Mois</MenuItem>
                          <MenuItem value="last year">Dernière Année</MenuItem>
                        </Select>
                      </div>
                    </AddItemInputContainer>
                  </Container>
                </div>
              </div>
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: dataMap[period].labels,
                  },
                ]}
                series={[
                  {
                    data: dataMap[period].data,
                    color: "var(--chart-background-color)",
                    label: "Visite(s)",
                  },
                ]}
                height={364}
              />
            </ProductListItemContainer>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default Stats;
