import React from "react";
import { API_URL } from "../../consts";
import { UserContext } from "./UserContext";
import { SettingsContext } from "./SettingsContext";

export const MenusContext = React.createContext();

export const MenusProvider = ({ children }) => {
  const { currentMerchantId } = React.useContext(UserContext);
  const { setSettings } = React.useContext(SettingsContext);
  const [menus, setMenus] = React.useState([]);

  const fetchMenus = async () => {
    if (!currentMerchantId) return;
    const response = await fetch(
      `${API_URL}api/marchants/${currentMerchantId}/menus`
    );
    console.log("[fetchMenus]response", response);
    console.log(`${API_URL}api/marchants/${currentMerchantId}/menus`);
    const data = await response.json();
    console.log(data);
    setMenus(data.menus);
    setSettings(data.informations);
  };

  const deleteMenu = async (menuId) => {
    try {
      await fetch(`${API_URL}api/menus/${menuId}`, {
        method: "DELETE",
      });
      setMenus(menus.filter((menu) => menu._id !== menuId));
    } catch (error) {
      console.error("Error deleting menu:", error);
    }
  };

  const moveMenu = async (menuId, direction) => {
    try {
      const response = await fetch(
        `${API_URL}api/menus/${menuId}/${direction}`,
        {
          method: "PUT",
        }
      );
      const { upadatedMenu, menuMovedUpdate } = await response.json();
      const updatedMenus = menus.map((menu) => {
        if (menu._id === menuMovedUpdate._id) {
          return { ...menuMovedUpdate, categories: menu.categories };
        }
        if (menu._id === menuId) {
          return { ...upadatedMenu, categories: menu.categories };
        }
        return menu;
      });
      setMenus(updatedMenus);
    } catch (error) {
      console.error(`Error moving menu ${direction}:`, error);
    }
  };

  const addMenu = async (name, description, icon, closeModal) => {
    if (!name || !currentMerchantId) {
      return;
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description: description ?? "",
        icon: icon ?? "fork",
        marchantId: currentMerchantId,
      }),
    };
    const response = await fetch(`${API_URL}api/menus`, options);
    const data = await response.json();
    console.log("[addMenu] response:", data);
    if (!data) {
      return;
    }
    if (!data.needToPay) {
      setMenus([...menus, data]);
      if (closeModal) {
        closeModal();
      }
    } else {
      window.location.replace("/user/dashboard?upgrade=true");
    }
  };

  const updateMenu = async (
    id,
    name,
    description,
    icon,
    closeModal,
    closeModalAction
  ) => {
    if (!name) {
      return;
    }
    try {
      console.log("try {");
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          description: description ?? "",
          icon: icon ?? "",
        }),
      };
      const response = await fetch(`${API_URL}api/menus/${id}`, options);
      const data = await response.json();
      console.log("[updateMenu] response:", data);
      const updatedMenus = menus.map((menu) => {
        if (menu._id === id) {
          return {
            ...menu,
            name: data.name,
            description: data.description,
            icon: data.icon,
          };
        }
        return menu;
      });

      setMenus(updatedMenus);
      if (closeModal && closeModalAction) {
        closeModalAction(data.icon);
        closeModal();
        return;
      }
    } catch (error) {
      console.error("Error updating menu:", error);
    }
    if (closeModal) {
      closeModal();
    }
  };

  const importMenu = async (code, closeModal) => {
    if (!code) {
      return;
    }
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code,
          marchantId: currentMerchantId,
        }),
      };
      const response = await fetch(`${API_URL}api/marchants/import`, options);
      const data = await response.json();
      if (!data) {
        return;
      }
      if (data.needToPay) {
        window.location.replace("/user/billing?backPage=/user/dashboard");
        return;
      } else {
        setMenus(data.menus);
        setSettings(data.informations);
      }
      if (closeModal) {
        console.log("closeModal");
        closeModal();
      }
    } catch (error) {
      console.error("Error importing menu:", error);
    }
  };

  React.useEffect(() => {
    console.log("[MenusContext]fetchMenus", currentMerchantId);
    fetchMenus();
  }, [currentMerchantId]);

  return (
    <MenusContext.Provider
      value={{
        menus,
        deleteMenu,
        moveMenu,
        addMenu,
        updateMenu,
        importMenu,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};
