import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import ActivatePersonalization from "../../Preview/ActivatePersonalization";

function PersonalizeInformationModule() {
  const { t } = useTranslation("global");
  const {
    informationText,
    setInformationText,
    informationsLinesColor,
    setInformationsLinesColor,
    informationCopiedTextColor,
    setInformationCopiedTextColor,
    informationSubtitleText,
    setInformationSubtitleText,
    reinitializeSpecificFieldFromTheme,
  } = React.useContext(PersonalizeContext);
  return (
    <ActivatePersonalization>
      <DoliNotMainSidebarDetailContainer
        title={t("sidebarPersonnalizeInformationsModuletDetailsTitle")}
      >
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeInformationText")}
          setter={setInformationText}
          value={informationText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("informationText")
          }
        />
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeInformationTextSubtitle")}
          setter={setInformationSubtitleText}
          value={informationSubtitleText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("informationSubtitleText")
          }
        />
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeInformationsLinesColors")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("informationsLinesColor")
          }
        >
          <ColorPickerPop
            setColor={setInformationsLinesColor}
            color={informationsLinesColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeInformationsCopiedTextColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("informationCopiedTextColor")
          }
        >
          <ColorPickerPop
            setColor={setInformationCopiedTextColor}
            color={informationCopiedTextColor}
          />
        </AddItemInputContainer>
      </DoliNotMainSidebarDetailContainer>
    </ActivatePersonalization>
  );
}

export default PersonalizeInformationModule;
