import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { handlePersonalizeSliderChange } from "../Personalize";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import DoliSlider from "../../common/DoliSlider";
import ActivatePersonalization from "../../Preview/ActivatePersonalization";

function PersonalizeText() {
  const { t } = useTranslation("global");
  const {
    titlesFontSize,
    setTitlesFontSize,
    textsColor,
    setTextsColor,
    setSecondaryTextsColor,
    secondaryTextsColor,
    textsFontSize,
    setTextsFontSize,
    categoriesFontSize,
    setCategoriesFontSize,
    importantTextsFontSize,
    setImportantTextsFontSize,
    reinitializeSpecificFieldFromTheme,
  } = React.useContext(PersonalizeContext);
  return (
    <ActivatePersonalization>
      <DoliNotMainSidebarDetailContainer
        title={t("sidebarPersonnalizeTextDetailsTitle")}
      >
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeTextsColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("textsColor")
          }
        >
          <ColorPickerPop setColor={setTextsColor} color={textsColor} />
        </AddItemInputContainer>

        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeSecondaryTextsColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("secondaryTextsColor")
          }
        >
          <ColorPickerPop
            setColor={setSecondaryTextsColor}
            color={secondaryTextsColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          widthName="100%"
          name={
            <PersonalizeName
              name={t("personalizeTitlesFontSize")}
              value={titlesFontSize}
            />
          }
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("titlesFontSize")
          }
        >
          <DoliSlider
            value={titlesFontSize}
            onChange={(e, value) =>
              handlePersonalizeSliderChange(e, value, setTitlesFontSize)
            }
            max={100}
          />
        </AddItemInputContainer>

        <AddItemInputContainer
          titleFontSize={15}
          widthName="100%"
          name={
            <PersonalizeName
              name={t("personalizeTextsFontSize")}
              value={textsFontSize}
            />
          }
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("textsFontSize")
          }
        >
          <DoliSlider
            value={textsFontSize}
            onChange={(e, value) =>
              handlePersonalizeSliderChange(e, value, setTextsFontSize)
            }
            max={100}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          widthName="100%"
          name={
            <PersonalizeName
              name={t("personalizeImportantTextFontSize")}
              value={importantTextsFontSize}
            />
          }
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("importantTextsFontSize")
          }
        >
          <DoliSlider
            value={importantTextsFontSize}
            onChange={(e, value) =>
              handlePersonalizeSliderChange(e, value, setImportantTextsFontSize)
            }
            max={100}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          widthName="100%"
          name={
            <PersonalizeName
              name={t("personalizeCategoriesFontSize")}
              value={categoriesFontSize}
            />
          }
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("categoriesFontSize")
          }
        >
          <DoliSlider
            value={categoriesFontSize}
            onChange={(e, value) =>
              handlePersonalizeSliderChange(e, value, setCategoriesFontSize)
            }
            max={100}
          />
        </AddItemInputContainer>
      </DoliNotMainSidebarDetailContainer>
    </ActivatePersonalization>
  );
}

export function PersonalizeName({ name, value, unit = "px" }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div>{name}</div>
      <div>
        {value}
        {unit && unit}
      </div>
    </div>
  );
}

export default PersonalizeText;
