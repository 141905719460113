// Define the date ranges for each period
export const getDateRanges = () => {
  const now = new Date();
  const sixDaysAgo = new Date();
  sixDaysAgo.setDate(now.getDate() - 6);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 27);

  const oneYearAgo = new Date();
  oneYearAgo.setDate(now.getDate() - 364);

  return {
    lastWeek: [sixDaysAgo, now],
    lastMonth: [thirtyDaysAgo, now],
    lastYear: [oneYearAgo, now],
  };
};

export const processData = (
  visits, // Liste totale des visites
  dateRange, //Retour de la fonction au dessus
  groupByWeek = false,
  groupByMonth = false
) => {
  // startDate = date la plus lointaine
  // endDate = aujourd'hui
  const [startDate, endDate] = dateRange;
  let filledVisits = fillMissingDates(visits, startDate, endDate);
  if (groupByWeek) {
    const response = groupVisitsByWeek(filledVisits);
    const labels = response.labels.map((label) => formatDateString(label));
    return { labels, data: response.data };
  }
  if (groupByMonth) {
    return groupVisitsByMonth(filledVisits);
  }
  const labels = filledVisits.map((visit) => formatDateString(visit.dayDate));
  const data = filledVisits.map((visit) => visit.visits);

  return { labels, data };
};

const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

// Helper function to fill missing dates with zero visits
const fillMissingDates = (visits, startDate, endDate) => {
  const dateRange = generateDateRange(startDate, endDate);
  const visitMap = {};

  visits.forEach((visit) => {
    visitMap[visit.dayDate] = visit.visits;
  });

  return dateRange.map((date) => {
    const dateString = date.toISOString().split("T")[0];
    return {
      dayDate: dateString,
      visits: visitMap[dateString] || 0,
    };
  });
};

// Helper function to group visits by week
const groupByWeekF = (visits) => {
  const weeks = {};
  visits.forEach((visit) => {
    const date = new Date(visit.dayDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const week = Math.floor(date.getDate() / 7);

    const key = `${year}-${month}-${week}`;
    if (!weeks[key]) {
      weeks[key] = 0;
    }
    weeks[key] += visit.visits;
  });

  const labels = Object.keys(weeks).map((key) => {
    const [year, month, week] = key.split("-");
    return `Week ${parseInt(week) + 1} of ${parseInt(month) + 1}/${year}`;
  });
  const data = Object.values(weeks);

  return { labels, data };
};

/*
TODO(chris): Remove if not used
With "Week + number" label
export const groupVisitsByWeek = (visits) => {
  const weeks = [];
  let week = [];
  let visitsCount = 0;
  visits.forEach((visit, index) => {
    week.push(visit.visits);
    visitsCount += visit.visits;
    if (week.length === 7) {
      weeks.push(visitsCount);
      week = [];
      visitsCount = 0;
    }
  });
  const labels = Array.from(
    { length: weeks.length },
    (_, i) => `Week ${i + 1}`
  );
  return { labels, data: weeks };
};
*/

export const groupVisitsByWeek = (visits) => {
  const weeks = [];
  let week = [];
  let visitsCount = 0;
  visits.forEach((visit, index) => {
    week.push(visit.visits);
    visitsCount += visit.visits;
    if (week.length === 7) {
      weeks.push({
        visits: visitsCount,
        startDate: visits[index - 6].dayDate,
        endDate: visit.dayDate,
      });
      week = [];
      visitsCount = 0;
    }
  });
  const labels = weeks.map((week, index) => {
    return `${week.startDate}`;
  });
  const data = weeks.map((week) => week.visits);
  return { labels, data };
};

export const groupVisitsByMonth = (visits) => {
  const months = {};

  visits.forEach((visit) => {
    const date = new Date(visit.dayDate);
    const month = date.getMonth();
    const year = date.getFullYear();
    const key = `${year}-${month}`;

    if (!months[key]) {
      months[key] = 0;
    }

    months[key] += visit.visits;
  });

  const labels = Object.keys(months).map((key) => {
    const [year, month] = key.split("-");
    const monthName = new Date(Date.UTC(year, month, 1)).toLocaleString(
      "en-us",
      { month: "long" }
    );
    return `${monthName}, ${year}`;
  });

  const data = Object.values(months);

  return { labels, data };
};

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("fr-FR", { month: "long" });
  return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
};
