import { Container } from "@mui/material";
import React from "react";
import OnboardingTemplate from "./OnboardingTemplate";
import { qrCodeSVGPreview } from "../QRCode/Content/QRCodeSVGPreview";
import { UserContext } from "../Context/UserContext";
import { useTranslation } from "react-i18next";

function OnboardingQR({ onPrimaryButtonClick }) {
  const { t } = useTranslation("global");
  const { currentMerchantId } = React.useContext(UserContext);
  const svgCode = qrCodeSVGPreview({
    QRBackgroundColor: "#ffffff",
    QRColor: "#000000",
    FrameBackgroundColor: "#000000",
    menuTextColor: "#ffffff",
    menuText: "MENU",
    fontSize: 60,
    currentMerchantId,
  });

  return (
    <OnboardingTemplate
      primaryButton={{
        text: "Terminer",
        onClick: () => onPrimaryButtonClick(),
      }}
      title={t("onboardingQRTitle")}
      subtitle={t("onboardingQRSubTitle")}
      divider={true}
      inputs={
        <>
          <Container
            sx={{
              padding: {
                xs: "14px 14px 0px 14px",
                sm: "24px 24px 0px 24px",
                md: "24px 34px 0px 34px",
              },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container
              dangerouslySetInnerHTML={{ __html: svgCode }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                margin: "0px",
              }}
              sx={{
                zoom: { xs: "80%", sm: "73%", md: "73%", lg: "80%" },
              }}
              alt="dark theme"
            />
          </Container>
        </>
      }
    />
  );
}

export default OnboardingQR;
