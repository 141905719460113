import * as React from "react";

export const useNotification = (delay = 100) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showNotification = React.useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true);
    }, delay);
  }, [delay]);

  return [isOpen, setIsOpen, showNotification];
};
