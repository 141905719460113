import { QRCodeSVG } from "qrcode.react";
import { renderToStaticMarkup } from "react-dom/server";
import { QR_CODE_URL } from "../../../consts";

export function qrCodeSVGPreview({
  QRBackgroundColor,
  QRColor,
  FrameBackgroundColor,
  menuTextColor,
  menuText,
  fontSize,
  currentMerchantId
}) {
  const qrCodeElement = QRCodeSVG({
    value: `${QR_CODE_URL}/go/${currentMerchantId}`,
    bgColor: QRBackgroundColor,
    fgColor: QRColor,
    level: "L",
    includeMargin: false,
    size: 265,
  });

  const qrCodeSvg = renderToStaticMarkup(qrCodeElement);

  const svgCode = `
    <svg xmlns="http://www.w3.org/2000/svg" width="351" height="415" style="font-family: Arial, sans-serif;">
      <rect width="100%" height="100%" fill="${FrameBackgroundColor}" rx="20" />
      
      <foreignObject x="0" y="0" width="351" height="415">
        <div xmlns="http://www.w3.org/1999/xhtml" 
          style="
            display: flex; 
            flex-direction: column; 
            align-items: center; 
            padding: 15px 15px 0px 15px;
            border-radius: 15px; 
            background-color: ${FrameBackgroundColor};
            height: calc(100% - 15px);
          "
        >
          <div 
            style="
              background-color: ${QRBackgroundColor}; 
              padding: 15px; 
              border-radius: 15px;
              flex: 1;
              margin-top: 15px;
            "
          >
            ${qrCodeSvg}
          </div>
          <div
          style="display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex: 1;
          "
          >
          <div 
            style="
              color: ${menuTextColor}; 
              font-size: ${fontSize}px;
              text-align: center; 
              font-weight: bold;
              margin-top: auto;
              margin-bottom: auto;
            "
          >
            ${menuText}
          </div>
          </div>
        </div>
      </foreignObject>
    </svg>
  `;

  return svgCode;
}
