import React from "react";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CloseIcon from "@mui/icons-material/Close";
import { MenusContext } from "../Context/MenusContext";
import { iconsList } from "../../iconsList";

function ChooseMenuIcon({
  closeModal,
  id,
  name,
  description,
  actualIcon,
  setIcon,
  saveFunction = false,
}) {
  const { updateMenu } = React.useContext(MenusContext);
  const [iconChoose, setIconChoose] = React.useState(actualIcon);

  const handleSave = () => {
    console.log("handleSave", iconChoose, actualIcon);
    if (iconChoose !== actualIcon) {
      console.log("iconChoose !== actualIcon");
      if (saveFunction) {
        setIcon(iconChoose);
        closeModal();
      } else {
        console.log("updateMenu");
        updateMenu(id, name, description, iconChoose, closeModal, setIcon);
      }
    } else {
      console.log("closeModal");
      closeModal();
    }
  };
  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Choisir une icône
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          {iconsList.map((icon) => (
            <div
              key={icon.name}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "24px",
                width: "24px",
                padding: 10,
                cursor: "pointer",
                borderRadius: 4,
                backgroundColor: "var(--default-background-color)",
                border:
                  iconChoose === icon.name
                    ? "2px solid var(--create-product-primary-button-color)"
                    : "",
              }}
              onClick={() => setIconChoose(icon.name)}
            >
              <div
                style={{
                  marginRight: "17px",
                  height: "100%",
                  width: "24px",
                  display: "flex",
                }}
              >
                {icon.icon}
              </div>
            </div>
          ))}
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={handleSave}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default ChooseMenuIcon;
