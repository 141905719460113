import React from "react";
import CheckIcon from "@mui/icons-material/Check";

function CheckList({ checkList, gap }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: gap ?? 0,
      }}
    >
      {checkList.map((check, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 6,
            alignItems: "center",
          }}
        >
          <div
            className="check-icon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckIcon id="check-icon" fontSize="small" />
          </div>
          <div
            style={{
              fontSize: 15,
            }}
          >
            {check}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CheckList;
