import { API_URL } from "../consts";
import { dataURLtoFile } from "./uploadFileService";

const createMerchant = async (name, userId, Type) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      name: name,
      userId: userId,
      Type: Type,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(`${API_URL}api/marchants`, requestOptions);
    console.log("[UserService][createMerchant] response", response);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Merchant created:", data);
    return data;
  } catch (error) {
    console.error("Error creating merchant:", error);
    throw error;
  }
};

const createInformation = async (merchantIdParams, informationDataParams) => {
  const formData = new FormData();
  formData.append("marhantId", merchantIdParams);
  formData.append(
    "appearance",
    JSON.stringify({
      showRestaurantName: informationDataParams.showRestaurantName ?? true,
      themeColor: informationDataParams.theme ?? "LIGHT_THEME",
    })
  );
  formData.append(
    "wifi",
    JSON.stringify({
      networkName: informationDataParams.wifi ?? "",
      password: informationDataParams.password ?? "",
    })
  );
  formData.append(
    "extraInformation",
    JSON.stringify({
      message: informationDataParams.message,
      position: informationDataParams.position ?? "ABOVE_THE_MENUS",
    })
  );
  formData.append(
    "socialNetworks",
    JSON.stringify({
      instagram: informationDataParams.instagram ?? "",
      facebook: informationDataParams.facebook ?? "",
      x: informationDataParams.x ?? "",
    })
  );
  formData.append(
    "basic",
    JSON.stringify({
      establishmentName: informationDataParams.establishmentName ?? "",
      publicMenuURL: informationDataParams.publicMenuURL ?? "",
      address: informationDataParams.address ?? "",
      phoneNumber: informationDataParams.phoneNumber ?? "",
    })
  );
  if (informationDataParams.customImage) {
    const fileToUpload = dataURLtoFile(
      informationDataParams.customImage.image,
      informationDataParams.customImage.name
    );
    formData.append("customImage", fileToUpload);
  } else if (informationDataParams.image) {
    formData.append("imageValue", informationDataParams.image.name);
  }

  try {
    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${API_URL}api/marchants/${merchantIdParams}/informations`,
      options
    );
    console.log("[UserService][createInformation] response", response);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    console.log("[merchantService][createInformation]", result);

    return result;
  } catch (error) {
    console.error("Error creating information:", error);
    throw error;
  }
};

function addCommaToPrice(number) {
  let numStr = number.toString();
  if (numStr.length < 3) {
      numStr = numStr.padStart(3, '0');
  }
  let formattedNumber = numStr.slice(0, -2) + ',' + numStr.slice(-2);
  return formattedNumber;
}

export { createMerchant, createInformation, addCommaToPrice };
