import * as React from "react";
import { Container, Menu } from "@mui/material";
import { HexColorPicker } from "react-colorful";
import BasicTextFields from "./TextField";
import AddItemInputContainer from "./AddItemInputContainer";

export default function ColorPickerPop({ setColor, color }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <AddItemInputContainer
        style={{ padding: 0 }}
        autoComplete={false}
        onClickTextField={handleClick}
        value={color?.toUpperCase()}
        setter={setColor}
        inputValueProps={color?.toUpperCase()}
        placeholder={color?.toUpperCase()}
        startAdornment={
          <div
            style={{
              height: "calc(100% - 8px)",
              width: "22px",
              backgroundColor: color,
              marginRight: 10,
              marginLeft: -10,
              border: "1px solid var(--border-color)",
              borderRadius: 5,
            }}
          />
        }
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        sx={{
          ".MuiMenu-paper": {
            height: 220,
          },
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            gap: 10,
          }}
        >
          <Container
            sx={{
              margin: 0,
              padding: "0px !important",
              ".react-colorful__hue": {
                height: 10,
                marginTop: "10px",
                marginRight: "24px",
                marginLeft: "24px",
                borderRadius: 100,
              },
              ".css-1cuz9v6-MuiContainer-root .react-colorful__hue": {
                margin: "10px !important",
              },
              ".react-colorful__pointer": {
                height: 20,
                width: 20,
              },
            }}
          >
            <HexColorPicker
              style={{
                height: 150,
                width: 200,
                marginTop: -8,
              }}
              color={color}
              onChange={(e) => {
                console.log(e);
                setColor(e);
              }}
            />
          </Container>

          <Container
            sx={{
              marginTop: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  height: 32,
                  width: 32,
                  backgroundColor: color,
                  borderRadius: 100,
                  marginRight: 10,
                }}
              />
              <div
                style={{
                  width: 100,
                }}
              >
                <BasicTextFields
                  type={"text"}
                  setter={setColor}
                  value={color}
                  borderRadiusProps={100}
                  inputValueProps={color}
                />
              </div>
            </div>
          </Container>
        </div>
      </Menu>
    </div>
  );
}
