import { Container } from "@mui/material";
import * as React from "react";

export default function CategoryHeader({ title, description, basicsSx }) {
  return (
    <Container
      sx={{
        ...basicsSx,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px 0px 15px 0px",
        padding: "0px 16px",
        flex: 2,
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          gap: "16px",
        }}
      >
        <h3
          style={{
            marginBottom: 0,
            marginTop: 0,
            fontSize: "var(--category-text-size)",
          }}
        >
          {title}
        </h3>
        <div
          style={{
            marginTop: 8,
            color: "var(--subtitles-color)",
            fontWeight: "400",
          }}
        >
          {description}
        </div>
      </div>
    </Container>
  );
}
