import React from "react";
import Container from "@mui/material/Container";
import BlankCard from "../common/BlankCard";
import AddItemInputContainer from "../common/AddItemInputContainer";
import PersonIcon from "@mui/icons-material/Person";
import CustomListButton from "../common/CustomListButton";
import ProductListItemContainer from "../common/ProductListItemContainer";
import { AuthContext } from "../Context/AuthContext";
import DeleteAccountModal from "../DeleteAccount/DeleteAccountModal";
import SuccessModal from "../common/SuccessModal";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

function Profile() {
  const {
    extractFirebaseProfileInformations,
    changeUserPassword,
    firebaseUser,
  } = React.useContext(AuthContext);
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("profilePageTitle")}`);
  const { deleteAccount } = React.useContext(AuthContext);
  const [firstName, setFirstName] = React.useState(
    extractFirebaseProfileInformations()?.firstName
  );
  const [lastName, setLastName] = React.useState(
    extractFirebaseProfileInformations()?.lastName
  );
  const [newPassword, setNewPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [profilePicture, setProfilePicture] = React.useState(
    extractFirebaseProfileInformations()?.photoURL
  );
  const [deleteAccountModal, setDeleteAccountModal] = React.useState(false);
  const [changePasswordMessage, setChangePasswordMessage] = React.useState({
    message: "",
  });

  const handleDeleteAccount = () => {
    setDeleteAccountModal(true);
  };

  const handleChangePassword = async () => {
    if (newPassword === "" || oldPassword === "") {
      setChangePasswordMessage({
        success: false,
        message: "Veuillez remplir tous les champs",
      });
      return;
    }
    if (newPassword.length < 6) {
      setChangePasswordMessage({
        success: false,
        message: "Le mot de passe doit contenir au moins 6 caractères",
      });
      return;
    }
    const response = await changeUserPassword(oldPassword, newPassword);
    if (response.success) {
      setChangePasswordMessage({
        success: true,
        message: "Le mot de passe a été modifié avec succès",
      });
      setNewPassword("");
      setOldPassword("");
    } else {
      setChangePasswordMessage({
        success: false,
        message: "Le mot de passe actuel est incorrect",
      });
    }
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DoliTopPageTitle title={t("profilePageTitle")} addPadding={false} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <BlankCard
              maxWidth={true}
              cardTitle={t("profilePageAccountInformation")}
              /* TODO(chris): Next Release if logic is added
              primaryBottomButton={{
                name: "Sauegarder",
                display: true,
                onclick: () => console.log("Modifier"),
              }}
            */
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  marginBottom: 30, // TODO(chris): Next Release: remove if there is save button
                }}
              >
                <AddItemInputContainer
                  removeHorizontalSpace={true}
                  style={{ alignItems: "flex-start" }}
                  name={t("textProfilePicture")}
                  notTextField={true}
                  titleFontSize={15}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 70,
                        width: 70,
                        borderRadius: 100,
                        backgroundColor: "var(--secondary-text-color)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {profilePicture ? (
                        <div
                          alt="profile"
                          style={{
                            height: 70,
                            width: 70,
                            borderRadius: 100,
                            background: `url(${profilePicture}`,
                            backgroundSize: 70,
                          }}
                        />
                      ) : (
                        <PersonIcon fontSize={"large"} />
                      )}
                    </div>
                    {/* TODO(chris): Next release add logic
                  <CustomListButton
                    text={"Mettre une photo"}
                    primary={false}
                    styles={{ height: 40 }}
                  />
                  */}
                  </div>
                </AddItemInputContainer>
                <AddItemInputContainer
                  removeHorizontalSpace={true}
                  setter={setFirstName}
                  value={firstName}
                  name={t("textFirstname")}
                  disabled={true}
                  titleFontSize={15}
                />
                <AddItemInputContainer
                  removeHorizontalSpace={true}
                  setter={setLastName}
                  value={lastName}
                  name={t("textLastName")}
                  disabled={true}
                  titleFontSize={15}
                />
              </div>
            </BlankCard>

            {firebaseUser.providerData[0].providerId == "password" && (
              <BlankCard
                maxWidth={true}
                cardTitle={t("profilePageSecurity")}
                primaryBottomButton={{
                  name: t("textSave"),
                  display: true,
                  onclick: () => handleChangePassword(),
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  <AddItemInputContainer
                    removeHorizontalSpace={true}
                    setter={setOldPassword}
                    value={oldPassword}
                    name={t("textActualPassword")}
                    type={"password"}
                    titleFontSize={15}
                  />
                  <AddItemInputContainer
                    removeHorizontalSpace={true}
                    setter={setNewPassword}
                    value={newPassword}
                    name={t("textNewPassword")}
                    type={"password"}
                    placeholder={t("textPasswordPlaceholder")}
                    titleFontSize={15}
                  />
                </div>
              </BlankCard>
            )}

            <ProductListItemContainer
              style={{
                border: "none",
                padding: 0,
                maxWidth: 600,
                backgroundColor: "transparent",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  padding: 20,
                }}
              >
                <div>
                  <h2 style={{ margin: 0, fontSize: 20 }}>
                    {t("profilePageDeleteAccount")}
                  </h2>
                  <p style={{ fontSize: 15 }}>
                    {t("profilePageDeleteAccountMessage")}
                  </p>
                </div>
                <div
                  style={{
                    height: 20,
                  }}
                >
                  <CustomListButton
                    text={t("profilePageDeleteAccount")}
                    onClick={handleDeleteAccount}
                    big={true}
                    primary={false}
                    type={"submit"}
                    alignButtonLeft={false}
                    styles={{ height: 40 }}
                  />
                </div>
              </div>
            </ProductListItemContainer>
          </div>
        </div>
      </Container>
      {deleteAccountModal && (
        <DeleteAccountModal
          open={deleteAccountModal}
          handleClose={() => setDeleteAccountModal(false)}
          deleteAccount={deleteAccount}
        />
      )}

      {changePasswordMessage.message !== "" && (
        <SuccessModal
          open={changePasswordMessage.message !== ""}
          title={
            changePasswordMessage.success === true
              ? t("textSuccess")
              : t("textError")
          }
          successMessage={changePasswordMessage.message}
          handleClose={() => {
            setChangePasswordMessage({ message: "" });
          }}
        />
      )}
    </>
  );
}

export default Profile;