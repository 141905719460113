import React from "react";
import Container from "@mui/material/Container";
import ProductCard from "../common/ProductCard";
import { MenusContext } from "../Context/MenusContext";
import EditMenuModal from "../EditMenu/EditMenuModal";
import { useLocation, useNavigate } from "react-router-dom";
import NoMenuCard from "./NoMenuCard";
import { useTitle } from "../../App";
import { useSearchParams } from "react-router-dom";
import BillingSuccess from "../Billing/BillingSuccess";
import SubscriptionLimit from "../SubscriptionLimit/SubscriptionLimit";
import CustomListButton from "../common/CustomListButton";
import ImportMenuModal from "../ImportMenu/ImportMenuModal";
import { UserContext } from "../Context/UserContext";
import { useTranslation } from "react-i18next";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditPromotionalTextModal from "../EditPromotionalText/EditPromotionalTextModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DoliNavigationContext,
  drawerBasicSecondaryListActions,
} from "../Context/DoliNavigationContext";
import CreateMenuModal from "../CreateMenu/CreateMenuModal";
import DoliHeaderCard from "../common/DoliHeaderCard";
import AccessibleButton from "../common/AccessibleButton";
import { QR_CODE_URL } from "../../consts";
import { FullPageSxStyle } from "../common/FullPageStyle";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

function MenusList() {
  useTitle("DoliQR - Menus");
  const { t } = useTranslation("global");
  const { menus, deleteMenu, moveMenu } = React.useContext(MenusContext);
  const { currentMerchantSubscribed, currentMerchantId } =
    React.useContext(UserContext);
  const { drawerAction, reinitializeDrawerAction } = React.useContext(
    DoliNavigationContext
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const success = searchParams.get("success");
  const upgrade = searchParams.get("upgrade");

  const handleMenuClick = (menuId) => {
    navigate(`/user/menus/${menuId}`);
    console.log("click");
  };
  const [openEditMenu, setOpenEditMenu] = React.useState(false);
  const [showSubscriptionLimitModal, setShowSubscriptionLimitModal] =
    React.useState(upgrade);
  const [showImportMenuModal, setShowImportMenuModal] = React.useState(false);
  const [menuToEdit, setMenuToEdit] = React.useState(null);
  const [openBillingSuccess, setOpenBillingSuccess] = React.useState(success);
  const [openEditPromotionalTextModal, setOpenEditPromotionalTextModal] =
    React.useState(false);
  const [showCreateMenu, setShowCreateMenu] = React.useState(false);

  const handleDrawerAction = () => {
    switch (drawerAction) {
      case drawerBasicSecondaryListActions.EDIT_PROMOTIONAL_TEXT:
        setOpenEditPromotionalTextModal(true);
        reinitializeDrawerAction();
        break;
      case drawerBasicSecondaryListActions.IMPORT_MENU:
        setShowImportMenuModal(true);
        reinitializeDrawerAction();
        break;
      case drawerBasicSecondaryListActions.CREATE_MENU:
        setShowCreateMenu(true);
        reinitializeDrawerAction();
        break;
    }
  };

  React.useEffect(() => {
    console.log("[MenusList]drawerAction", drawerAction);
    if (drawerAction) {
      // Vérifier si drawerAction n'est pas null
      handleDrawerAction();
    }
  }, [drawerAction, handleDrawerAction]);

  const handleCloseBillingSuccess = () => {
    setOpenBillingSuccess(false);
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("success");
    navigate({ search: queryParams.toString() });
  };

  const handleOpenEditMenu = (product) => {
    setMenuToEdit(product);
    setOpenEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    setMenuToEdit(null);
    setOpenEditMenu(false);
  };

  const menuSettings = (articleId, index) => {
    const isFirst = index === 0;
    const isLast = index === menus.length - 1;
    if (currentMerchantSubscribed || index < 1) {
      return [
        {
          name: "Editer",
          action: () => handleOpenEditMenu(menus[index]),
        },
        {
          name: "Supprimer",
          action: () => deleteMenu(articleId),
        },
        {
          name: "Déplacer en Haut",
          action: () => moveMenu(articleId, "moveUp"),
          hidden: isFirst,
        },
        {
          name: "Déplacer en Bas",
          action: () => moveMenu(articleId, "moveDown"),
          hidden: isLast,
        },
      ];
    } else {
      return [
        {
          name: t("subscriptionLimitModalButton"),
          action: () => navigate("/user/billing?backPage=/user/dashboard"),
        },
      ];
    }
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: { xs: "0px !important", sm: "10px !important" },
          ...FullPageSxStyle,
        }}
      >
        <DoliHeaderCard>
          <Container
            sx={{
              margin: "0px !important",
              padding: "0px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "var(--white-text-color)",
              fontSize: { xs: "1.5rem", sm: "2rem" },
              ...FullPageSxStyle,
            }}
          >
            <div style={{ fontWeight: "600" }}>DoliQR</div>
            <div style={{ fontWeight: "500", fontSize: 20 }}>
              Donnez à votre restaurant l'identité qu'il mérite.
            </div>

            <div
              style={{
                margin: "10px 0px 0px 0px",
                width: "100%",
                minWidth: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <CustomListButton
                backgroundColorProps={"var(--primary-purple-color-transparent)"}
                borderColorProps={"var(--primary-border-color-f-transparent)"}
                backgroundColorHoverProps={"var(--purple-color)"}
                iconBefore={
                  <div
                    style={{
                      marginRight: "auto",
                      display: "flex",
                      paddingRight: 10,
                    }}
                  >
                    <FormatPaintIcon fontSize={"small"} />
                  </div>
                }
                styles={{
                  fontSize: 15,
                  height: "38px",
                }}
                onClick={() => navigate("/user/personalize?type=style")}
                text={
                  <div
                    style={{
                      marginRight: "auto",
                    }}
                  >
                    Personnaliser
                  </div>
                }
                primary={true}
              />
              <CustomListButton
                styles={{
                  fontSize: 15,
                  height: "38px",
                  border: "none",
                }}
                iconBefore={
                  <div
                    style={{
                      marginRight: "auto",
                      display: "flex",
                      paddingRight: 10,
                    }}
                  >
                    <EditNoteIcon fontSize={"small"} />
                  </div>
                }
                onClick={() => setOpenEditPromotionalTextModal(true)}
                text={
                  <div
                    style={{
                      marginRight: "auto",
                    }}
                  >
                    {t("promotionalModalButton")}
                  </div>
                }
                primary={false}
              />
            </div>
          </Container>
        </DoliHeaderCard>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "15px 0px 0px 0px !important",
            padding: "0px 0px 10px 0px !important",
            alignItems: "flex-start",
            justifyContent: { xs: "flex-start", sm: "center" },
            gap: "15px",
            ...FullPageSxStyle,
          }}
        >
          <AccessibleButton
            onClick={() => setShowCreateMenu(true)}
            text={"Nouveau Menu"}
            color={"rgb(21, 153, 172)"}
            icon={<AddCircleIcon />}
          />
          <AccessibleButton
            onClick={() => navigate("/user/personalize?type=style")}
            text={"Personnaliser"}
            color={"rgb(18, 158, 65)"}
            icon={<FormatPaintIcon />}
          />
          <AccessibleButton
            onClick={() => setOpenEditPromotionalTextModal(true)}
            text={"Texte promotionnel"}
            color={"rgb(255, 86, 8)"}
            icon={<EditNoteIcon />}
          />
          <AccessibleButton
            onClick={() => setShowImportMenuModal(true)}
            text={"Importer un Menu"}
            color={"rgb(255, 51, 66)"}
            icon={<FileDownloadIcon />}
          />
          <AccessibleButton
            onClick={() =>
              window
                .open(`${QR_CODE_URL}/go/${currentMerchantId}`, "_blank")
                .focus()
            }
            text={"Aperçu"}
            color={"rgb(229, 71, 26)"}
            icon={<VisibilityIcon />}
          />
        </Container>
        <DoliTopPageTitle title="Liste de vos menus" />
        {menus &&
          menus
            .sort((a, b) => a.order - b.order)
            .map((menu, menuIndex) => (
              <div key={menu._id} style={{ marginBottom: 15 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  <ProductCard
                    key={menu._id}
                    propsIcon={menu.icon}
                    name={menu.name}
                    description={menu.description}
                    first={true}
                    settings={menuSettings(menu._id, menuIndex)}
                    alone={true}
                    backgroundColor={
                      currentMerchantSubscribed || menuIndex < 1
                        ? "var(--product-card-background-color)"
                        : ""
                    }
                    onClick={() => {
                      if (currentMerchantSubscribed || menuIndex < 1) {
                        handleMenuClick(menu._id);
                      } else {
                        setShowSubscriptionLimitModal(true);
                      }
                    }}
                  />
                </div>
              </div>
            ))}
        {menus &&
          menus > 0 &&
          menus
            .sort((a, b) => a.order - b.order)
            .map((menu, menuIndex) => (
              <div key={menu._id} style={{ marginBottom: 15 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  <ProductCard
                    key={menu._id}
                    name={menu.name}
                    description={menu.description}
                    first={true}
                    settings={menuSettings(menu._id, menuIndex)}
                    alone={true}
                    onClick={() => handleMenuClick(menu._id)}
                  />
                </div>
              </div>
            ))}
        {(!menus || menus.length) < 1 && <NoMenuCard />}
      </Container>
      {showImportMenuModal && (
        <ImportMenuModal
          open={showImportMenuModal}
          handleClose={() => setShowImportMenuModal(false)}
        />
      )}
      {showSubscriptionLimitModal && (
        <SubscriptionLimit
          open={showSubscriptionLimitModal}
          closeModal={() => {
            setShowSubscriptionLimitModal(false);
            navigate("/user/dashboard");
          }}
        />
      )}
      {openBillingSuccess && (
        <BillingSuccess
          open={openBillingSuccess}
          closeModal={() => handleCloseBillingSuccess()}
        />
      )}
      {openEditMenu && (
        <EditMenuModal
          open={openEditMenu}
          handleClose={handleCloseEditMenu}
          id={menuToEdit._id}
          name={menuToEdit.name}
          description={menuToEdit.description}
          icon={menuToEdit.icon ? menuToEdit.icon : "fork"}
        />
      )}
      {openEditPromotionalTextModal && (
        <EditPromotionalTextModal
          open={openEditPromotionalTextModal}
          handleClose={() => setOpenEditPromotionalTextModal(false)}
        />
      )}
      {showCreateMenu && (
        <CreateMenuModal
          open={showCreateMenu}
          handleClose={() => setShowCreateMenu(false)}
        />
      )}
    </>
  );
}

export default MenusList;
