import { saveAs } from "file-saver";

export const downloadPNG = async (svgRef) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const image = new Image();
  const svgElement = svgRef.current;
  let data = new XMLSerializer().serializeToString(svgElement);
  data = data.substring(data.indexOf("\n") + 1);
  data = data.substring(data.lastIndexOf("\n") + 1, -1);
  image.onload = () => {
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0);
    canvas.toBlob((blob) => {
      saveAs(blob, "qr-code.png");
    });
  };
  image.src = `data:image/svg+xml;base64,${btoa(data)}`;
};

export const generatePNGDataURL = (svgRef) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = new Image();
    const svgElement = svgRef.current;
    let data = new XMLSerializer().serializeToString(svgElement);
    data = data.substring(data.indexOf("\n") + 1);
    data = data.substring(data.lastIndexOf("\n") + 1, -1);
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      const pngDataURL = canvas.toDataURL("image/png");
      resolve(pngDataURL);
    };
    image.src = `data:image/svg+xml;base64,${btoa(data)}`;
  });
};