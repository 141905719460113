import * as React from "react";
import CustomModal from "../Modal/Modal";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";

export default function SuccessModal({
  title = "Succès",
  successMessage = "Les informations ont été mis à jour.",
  open,
  handleClose,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          {title}
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
      </Container>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{
            fontWeight: "400",
            fontSize: 20,
          }}
        >
          {successMessage}
        </div>
      </Container>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          padding: 24,
          backgroundColor: "var(--default-background-color)",
        }}
      ></div>
    </CustomModal>
  );
}
