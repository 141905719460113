import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesListV2 } from "../../consts";

function BottomNavigationV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const page = pagesListV2.find((page) => {
      if (page.link) {
        return page.link === location.pathname;
      }
    });
    if (page) {
      console.log(page.id);
      setValue(page.id);
    }
  }, [location.pathname]);

  return (
    <Container
      sx={{
        display: { xs: "flex", sm: "none", md: "none" },
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100% !important",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        height: "calc(var(--mobile-bottom-navigation-height) + 1px)",
        backgroundColor: "var(--white-color)",
      }}
    >
      <Container
        sx={{
          display: { xs: "flex", sm: "none", md: "none" },
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100% !important",
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
          height: "var(--mobile-bottom-navigation-height)",
          backgroundColor: "var(--white-color)",
          boxShadow:
            "0px 0px 0px 1px rgba(64,87,109,.04),0px 6px 20px -4px rgba(64,87,109,.3)",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              console.log(newValue);
              setValue(newValue);
            }}
            sx={{
              padding: "12px 0px !important",
              ".Mui-selected": {
                borderBottom: "none",
                fontSize: "0.75rem !important",
              },
              ".Mui-selected > .MuiTouchRipple-root": {
                borderBottom: "none",
                width: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                padding: 0,
              },
              justifyContent: "space-around",
            }}
          >
            {pagesListV2.map((item, index) => (
              <BottomNavigationAction
                key={index}
                label={item.name}
                icon={
                  <div
                    style={{
                      backgroundColor:
                        index === value ? "var(--purple-color)" : "",
                      borderRadius: "5px",
                      padding: "2px",
                      width: "26px",
                      height: "26px",
                    }}
                  >
                    {item.icon}
                  </div>
                }
                onClick={() => navigate(item.link)}
                sx={{
                  color: "var(--purple-color) !important",
                  gap: "2px !important",
                  fontWeight: "500",
                  ".MuiButtonBase-root": {
                    backgroundColor: "transparent !important",
                    gap: "10px !important",
                    padding: "10px !important",
                  },
                  ".MuiButtonBase-root-MuiBottomNavigationAction-root": {},
                  ".MuiBottomNavigationAction-root": {
                    padding: "10px !important",
                  },
                  "&& .MuiTouchRipple-rippleVisible": {
                    backgroundColor: "transparent",
                    color: "transparent",
                  },
                  ".Mui-selected": {
                    border: "none",
                    backgroundColor: "transparent !important",
                    color: "var(--purple-color) !important",
                  },
                  ".MuiBottomNavigationAction-iconOnly": {},
                  ".MuiBottomNavigationAction-label": {
                    color:
                      index === value ? "var(--purple-color) !important" : "",
                  },
                  ".MuiSvgIcon-root": {
                    width:
                      index === value ? "15px !important" : "24px !important",
                    height:
                      index === value ? "20px !important" : "24px !important",
                  },
                  svg: {
                    width: index === value ? "15px " : "24px !important",
                    height: index === value ? "20px" : "24px !important",
                    color:
                      index === value
                        ? "var(--icon-selected-color) !important"
                        : "",
                  },
                }}
              />
            ))}
          </BottomNavigation>
        </Box>
      </Container>
    </Container>
  );
}
export default BottomNavigationV2;
