import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import global_en from "./locales/en/global.json";
import global_fr from "./locales/fr/global.json";

const locale = navigator.language;
const resourcesFiles = {
  en: {
    global: global_en,
  },
  fr: {
    global: global_fr,
  },
};
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "fr"],
    interpolation: { escapeValue: false },
    fallbackLng: { 
      "fr": ["fr-FR"],
      "fr": ["fr-CA"],
      "default": ["en"]
  },
    lng: locale,
    resources: resourcesFiles,
  });

export default i18n;
