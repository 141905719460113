import * as React from "react";
import ProductListItemContainer from "../common/ProductListItemContainer";
import Container from "@mui/material/Container";

export default function MemberCard({
  firstElement,
  secondElement,
  isMemberCard,
  first,
  last,
  alone = false,
  sx,
  onClick,
}) {
  const sxMemberCard = isMemberCard
    ? {
        display: { xs: "flex", md: "" }, // removeit for no member
        margin: { xs: "0px 0px 0px auto", md: "0px 24px !important" }, // removeit for no member
        justifyContent: { xs: "flex-end", md: "flex-start" }, // removeit for no member
        width: { xs: "calc(100% - 50px ) !important", md: "" },
        padding: {
          xs: "0px 0px 0px 0px!important",
          md: "0px !important",
        },
      }
    : {
        width: { xs: "calc(100% - 50px ) !important", md: "" },
        padding: {
          xs: "0px 10px 0px 0px !important",
          md: "0px !important",
        },
      };

  return (
    <ProductListItemContainer
      sx={sx}
      style={{
        borderTopRightRadius: first || alone ? 10 : 0,
        borderTopLeftRadius: first || alone ? 10 : 0,
        borderBottomLeftRadius: last || alone ? 10 : 0,
        borderBottomRightRadius: last || alone ? 10 : 0,
        borderTop: first ? "1px solid var(--border-color)" : "",
        padding: 0,
      }}
    >
      <Container
        sx={{
          flexDirection: { xs: "column !important", md: "row !important" },
          paddingBottom: { xs: "20px !important", md: "0px !important" },
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          flex: 4,
        }}
      >
        <Container
          sx={{
            padding: { xs: "20px 0px !important", md: "15px 20px" },
          }}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflowWrap: "break-word",
            cursor: "pointer",
            height: "100%",
            flex: 2,
          }}
          onClick={onClick}
        >
          {firstElement}
        </Container>
        <Container
          sx={{
            marginLeft: { xs: "0px !important", md: "auto" },
            marginRight: { xs: "0px !important", md: "auto" },
            padding: {
              xs: "0px !important",
              md: "0px !important",
            },
            width: { xs: "100% !important", md: "" },
          }}
          style={{
            flex: 1,
            overflow: "hidden",
            textOverflow: "clip",
            whiteSpace: "nowrap",
            overflowWrap: "break-word",
          }}
        >
          <Container sx={sxMemberCard}>{secondElement}</Container>
        </Container>
      </Container>
    </ProductListItemContainer>
  );
}
