import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import CustomListButton from "../common/CustomListButton";
import AddItemInputContainer from "../common/AddItemInputContainer";
import TimeField from "../common/TimeField";
import Divider from "../common/Divider";
import { SettingsContext } from "../Context/SettingsContext";

const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

function EditOpeningHours({ closeModal }) {
  const { t } = useTranslation("global");
  const { settings, updateSettings } = useContext(SettingsContext);

  const [hours, setHours] = useState(
    DAYS.reduce(
      (acc, day) => ({
        ...acc,
        [day]: {
          open: settings?.basic?.dayHours?.[day]?.open || "10:00",
          close: settings?.basic?.dayHours?.[day]?.close || "22:00",
        },
      }),
      {}
    )
  );

  const handleHourChange = (day, type, value) => {
    setHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], [type]: value },
    }));
  };

  const daysList = DAYS.map((day) => ({
    day: t(`text${day.charAt(0).toUpperCase() + day.slice(1)}`),
    ...hours[day],
    setOpeningHour: (value) => handleHourChange(day, "open", value),
    setClosingHour: (value) => handleHourChange(day, "close", value),
  }));

  const handleSave = () => {
    updateSettings({
      basic: {
        dayHours: DAYS.reduce(
          (acc, day) => ({
            ...acc,
            [day]: hours[day],
          }),
          {}
        ),
      },
    });
  };

  return (
    <>
      <Container
        sx={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 20 }}>
            {t("openingHoursModalTitle")}
          </div>
          <CloseIcon style={{ cursor: "pointer" }} onClick={closeModal} />
        </div>
        <div style={{ fontSize: 17, fontWeight: 500 }}>
          {t("openingHoursModalSubtitle")}
        </div>
      </Container>

      <form style={{ maxHeight: "calc(100vh - 200px)", overflowY: "scroll" }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {daysList.map((day, index) => (
              <React.Fragment key={day.day}>
                <AddItemInputContainer
                  titleFontSize={18}
                  notTextField={true}
                  sxStyle={{
                    padding: "0px !important",
                    alignItems: "flex-start !important",
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                    }}
                  >
                    {day.day}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {["open", "close"].map((type) => (
                      <AddItemInputContainer
                        key={type}
                        name={t(
                          type === "open" ? "openingHour" : "closingHour"
                        )}
                        titleFontSize={16}
                        notTextField={true}
                        sxStyle={{
                          padding: "0px !important",
                          alignItems: "flex-start !important",
                        }}
                      >
                        <TimeField
                          name={`${day.day}${
                            type.charAt(0).toUpperCase() + type.slice(1)
                          }Hour`}
                          onChange={(newValue) =>
                            type === "open"
                              ? day.setOpeningHour(newValue)
                              : day.setClosingHour(newValue)
                          }
                          value={day[type]}
                        />
                      </AddItemInputContainer>
                    ))}
                  </div>
                </AddItemInputContainer>
                <Divider />
              </React.Fragment>
            ))}
          </LocalizationProvider>
        </Container>
      </form>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          padding: 24,
          backgroundColor: "var(--default-background-color)",
        }}
      >
        <CustomListButton
          text={t("textSave")}
          onClick={handleSave}
          big={true}
          type="submit"
        />
      </div>
    </>
  );
}

export default EditOpeningHours;
