import React from "react";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import { SettingsContext } from "../Context/SettingsContext";
import SuccessModal from "../common/SuccessModal";
import { PersonalizeContext } from "../Context/PersonalizeContext";
import { FullPageSxStyle } from "../common/FullPageStyle";
import IphonePersonalizePreview from "../Preview/IphonePersonalizePreview";
import DoliButton from "../common/DoliButton";
import { DoliNavigationContext } from "../Context/DoliNavigationContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InformationsContext } from "../Context/InformationsContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function PreviewPageContent() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("personalizePageTitle")}`);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { changesStyleModal, setChangesStyleModal, allIsSaved } =
    React.useContext(SettingsContext);
  const { windowDimensions } = React.useContext(DoliNavigationContext);
  const { handleSaveStyle, setCanLoad } = React.useContext(PersonalizeContext);
  const { handleSaveInformations } = React.useContext(InformationsContext);
  const { settings } = React.useContext(SettingsContext);

  const isPersonnalize = searchParams.get("type") === "style";

  const ref = React.useRef(null);
  React.useEffect(() => {
    setCanLoad(true);
  }, []);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px !important",
          gap: 10,
          marginBottom: 56,
        }}
        sx={{
          maxWidth: { md: "auto", lg: 1136 },
          padding: { xs: "0px !important", md: "0px 0px !important" },
          "--border-radius-card": {
            xs: "0px",
            md: "8px",
          },
          ...FullPageSxStyle,
        }}
      >
        <Container
          sx={{
            padding: {
              xs: "0px 0px !important",
              md: "0px !important",
              lg: "0px !important",
            },
            "--border-radius-card": {
              xs: "0px",
              md: "8px",
              height: "100vh",
            },
            ...FullPageSxStyle,
            overflow: "hidden !important",
            display: "flex",
            flexDirection:
              windowDimensions.innerWidth < 529 ||
              windowDimensions.innerHeight < 894
                ? ""
                : "column",
            justifyContent:
              windowDimensions.innerWidth < 529 ||
              windowDimensions.innerHeight < 894
                ? ""
                : "center",
            alignItems:
              windowDimensions.innerWidth < 529 ||
              windowDimensions.innerHeight < 894
                ? ""
                : "center",
          }}
        >
          <div
            style={{
              transform:
                windowDimensions.innerHeight < 810
                  ? "scale(0.6)"
                  : windowDimensions.innerHeight < 894
                  ? "scale(0.7)"
                  : windowDimensions.innerHeight < 1085
                  ? "scale(0.8)"
                  : windowDimensions.innerHeight < 1285
                  ? "scale(0.9)"
                  : "scale(1)",
              transformOrigin: "top left",
              display: "flex",
            }}
            id="1"
          >
            <div ref={ref}>
              {settings !== null && <IphonePersonalizePreview givenRef={ref} />}
            </div>
          </div>
        </Container>
        <Container
          sx={{
            background: "rgb(139,61,255)",
            background: "var(--mobile-topbar-nav-background-color)",
            position: "fixed",
            bottom: { xs: "70px", sm: "0px", md: "0px" },
            left: 0,
            height: "70px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: 5,
            ...FullPageSxStyle,
          }}
        >
          <DoliButton
            text={
              isPersonnalize
                ? t("Changer les Informations")
                : t("Changer le Style")
            }
            onClick={() =>
              isPersonnalize
                ? navigate("/user/personalize?type=informations")
                : navigate("/user/personalize?type=style")
            }
            height={"40px"}
            backgroundColorProps={"transparent"}
            borderColorProps={"white"}
            backgroundColorHoverProps={
              "var(--white-button-hover-color-w-transparent-background)"
            }
            styles={{ textAlign: "center" }}
          />
          <DoliButton
            text={t("textSave")}
            children={
              allIsSaved && (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleIcon />
                </div>
              )
            }
            onClick={() => {
              if (!allIsSaved) {
                if (isPersonnalize) {
                  handleSaveStyle();
                } else {
                  handleSaveInformations();
                }
              }
            }}
            height={"40px"}
            primary={!allIsSaved}
            styles={{ padding: "20px", color: allIsSaved ? "green" : "white" }}
          />
        </Container>
      </Container>
      {changesStyleModal && (
        <SuccessModal
          open={changesStyleModal}
          handleClose={() => {
            setChangesStyleModal(false);
          }}
        />
      )}
    </>
  );
}

export default PreviewPageContent;
