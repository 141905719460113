import React from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import InputTemplate from "./InputTemplate";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

const Address = ({ address }) => {
  function openAddressInMaps(address) {
    const encodedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      const mapsAppUrl = `comgooglemaps://?q=${encodedAddress}`;
      const newTab = window.open(mapsAppUrl, "_blank");
      setTimeout(() => {
        if (!newTab || newTab.closed || typeof newTab.closed == "undefined") {
          window.open(googleMapsUrl, "_blank");
        } else {
          newTab.location.href = googleMapsUrl;
        }
      }, 500);
    } else {
      window.open(googleMapsUrl, "_blank");
    }
  }

  return (
    <InputTemplate
      leftIcon={
        <PlaceOutlinedIcon
          sx={{ height: 24, padding: "0px 24px" }}
          htmlColor={"var(--icon-color)"}
        />
      }
      rightIcon={{
        icon: (
          <OpenInNewOutlinedIcon
            sx={{ height: 24, padding: "0px 24px" }}
            htmlColor={"var(--icon-color)"}
          />
        ),
        onClick: () => {
          openAddressInMaps(address);
        },
      }}
    >
      <div>
        <p
          style={{
            fontSize: "var(--text-size)",
            margin: 0,
            color: "var(--text-color)",
            fontWeight: "500",
            padding: "12px 12px 12px 0px",
          }}
        >
          {address?.toUpperCase() ?? ""}
        </p>
      </div>
    </InputTemplate>
  );
};

export default Address;
