import React from "react";
import { PersonalizeContext } from "../Context/PersonalizeContext";
import { IPhonePreview } from "../Preview/IphonePreview";
import { Box, Container } from "@mui/material";
import { basicsSx } from "./IphonePreviewUtils";
import HomeView from "./components/HomeView";
import { imageType, InformationsContext } from "../Context/InformationsContext";
import { DoliNavigationContext } from "../Context/DoliNavigationContext";
import { MenusContext } from "../Context/MenusContext";
import ItemListView from "./components/ItemsListView";
import { CategoriesContext } from "../Context/CategoriesContext";
import { backgroundList } from "../../backgroundList";
import ReviewButton from "./components/ReviewButton";
import ModalInformationContent from "./components/InformationsModalContent";
import NotFullPageModal from "./components/NotFullPageModal";
import { handleRetriveImage } from "../../services/ImageService";
import { bannerImagesList } from "../../bannerImagesList";

export const handlePersonalizeSliderChange = (event, newValue, setter) => {
  setter(newValue);
};

function IphonePersonalizePreview() {
  const {
    personalizeActivated,
    backgroundColor,
    backgroundImage,
    backgroundImageOpacity,
    buttonsColor,
    linesColor,
    iconsColor,
    menuButtonSize,
    menuButtonAlign,
    menuButtonContentAlign,
    ourBackgroundImage,
    textsColor,
    secondaryTextsColor,
    titlesFontSize,
    textsFontSize,
    categoriesFontSize,
    importantTextsFontSize,
    informationText,
    informationSubtitleText,
    infromationsLinesColor,
    infromationCopiedTextColor,
    reviewButtonTextColor,
    reviewButtonText,
    reviewPageTitleText,
    reviewFeedbackText,
    reviewCloseButtonText,
    reviewSendButtonText,
    reviewButtonColor,
    activeStarColor,
    promotionalBackgroundColor,
    promotionalTextColor,
    promotionalTextSize,
    promotionalBorderColor,
    allergensTitleFontSize,
    allergensTitleColor,
    allergensTextFontSize,
    allergensTextColor,
    handleSaveStyle,
    handleChangePersonalizeActivation,
    stylesFetched,
    showContent,
    handleShowContent,
    setShowContent,
  } = React.useContext(PersonalizeContext);
  const {
    restaurantName,
    display,
    link,
    message,
    restaurantAddress,
    instagram,
    facebook,
    twitter,
    promotionalText,
    dayHours,
    networkName,
    password,
    restaurantPhoneNumber,
    image,
    customImage,
  } = React.useContext(InformationsContext);
  const { menus } = React.useContext(MenusContext);
  const iphoneRef = React.useRef(null);
  const [displayInformationModal, setDisplayInformationModal] =
    React.useState(false);
  const [custom, setCustom] = React.useState(false);
  const { windowDimensions } = React.useContext(DoliNavigationContext);

  const [selectedMenu, setSelectedMenu] = React.useState(null);
  const [selectedMenuInformations, setSelectedMenuInformations] =
    React.useState(null);

  const { fetchCategories } = React.useContext(CategoriesContext);

  const handleMenuClick = async (menuId) => {
    setSelectedMenu(menuId);
    const selectedMenuData = menus?.find((m) => m._id === menuId);
    const categoriesFetched = await fetchCategories(menuId);
    if (selectedMenuData && fetchCategories) {
      setSelectedMenuInformations({
        categories: categoriesFetched.categories,
        name: categoriesFetched.menuInformation.name,
        hideCategories: categoriesFetched.hideCategories ?? false,
      });
    }
  };

  const handleBackButtonClick = () => {
    setSelectedMenu(null);
  };

  React.useEffect(() => {
    setCustom(personalizeActivated);
  }, [personalizeActivated]);

  const [backgroundImageValue, setBackgroundImageValue] =
    React.useState("none");
  const [backgroundOpacityValue, setBackgroundOpacityValue] =
    React.useState("1");

  const handleBackgroundImage = async () => {
    if (personalizeActivated) {
      console.log(
        "[IphonePersonalizePreview][handleBackgroundImage] IN",
        backgroundImage,
        ourBackgroundImage
      );
      const image = await handleRetriveImage(
        backgroundImage,
        ourBackgroundImage,
        backgroundList,
        (imageFound) => {
          console.log("imageFound");
          return `url(${imageFound})`;
        }
      );
      console.log(
        "[IphonePersonalizePreview][handleBackgroundImage] image",
        image
      );
      setBackgroundImageValue(image);
    } else {
      setBackgroundImageValue("none");
    }
  };

  React.useEffect(() => {
    handleBackgroundImage();
  }, [backgroundImage, ourBackgroundImage]);

  React.useEffect(() => {
    console.log(
      "[IphonePersonalizePreview][ip2]backgroundImageOpacity",
      backgroundImageOpacity
    );
    if (backgroundImageOpacity && (ourBackgroundImage || backgroundImage)) {
      setBackgroundOpacityValue(backgroundImageOpacity);
    }
  }, [backgroundImageOpacity]);

  React.useEffect(() => {
    console.log(
      "[IphonePersonalizePreview][backgroundImageValue] new=",
      backgroundImageValue,
      ourBackgroundImage
    );
  }, [backgroundImage, ourBackgroundImage]);

  const [bannerImageFound, setBannerImageFound] = React.useState(null);
  const handleBannerImageToUse = async () => {
    console.log("[IphonePersonalizePreview][Apparence] image", image);
    console.log(
      "[IphonePersonalizePreview][Apparence] customImage",
      customImage
    );
    const imageFound = await handleRetriveImage(
      customImage,
      image,
      bannerImagesList,
      (imageFound) => {
        console.log(
          "[IphonePersonalizePreview][Apparence] imageFound callback"
        );
        return imageFound;
      }
    );
    console.log("[IphonePersonalizePreview][Apparence] imagefound", imageFound);
    setBannerImageFound(imageFound);
  };

  React.useEffect(() => {
    //console.log("[IphonePersonalizePreview][banner] image", image);
    handleBannerImageToUse();
  }, [image, customImage]);

  const [dimensions, setDimensions] = React.useState({
    topPosition: 0,
    height: 0,
    left: 0,
    width: 0,
  });

  React.useEffect(() => {
    const updateDimensions = () => {
      if (iphoneRef.current) {
        const rect = iphoneRef.current.getBoundingClientRect();
        setDimensions({
          topPosition: rect.top,
          height: rect.height,
          left: rect.left,
          width: rect.width,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Container
      sx={{
        "--preview-screen-top": `${dimensions.topPosition}px`,
        "--preview-screen-top-padding": `calc(${dimensions.topPosition}px + 30px)`,
        "--preview-screen-bottom": `${
          windowDimensions.innerHeight -
          dimensions.topPosition -
          dimensions.height
        }px`,
        "--preview-screen-height": `${dimensions.height}px`,
        "--preview-screen-left": `${dimensions.left}px`,
        "--preview-screen-width": `${dimensions.width}px`,
        "--preview-screen-remove-top-padding": "40px",
        "--preview-screen-padding-horizontal": `0px`,
        "--text-color": textsColor,
        "--background-color": backgroundColor,
        "--card-color": buttonsColor,
        "--border": `1px solid ${linesColor}`,
        "--information-border-color": infromationsLinesColor,
        "--icon-color": iconsColor,
        "--green-text": infromationCopiedTextColor,
        "--reverse-background-color": reviewButtonColor,
        "--reverse-text-color": reviewButtonTextColor,
        "--font-family": "'Roboto', sans-serif",
        "--gold-color": activeStarColor,
        "--text-size": `${textsFontSize}px`,
        "--title-text-size": `${titlesFontSize}px`,
        "--category-text-size": `${categoriesFontSize}px`,
        "--important-text-size": `${importantTextsFontSize}px`,
        "--subtitles-color": secondaryTextsColor,
        "--promotional-background-color": promotionalBackgroundColor,
        "--promotional-text-color": promotionalTextColor,
        "--promotional-text-size": `${promotionalTextSize}px`,
        "--promotional-border-color": promotionalBorderColor,
        "--allergens-title-font-size": `${allergensTitleFontSize}px`,
        "--allergens-title-color": allergensTitleColor,
        "--allergens-text-font-size": `${allergensTextFontSize}px`,
        "--allergens-text-color": allergensTextFontSize,
        "--open-text-color": "green",
        "--close-text-color": "red",
        "--opening-text-font-size": "18px",
        "--close-text-font-size": "18px",
      }}
    >
      <Box display="flex" id="toto">
        <IPhonePreview
          boxShadow="0px 35px 70px rgba(0,0,0,0.6)"
          width={430}
          refState={iphoneRef}
        >
          <Container
            style={{
              padding: "0px",
              margin: "0px",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              fontFamily: `"Segoe UI" !important`,
              width: 414,
              color: "var(--text-color)",
              height: "896px",
              backgroundColor: "var(--background-color)",
            }}
            sx={{ ...basicsSx }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage: backgroundImageValue,
                opacity: backgroundOpacityValue,
                backgroundSize: "cover",
                borderRadius: "40em",
              }}
            />
            <Container
              sx={{
                padding: "0px 0px 0px 0px !important",
                margin: "0px !important",
                zIndex: 2,
              }}
            >
              {selectedMenu === null ? (
                <HomeView
                  handleMenuClick={handleMenuClick}
                  menus={menus}
                  menuButtonSize={menuButtonSize}
                  menuButtonAlign={menuButtonAlign}
                  menuButtonContentAlign={menuButtonContentAlign}
                  promotionalText={promotionalText}
                  establishmentName={restaurantName}
                  dayHours={dayHours}
                  message={message}
                  informationText={informationText}
                  informationSubtitleText={informationSubtitleText}
                  custom={custom}
                  address={restaurantAddress}
                  networkName={networkName}
                  password={password}
                  phoneNumber={restaurantPhoneNumber}
                  instagram={instagram}
                  facebook={facebook}
                  x={twitter}
                  banner={bannerImageFound}
                  setDisplayInformationModal={setDisplayInformationModal}
                />
              ) : (
                <ItemListView
                  establishmentName={restaurantName}
                  categories={selectedMenuInformations?.categories}
                  menuName={selectedMenuInformations?.name}
                  hideCategories={
                    selectedMenuInformations?.hideCategories ?? false
                  }
                  onBack={handleBackButtonClick}
                />
              )}

              {display && (
                <ReviewButton
                  url={link}
                  custom={custom}
                  reviewCloseButtonText={reviewCloseButtonText}
                  reviewFeedbackText={reviewFeedbackText}
                  reviewButtonText={reviewButtonText}
                  reviewPageTitleText={reviewPageTitleText}
                  reviewSendButtonText={reviewSendButtonText}
                />
              )}
              <NotFullPageModal
                isVisible={displayInformationModal}
                onClose={() => setDisplayInformationModal(false)}
              >
                <ModalInformationContent
                  basicsSx={basicsSx}
                  establishmentName={restaurantName}
                  message={message}
                  address={restaurantAddress}
                  networkName={networkName}
                  password={password}
                  phoneNumber={restaurantPhoneNumber}
                  instagram={instagram}
                  facebook={facebook}
                  x={twitter}
                />
              </NotFullPageModal>
            </Container>
          </Container>
        </IPhonePreview>
      </Box>
    </Container>
  );
}

export default IphonePersonalizePreview;
