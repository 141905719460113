import React from "react";
import Container from "@mui/material/Container";
import { plansList, plansListAnnual } from "../../consts";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import UpgradeOfferCard from "./UpgradeOfferCard";
import FAQ from "../common/FAQ";
import { IOSSwitch } from "../common/IOSSwitch";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import BillingFail from "./BillingFail";

function Billing({ isNavigation = false, closeModal }) {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const backPage = queryParameters.get("backPage");
  const fail = queryParameters.get("fail");

  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("billingPageTitle")}`);

  const [isAnnual, setIsAnnual] = React.useState(true);
  const handleChange = (event) => setIsAnnual(event.target.checked);
  const [openBillingFail, setOpenBillingFail] = React.useState(fail);

  const currentPlansList = isAnnual ? plansListAnnual : plansList;

  return (
    <div
      style={{
        backgroundColor: "white",
        marginBottom: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: 20,
        }}
      >
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => (isNavigation ? closeModal() : navigate(backPage))}
            fontSize="medium"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 30,
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h1>{t("billingPageQuestionMarkTitle")}</h1>
        </Container>

        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: 15, fontWeight: "500" }}>
            {t("billingPageMonthly")}
          </div>
          <IOSSwitch
            defaultChecked
            checked={isAnnual}
            onChange={handleChange}
          />

          <div style={{ fontSize: 15, fontWeight: "500", marginRight: 10 }}>
            {t("billingPageAnnual")}{" "}
            <span style={{ color: "var(--billing-promotional-text-color)" }}>
              (-25%)
            </span>
          </div>
        </Container>

        <Container
          style={{
            display: "flex",
            justifyContent: "flex-start",
            textAlign: "center",
            gap: 10,
          }}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "0px 20px", md: "0px 10%" },
          }}
        >
          {currentPlansList.map((plan) => (
            <UpgradeOfferCard plan={plan} isAnnual={isAnnual} />
          ))}
        </Container>

        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h1>{t("billingPageFAQTitle")}</h1>
        </Container>

        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "0px 20px", md: "0px 10%" },
          }}
        >
          <FAQ
            title={"Est-ce que je peux annuler mon abonnement à tout moment ?"}
            description={
              "Oui bien sûr ! Vous pouvez annuler votre abonnement à tout moment. Votre abonnement restera actif jusqu'à la fin de la période de facturation en cours. Vous ne serez pas facturé pour la période suivante."
            }
          />
          <FAQ
            title={"Comment puis-je payer mon abonnement ?"}
            description={
              "Vous pouvez payer votre abonnement par carte bancaire. Nous acceptons les cartes Visa, Mastercard et American Express."
            }
          />
          <FAQ
            title={"Puis-je changer de forfait à tout moment ?"}
            description={
              "Oui, vous pouvez changer de forfait à tout moment. Votre abonnement actuel sera annulé et vous serez facturé au prorata pour le nouveau forfait."
            }
          />
        </Container>
      </div>
      {openBillingFail && (
        <BillingFail
          open={openBillingFail}
          closeModal={() => setOpenBillingFail(false)}
          isAnnual={isAnnual}
        />
      )}
    </div>
  );
}

export default Billing;
