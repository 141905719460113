import React from "react";
import Container from "@mui/material/Container";
import CropIcon from "@mui/icons-material/Crop";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import ColorsContent from "./Content/ColorsContent";
import FrameContent from "./Content/FrameContent";
import QRCodePreview from "./Content/QRCodePreview";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

function QRCode() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("qrCodePageTitle")}`);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px !important",
          gap: 10,
          marginBottom: 56,
        }}
        sx={{
          maxWidth: { md: "auto", lg: 1136 },
          padding: { xs: "0px !important", md: "0px 24px !important" },
          "--border-radius-card": {
            xs: "0px",
            md: "8px",
          },
        }}
      >
        <DoliTopPageTitle title={t("qrCodePageTitle")} addPadding={true} />
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            gap: "10px",
            flex: 3,
          }}
        >
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              border: "1px solid var(--border-color)",
              backgroundColor: "var(--product-card-background-color)",
              borderRadius: 8,
              flex: 1,
            }}
            sx={{
              maxWidth: { xs: "100%", md: 320, lg: 320 },
              padding: { xs: "10px", sm: "10px", md: "20px", lg: "20px" },
            }}
          >
            <QRCodePreview />
          </Container>
        </Container>
      </Container>
    </>
  );
}

export default QRCode;
