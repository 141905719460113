import * as React from "react"

const ChatIcon = ({ color, width = 512, height = 512 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${height}px`}
      version="1"
      viewBox="0 0 512 512"
      fill={color}
    >
      <path
        d="M2370 5114c-19-2-78-9-130-15-376-43-778-193-1102-410-272-183-538-451-714-719-507-773-562-1748-145-2572l59-117-100-398c-112-448-113-457-58-569 33-68 78-111 150-145 88-42 119-39 578 55l424 87 111-54C2054-40 2757-80 3411 145c139 48 419 187 549 272 582 383 991 994 1114 1667 156 845-103 1682-705 2285-399 398-879 639-1449 726-100 15-472 28-550 19zm1337-1834c61-27 85-70 80-143-3-46-9-63-31-86-16-16-40-34-55-40-18-8-355-11-1141-9l-1115 3-32 23c-40 28-68 98-59 147 9 45 59 102 100 114 18 5 496 9 1121 10 1054 1 1091 0 1132-19zm44-697c35-38 39-47 39-95 0-63-24-109-72-135-31-17-96-18-1128-21-1208-3-1150-6-1204 59-54 64-37 169 33 216l34 23 1129-2 1129-3 40-42zm-23-641c88-63 82-195-10-252-33-20-49-20-1153-18l-1120 3-32 23c-40 28-68 98-59 146 8 43 49 94 91 112 24 11 231 13 1140 11l1110-2 33-23z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
)
export default ChatIcon
