import React from "react";
import DoliButton from "../common/DoliButton";
import { PersonalizeContext } from "../Context/PersonalizeContext";
import DoliNotificationPopup from "../common/DoliNotificationPopup";

function DoiPersonalizeTopbarButton() {
  const { personalizeActivated, handleChangePersonalizeActivation } =
    React.useContext(PersonalizeContext);

  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, [personalizeActivated]);

  return (
    <div>
      {personalizeActivated !== null && (
        <>
          <DoliButton
            onClick={() => handleChangePersonalizeActivation()}
            backgroundColorProps={"transparent"}
            borderColorProps={"white"}
            backgroundColorHoverProps={
              "var(--white-button-hover-color-w-transparent-background)"
            }
            height={"38px"}
            text={personalizeActivated ? "Personnalisé" : "Defaut"}
          />
          <DoliNotificationPopup
            title={
              personalizeActivated
                ? "Personnalisation activée."
                : "Personnalisation désactivée."
            }
            message={
              personalizeActivated
                ? "Cliquez pour désactiver la personnalisation du thème."
                : "Cliquez pour activer la personnalisation du thème."
            }
            setOpen={setOpen}
            open={open}
          />
        </>
      )}
    </div>
  );
}
export default DoiPersonalizeTopbarButton;
