import React from "react";
import Container from "@mui/material/Container";
import RestaurantMenuSharpIcon from "@mui/icons-material/RestaurantMenuSharp";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import BarChartIcon from "@mui/icons-material/BarChart";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DoliTopBarNavigation from "../common/DoliTopBarNavigation";
import DoliSidebarNavigation from "../common/DoliSidebarNavigation";
import DoliTopBarNavigationNotMain from "../common/DoliTopBarNavigationNotMain";
import DoliSidebarNavigationNotMain from "../common/DoliSidebarNavigationNotMain";
import { FullPageSxStyle } from "../common/FullPageStyle";

function DoliBasicNavigation({
  notMainPage = false,
  actualPageId = 0,
  hideDetailSidebar = false,
}) {
  return (
    <>
      {!notMainPage && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0px !important",
            padding: "0px !important",
            ...FullPageSxStyle,
          }}
        >
          <DoliSidebarNavigation
            actualPageId={actualPageId}
            hideDetailSidebar={hideDetailSidebar}
          />
          <DoliTopBarNavigation
            notMainPage={notMainPage}
            detailSidebarIsHidden={hideDetailSidebar}
          />
        </Container>
      )}
      {notMainPage && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0px !important",
            padding: "0px !important",
            ...FullPageSxStyle,
          }}
        >
          <DoliTopBarNavigationNotMain actualPageId={actualPageId} />
          <DoliSidebarNavigationNotMain
            actualPageId={actualPageId}
            hideDetailSidebar={hideDetailSidebar}
          />
        </Container>
      )}
    </>
  );
}
export default DoliBasicNavigation;

export const drawerBasicList = [
  {
    name: "Menus",
    icon: <RestaurantMenuSharpIcon fontSize="medium" />,
    link: "/user/dashboard",
    button: true,
    id: 0,
  },
  {
    name: "Ventes",
    icon: <MonetizationOnIcon fontSize="medium" />,
    link: "/user/orders",
    button: true,
    id: 18,
  },
  {
    name: "Stats",
    icon: <BarChartIcon fontSize="medium" />,
    link: "/user/stats",
    button: true,
    id: 2,
  },
  {
    name: "QR",
    icon: <QrCode2Icon fontSize="medium" />,
    link: "/user/qrcode",
    button: true,
    id: 3,
  },
];
