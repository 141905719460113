import * as React from "react";
import CustomModal from "../Modal/Modal";
import CancelSubscription from "./CancelSubscription";

export default function CancelSubscriptionModal({
  open,
  handleClose,
  cancelSubscriptionFunction,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <CancelSubscription
        closeModal={handleClose}
        cancelSubscriptionFunction={cancelSubscriptionFunction}
      />
    </CustomModal>
  );
}
