export const basicsSx = {
  h1: {
    padding: "0px !important",
    margin: "0px !important",
    fontFamily: `"Roboto", sans-serif`,
  },
  p: {
    padding: "0px !important",
    margin: "0px !important",
    fontFamily: `"Roboto", sans-serif`,
  },
};
