import React from "react";
import styles from "./ReviewButton.module.css";
import ChatIcon from "../assets/chat.svg";
import NotFullPageModal from "./NotFullPageModal";
import { Container } from "@mui/material";
import { basicsSx } from "../IphonePreviewUtils";
import StarIcon from "../assets/star.svg";

const ReviewButton = ({
  url,
  custom,
  reviewCloseButtonText,
  reviewFeedbackText,
  reviewButtonText,
  reviewPageTitleText,
  reviewSendButtonText,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [closeMessages, setCloseMessages] = React.useState(null);
  const [unSelectedStarColor, setUnselectedStarColor] = React.useState(null);

  React.useEffect(() => {
    console.log(
      window.getComputedStyle(document.body).getPropertyValue("--card-color")
    );
    setUnselectedStarColor(
      window
        .getComputedStyle(document.body)
        .getPropertyValue("--subtitles-color")
    );
  }, []);

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const handleClose = () => {
    const closeButtonText = custom
      ? reviewCloseButtonText || "Fermer"
      : "Fermer";
    const feedbackText = custom
      ? reviewFeedbackText || "Merci, votre avis est important pour nous !"
      : "Merci, votre avis est important pour nous !";
    setCloseMessages({
      buttonText: closeButtonText,
      onClick: () => {
        setCloseMessages(null);
        setIsVisible(false);
        setRating(0);
      },
      message: feedbackText,
    });
  };

  const handleSendRating = () => {
    if (rating >= 4) {
      window.open(url, "_blank");
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <>
      <div className={styles.rateButton} onClick={() => setIsVisible(true)}>
        <ChatIcon color={"var(--reverse-text-color)"} height={20} width={20} />
        <p>
          {custom
            ? reviewButtonText || "Noter l'établissement"
            : "Noter l'établissement"}
        </p>
      </div>
      <NotFullPageModal
        showCloseButton={false}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      >
        <Container
          sx={{
            basicsSx,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100% !important",
            margin: "0px !important",
            padding: "0px !important",
            maxWidth: "100% !important",
            height: "fit-content",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 16px 10px",
              width: "100% !important",
              height: "100%",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                color: "var(--text-color)",
                fontSize: "var(--important-text-size)",
                fontWeight: "700",
              }}
            >
              {custom
                ? reviewPageTitleText || "Noter l'établissement"
                : "Noter l'établissement"}
            </h1>
            {!closeMessages && (
              <div style={{ display: "flex", gap: "8px" }}>
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    onClick={() => handleStarClick(index)}
                    style={{
                      cursor: "pointer",
                      "--card-color": unSelectedStarColor,
                    }}
                  >
                    <StarIcon
                      key={index}
                      color={
                        index < rating
                          ? "var(--gold-color)"
                          : "var(--card-color)"
                      }
                      height={40}
                      width={40}
                    />
                  </div>
                ))}
              </div>
            )}
            {closeMessages && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: "var(--text-color)",
                    fontSize: "var(--text-size)",
                    fontWeight: "500",
                    fontFamily: "var(--font-family)",
                  }}
                >
                  {closeMessages.message}
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "var(--reverse-background-color)",
                padding: "0px 36px",
                borderRadius: "50px",
                width: "fit-content",
                textAlign: "center",
                cursor: "pointer",
                height: "fit-content",
              }}
              onClick={closeMessages ? closeMessages.onClick : handleSendRating}
            >
              <p
                style={{
                  color: "var(--reverse-text-color)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--text-size)",
                  fontWeight: "500",
                  display: "block",
                  marginBlockStart: "1em",
                  marginBlockEnd: "1em",
                  marginInlineStart: "0px",
                  marginInlineEnd: "0px",
                  unicodeBidi: "isolate",
                }}
              >
                {closeMessages
                  ? closeMessages.buttonText
                  : custom
                  ? reviewSendButtonText || "Envoyer"
                  : "Envoyer"}
              </p>
            </div>
          </div>
        </Container>
      </NotFullPageModal>
    </>
  );
};

export default ReviewButton;
