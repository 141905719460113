import * as React from "react";
import ProductListItemContainer from "../common/ProductListItemContainer";
import "../../index.css";
import CustomListButton from "../common/CustomListButton";

export default function ProductListFooter({
  categoryId,
  showCreateDish = true,
  alone = false,
  handleOpenDish,
  handleOpenCategorie,
}) {

  return (
    <ProductListItemContainer
      style={{
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: alone ? 10 : 0,
        borderTopRightRadius: alone ? 10 : 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          gap: 10,
        }}
      >
        {showCreateDish && (
          <CustomListButton text={"Nouveau Plat"} onClick={() => handleOpenDish(categoryId)} />
        )}
        <CustomListButton
          text={"Nouvelle Catégorie"}
          onClick={handleOpenCategorie}
          primary={false}
        />
      </div>
    </ProductListItemContainer>
  );
}
