import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import { SettingsContext } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";
import EditOpeningHoursModal from "../../EditOpeningHours/EditOpeningHoursModal";
import { InformationsContext } from "../../Context/InformationsContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function BasicInformation() {
  const { t } = useTranslation("global");
  const {
    restaurantName,
    setRestaurantName,
    restaurantPublicMenuUrl,
    setRestaurantPublicMenuUrl,
    restaurantAddress,
    setRestaurantAddress,
    restaurantPhoneNumber,
    setRestaurantPhoneNumber,
    message,
    setMessage,
    allSettings,
  } = React.useContext(InformationsContext);

  const { settings, checkPublicMenuUrl } = React.useContext(SettingsContext);

  const [restaurantPublicMenuUrlError, setRestaurantPublicMenuUrlError] =
    React.useState(false);
  const [displayOpeningHoursModal, setDisplayOpeningHoursModal] =
    React.useState(false);

  const checkMenuUrl = async () => {
    if (restaurantPublicMenuUrl !== settings?.basic?.publicMenuURL) {
      const response = await checkPublicMenuUrl(restaurantPublicMenuUrl);
      if (!response.available) {
        setRestaurantPublicMenuUrlError(true);
        return false;
      } else {
        setRestaurantPublicMenuUrlError(false);
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <DoliNotMainSidebarDetailContainer
        title={t("sidebarSettingsBasicInformationsDetailsTitle")}
      >
        <AddItemInputContainer
          setter={setRestaurantName}
          value={restaurantName || ""}
          name={t("textEstablishmentName")}
          titleFontSize={15}
        />
        <AddItemInputContainer
          setter={setRestaurantPublicMenuUrl}
          value={restaurantPublicMenuUrl || ""}
          name={t("textPublicMenuUrl")}
          description={
            restaurantPublicMenuUrlError
              ? t("textPublicMenuUrlIsAlreadyTaken")
              : t("settingsPublicMenuUrlInformation")
          }
          titleFontSize={15}
          subTitleFontSize={15}
          descriptionColor={
            restaurantPublicMenuUrlError
              ? "var(--unwanted-button-color)"
              : "var(--category-secondary-text-color)"
          }
          startAdornment={
            <div
              style={{
                color: "var(--category-secondary-text-color)",
                fontWeight: "500",
                fontSize: 16,
                marginRight: -7,
              }}
            >
              go.doliqr.com/
            </div>
          }
        />
        <AddItemInputContainer
          setter={setRestaurantAddress}
          value={restaurantAddress || ""}
          name={t("textAddress")}
          titleFontSize={15}
          placeholder={t("settingsEnterAddress")}
        />
        <AddItemInputContainer
          setter={setRestaurantPhoneNumber}
          value={restaurantPhoneNumber || ""}
          name={t("settingsPhoneNumber")}
          titleFontSize={15}
          placeholder={t("settingsEnterPhoneNumber")}
        />
        <AddItemInputContainer
          name={t("settingsOpeningHours")}
          titleFontSize={15}
          notTextField={true}
        >
          <CustomListButton
            text={t("settingsEnterOpeningHours")}
            big={true}
            type={"submit"}
            minWidth="100%"
            onClick={() => setDisplayOpeningHoursModal(true)}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          setter={setMessage}
          value={message}
          name={t("settingsMessage")}
          titleFontSize={15}
          rows={4}
        />
      </DoliNotMainSidebarDetailContainer>
      {displayOpeningHoursModal && (
        <EditOpeningHoursModal
          open={displayOpeningHoursModal}
          handleClose={() => setDisplayOpeningHoursModal(false)}
        />
      )}
    </>
  );
}

export default BasicInformation;
