import * as React from "react";
import { Container } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HomeButton from "./HomeButton";
import OpeningStatus from "./OpeningStatus";

export default function HeaderInformations({
  establishmentName,
  dayHours,
  message,
  informationSubtitleText,
  informationText,
  custom,
  setDisplayInformationModal,
  basicsSx,
}) {
  const openModal = () => {
    setDisplayInformationModal(true);
  };

  return (
    <>
      <Container
        sx={{
          ...basicsSx,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "16px !important",
          borderBottom: "var(--border)",
          width: "100%",
        }}
      >
        {establishmentName && (
          <h1
            style={{
              color: "var(--text-color)",
              fontSize: "var(--title-text-size)",
              fontWeight: "bold",
              overflowWrap: "break-word",
            }}
          >
            {establishmentName}
          </h1>
        )}
        {<OpeningStatus dayHours={dayHours} />}
        {message && (
          <p
            style={{
              fontSize: "var(--text-size)",
              margin: 0,
              color: "var(--subtitles-color)",
              fontWeight: "300",
              marginTop: "10px",
              overflowWrap: "break-word",
            }}
          >
            {message}
          </p>
        )}
        <HomeButton
          basicsSx={{
            marginTop: "10px",
          }}
          onClick={openModal}
          leftIcon={
            <InfoOutlinedIcon
              sx={{ height: 24, marginRight: "17px" }}
              htmlColor={"var(--icon-color)"}
            />
          }
          rightIcon={
            <ArrowForwardIosIcon
              sx={{ height: 20, marginLeft: "auto" }}
              htmlColor={"var(--icon-color)"}
            />
          }
          title={
            custom
              ? informationText || "Informations"
              : "Informations"
          }
          description={
            custom
              ? informationSubtitleText ||
                "Téléphone, adresse et réseaux sociaux"
              : "Téléphone, adresse et réseaux sociaux"
          }
          propsStyles={{
            marginTop: "16px !important",
          }}
        />
      </Container>
    </>
  );
}
