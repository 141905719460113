import * as React from "react";
import CustomModal from "../Modal/Modal";
import DeleteAccount from "./DeleteAccount";

export default function DeleteAccountModal({
  open,
  handleClose,
  deleteAccount,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <DeleteAccount closeModal={handleClose} deleteAccount={deleteAccount} />
    </CustomModal>
  );
}
