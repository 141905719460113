import { Container } from "@mui/material";
import { bannerImagesList } from "../../../bannerImagesList";
import { imagesList } from "../../../imagesList";
import Image from "../next/Image";
import React from "react";

export default function Banner({
  restaurantName,
  bannerUrl,
  image,
  height = 200,
  width = 100,
  isBanner = true,
  affiliateUrl,
  isHome = false,
  onLoad,
}) {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    if (containerRef.current && onLoad) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          onLoad(entry.contentRect.height);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [onLoad]);

  const imageLoader = ({ src, width, quality }) => {
    const url = affiliateUrl ? affiliateUrl : bannerUrl;
    return url;
  };
  const imageList = isBanner ? bannerImagesList : imagesList;

  return (
    <Container
      ref={containerRef}
      sx={{
        padding: "0px !important",
        borderRadius: "40em",
        width: "390px",
        top:  isHome? "0":"",
        left: isHome?"0" : "",
        position: isHome ? "absolute":"",
      }}
      id="banner"
    >
      {affiliateUrl && (
        <Image
          loader={imageLoader}
          src={affiliateUrl}
          alt={`${restaurantName} banner`}
          sizes="100vw"
          style={{
            width: "100%",
            objectFit: "cover",
            height: height,
            overflow: "clip",
            borderRadius: "40em 40em 0px 0px",
            overflowClipMargin: "content-box",
          }}
          width={width}
          height={height}
        />
      )}
      {bannerUrl && !affiliateUrl && (
        <Image
          loader={imageLoader}
          src={bannerUrl}
          alt={`${restaurantName} banner`}
          sizes="100vw"
          style={{
            width: "100%",
            objectFit: "cover",
            height: height,
            borderRadius: "40em 40em 0px 0px",
            overflow: "clip",
            overflowClipMargin: "content-box",
          }}
          width={width}
          height={height}
        />
      )}
      {image &&
        !affiliateUrl &&
        imageList.find((imageFound) => imageFound.name === image) && (
          <Image
            src={
              imageList.find((imageFound) => imageFound.name === image).image
            }
            alt={`${restaurantName} banner`}
            sizes="100vw"
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "40em 40em 0px 0px",
              height: height,
              overflow: "clip",
              overflowClipMargin: "content-box",
            }}
            width={width}
            height={height}
          />
        )}
    </Container>
  );
}
