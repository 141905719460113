import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import ActivatePersonalization from "../../Preview/ActivatePersonalization";

function PersonalizeReviewModule() {
  const { t } = useTranslation("global");
  const {
    setReviewSendButtonText,
    reviewButtonTextColor,
    setReviewButtonTextColor,
    reviewButtonColor,
    setReviewButtonColor,
    activeStarColor,
    setActiveStarColor,
    reviewButtonText,
    setReviewButtonText,
    reviewPageTitleText,
    setReviewPageTitleText,
    reviewFeedbackText,
    setReviewFeedbackText,
    reviewCloseButtonText,
    setReviewCloseButtonText,
    reviewSendButtonText,
    reinitializeSpecificFieldFromTheme,
  } = React.useContext(PersonalizeContext);

  return (
    <ActivatePersonalization>
      <DoliNotMainSidebarDetailContainer
        title={t("sidebarPersonnalizeReviewModuletDetailsTitle")}
      >
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewButtonColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewButtonColor")
          }
        >
          <ColorPickerPop
            setColor={setReviewButtonColor}
            color={reviewButtonColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewButtonTextColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewButtonTextColor")
          }
        >
          <ColorPickerPop
            setColor={setReviewButtonTextColor}
            color={reviewButtonTextColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeActiveStarColor")}
          notTextField={true}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("activeStarColor")
          }
        >
          <ColorPickerPop
            setColor={setActiveStarColor}
            color={activeStarColor}
          />
        </AddItemInputContainer>
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewButtonText")}
          setter={setReviewButtonText}
          value={reviewButtonText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewButtonText")
          }
        />
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewPageTitleText")}
          setter={setReviewPageTitleText}
          value={reviewPageTitleText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewPageTitleText")
          }
        />
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewFeedbackText")}
          setter={setReviewFeedbackText}
          value={reviewFeedbackText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewFeedbackText")
          }
        />
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewCloseButtonText")}
          setter={setReviewCloseButtonText}
          value={reviewCloseButtonText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewCloseButtonText")
          }
        />
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeReviewSendButtonText")}
          setter={setReviewSendButtonText}
          value={reviewSendButtonText}
          reinitializeFunction={() =>
            reinitializeSpecificFieldFromTheme("reviewSendButtonText")
          }
        />
      </DoliNotMainSidebarDetailContainer>
    </ActivatePersonalization>
  );
}

export default PersonalizeReviewModule;
