import * as React from "react";
import ProductListItemContainer from "./ProductListItemContainer";
import TooltipMenu from "./TooltipMenu";
import { iconsList } from "../../iconsList";
import { FullPageSxStyle, HoverTransitionStyle } from "./FullPageStyle";

export default function ProductCard({
  name,
  description,
  price,
  image,
  customImage,
  propsIcon,
  first,
  settings,
  alone = false,
  backgroundColor = "var(--product-card-background-color)",
  onClick,
}) {
  console.log("image", image);
  console.log("customImage", customImage);
  return (
    <ProductListItemContainer
      sx={{
        borderTopRightRadius: first || alone ? 10 : 0,
        borderTopLeftRadius: first || alone ? 10 : 0,
        borderBottomLeftRadius: alone ? 10 : 0,
        borderBottomRightRadius: alone ? 10 : 0,
        borderTop: first ? "1px solid var(--border-color)" : "",
        backgroundColor: backgroundColor,
        padding: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        flex: 5,
        ...HoverTransitionStyle,
        "&:hover": {
          backgroundColor: "var(--create-product-secondary-button-hover-color)",
          borderColor: "var(--create-product-secondary-button-border-color)",
        },
        ...FullPageSxStyle,
      }}
    >
      {propsIcon && (
        <div
          style={{
            marginLeft: "20px",
            width: "20px",
            height: "20px",
            display: "flex",
          }}
        >
          {iconsList.find((icon) => icon.name === propsIcon).icon}
        </div>
      )}
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflowWrap: "break-word",
          cursor: "pointer",
          height: "100%",
          width: "100%",
          padding: 20,
        }}
        onClick={onClick}
      >
        <span
          style={{
            fontWeight: "500",
            color: "var(--product-card-title-color)",
          }}
        >
          {name}{" "}
        </span>
        <span
          style={{
            fontWeight: "400",
            color: "var(--product-card-title-color)",
          }}
        >
          {description ? `- ${description}` : ""}
        </span>
      </div>
      <div
        style={{
          fontSize: 15,
          fontWeight: "400",
          color: "var(--product-card-title-color)",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          paddingRight: 5,
          overflow: "hidden",
          textOverflow: "clip",
          whiteSpace: "nowrap",
          overflowWrap: "break-word",
        }}
      >
        {price ? `${price} €` : ""}
      </div>
      {image || customImage ? (
        <img
          src={image || customImage}
          style={{
            width: "50px",
            height: "50px",
            maxHeight: "50px",
            objectFit: "cover",
            borderRadius: 4,
          }}
        />
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          paddingRight: 10,
        }}
      >
        {
          //TODO(chris) : Add display option
          /*
              <IOSSwitch defaultChecked />
            */
        }
        <TooltipMenu height={20} itemsMenu={settings} />
      </div>
    </ProductListItemContainer>
  );
}
