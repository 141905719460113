import React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import { MenuItem, OutlinedInput, Select } from "@mui/material";

function AddMember({ closeModal }) {
  const dropdownOptions = [
    {
      value: "OWNER",
      label: "Créateur",
    },
    {
      value: "ADMIN",
      label: "Administrateur",
    },
    {
      value: "EDITOR",
      label: "Editeur",
    },
  ];
  const [role, setRole] = React.useState("EDITOR");

  const handleChange = (event) => {
    setRole(event.target.value);
    return;
  };
  // eslint-disable-next-line
  const [email, setMail] = React.useState("");

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Ajouter un membre
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <AddItemInputContainer
            name={"Mail"}
            //description={"Nom du Menu"}
            setter={setMail}
            required={true}
          />
          <AddItemInputContainer name={"Role"} notTextField={true}>
            <Select
              displayEmpty
              value={role}
              onChange={handleChange}
              input={<OutlinedInput />}
              sx={{
                width: { xs: "100% !important", md: "90% !important" },
              }}
              style={{
                backgroundColor: "var(--default-background-color)",
                height: 35,
                fontSize: 15,
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 200,
                  },
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {dropdownOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </AddItemInputContainer>
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => {}}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default AddMember;
