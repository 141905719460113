import React, { createContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const QRCodeContext = createContext();

export const QRCodeProvider = ({ children }) => {
  const { t } = useTranslation("global");
  const [showContent, setShowContent] = React.useState("");
  const [QRBackgroundColor, setQRBackgroundColor] = React.useState("#ffffff");
  const [QRColor, setQRColor] = React.useState("#000000");
  const [frameBackgroundColor, setFrameBackgroundColor] =
    React.useState("#000000");
  const [menuTextColor, setMenuTextColor] = React.useState("#ffffff");
  const [menuText, setMenuText] = React.useState("MENU");
  const [fontSize, setFontSize] = React.useState(65);

  const contextValue = useMemo(
    () => ({
      showContent,
      setShowContent,
      QRBackgroundColor,
      setQRBackgroundColor,
      QRColor,
      setQRColor,
      frameBackgroundColor,
      setFrameBackgroundColor,
      menuTextColor,
      setMenuTextColor,
      menuText,
      setMenuText,
      fontSize,
      setFontSize,
    }),
    [
      showContent,
      setShowContent,
      QRBackgroundColor,
      setQRBackgroundColor,
      QRColor,
      setQRColor,
      frameBackgroundColor,
      setFrameBackgroundColor,
      menuTextColor,
      setMenuTextColor,
      menuText,
      setMenuText,
      fontSize,
      setFontSize,
    ]
  );

  return (
    <QRCodeContext.Provider value={contextValue}>
      {children}
    </QRCodeContext.Provider>
  );
};
