import { RadioGroup } from "@mui/material";
import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import OnboardingTemplate from "./OnboardingTemplate";
import ThemeCard from "./ThemeCard";
import { useTranslation } from "react-i18next";

function OnboardingTheme({
  onPrimaryButtonClick,
  error,
  theme,
  setTheme = () => {},
}) {
  const { t } = useTranslation("global");

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  return (
    <OnboardingTemplate
      error={error}
      primaryButton={{
        text: "Continuer",
        onClick: () => onPrimaryButtonClick(),
      }}
      title={t("onboardingThemeTitle")}
      subtitle={t("onboardingThemeSubTitle")}
      divider={true}
      inputs={
        <>
          <AddItemInputContainer
            name={""}
            notTextField={true}
            titleFontSize={"15px"}
            fontSize={"18px"}
            heightProps={40}
            style={{
              padding: "0px 16px",
            }}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={theme}
              onChange={handleThemeChange}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",
                width: "100%",
              }}
            >
              <ThemeCard value={"LIGHT_THEME"} label={"Clair"} />
              <ThemeCard value={"DARK_THEME"} label={"Sombre"} />
            </RadioGroup>
          </AddItemInputContainer>
        </>
      }
    />
  );
}

export default OnboardingTheme;
