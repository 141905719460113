import React from "react";
import { Container } from "@mui/material";
import { basicsSx } from "../../IphonePreviewUtils";
const InputTemplate = ({ leftIcon, rightIcon, children }) => {
  return (
    <Container
      onClick={rightIcon?.onClick}
      sx={{
        ...basicsSx,
        padding: "0px !important",
        borderTop: `2px solid var(--information-border-color)`,
        minHeight: "50px",
        display: "flex",
        width: "100% !important",
        alignItems: "center",
        cursor: "pointer",
        maxWidth: "100% !important",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        {leftIcon && leftIcon}
        {children}
        {rightIcon && (
          <div
            onClick={rightIcon.onClick}
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          >
            {rightIcon.icon}
          </div>
        )}
      </div>
    </Container>
  );
};

export default InputTemplate;
