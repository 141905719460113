import BurritoIcon from "./components/assets/menuIcons/burrito.svg.js";
import SandwichIcon from "./components/assets/menuIcons/sandwich.svg.js";
import WineIcon from "./components/assets/menuIcons/wine.svg.js";
import CocktailIcon from "./components/assets/menuIcons/cocktail.svg.js";
import TeaIcon from "./components/assets/menuIcons/tea.svg.js";
import CoffeeIcon from "./components/assets/menuIcons/coffee.svg.js";
import SoupIcon from "./components/assets/menuIcons/soup.svg.js";
import NigiriIcon from "./components/assets/menuIcons/nigiri.svg.js";
import FishIcon from "./components/assets/menuIcons/fish.svg.js";
import UramakiIcon from "./components/assets/menuIcons/uramaki.svg.js";
import PizzaIcon from "./components/assets/menuIcons/pizza.svg.js";
import CroissantIcon from "./components/assets/menuIcons/croissant.svg.js";
import MuffinIcon from "./components/assets/menuIcons/muffin.svg.js";
import Cocktail2Icon from "./components/assets/menuIcons/cocktail2.svg.js";
import SausageIcon from "./components/assets/menuIcons/sausage.svg.js";
import RiceIcon from "./components/assets/menuIcons/rice.svg.js";
import PastaIcon from "./components/assets/menuIcons/pasta.svg.js";
import SaladIcon from "./components/assets/menuIcons/salad.svg.js";
import GrapeIcon from "./components/assets/menuIcons/grape.svg.js";
import Salad2Icon from "./components/assets/menuIcons/salad2.svg.js";
import BeerIcon from "./components/assets/menuIcons/beer.svg.js";
import ChickenlegIcon from "./components/assets/menuIcons/chickenleg.svg.js";
import WatermelonIcon from "./components/assets/menuIcons/watermelon.svg.js";
import ChickenIcon from "./components/assets/menuIcons/chicken.svg.js";
import LettuceIcon from "./components/assets/menuIcons/lettuce.svg.js";
import CakeIcon from "./components/assets/menuIcons/cake.svg.js";
import JellyIcon from "./components/assets/menuIcons/jelly.svg.js";
import IcecreamIcon from "./components/assets/menuIcons/icecream.svg.js";
import SteakIcon from "./components/assets/menuIcons/steak.svg.js";
import ForkIcon from "./components/assets/menuIcons/fork.svg.js";

export const iconsList = [
  {
    name: "fork",
    icon: (
      <ForkIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "cake",
    icon: (
      <CakeIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "watermelon",
    icon: (
      <WatermelonIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "salad2",
    icon: (
      <Salad2Icon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "grape",
    icon: (
      <GrapeIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "beer",
    icon: (
      <BeerIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "salad",
    icon: (
      <SaladIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "lettuce",
    icon: (
      <LettuceIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "cocktail2",
    icon: (
      <Cocktail2Icon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "pasta",
    icon: (
      <PastaIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "rice",
    icon: (
      <RiceIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "chicken",
    icon: (
      <ChickenIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "chickenleg",
    icon: (
      <ChickenlegIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "jelly",
    icon: (
      <JellyIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "icecream",
    icon: (
      <IcecreamIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "muffin",
    icon: (
      <MuffinIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "croissant",
    icon: (
      <CroissantIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "pizza",
    icon: (
      <PizzaIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "sausage",
    icon: (
      <SausageIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "uramaki",
    icon: (
      <UramakiIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "nigiri",
    icon: (
      <NigiriIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "fish",
    icon: (
      <FishIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "soup",
    icon: (
      <SoupIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "steak",
    icon: (
      <SteakIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "coffee",
    icon: (
      <CoffeeIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "cocktail",
    icon: (
      <CocktailIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "tea",
    icon: (
      <TeaIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "wine",
    icon: (
      <WineIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "sandwich",
    icon: (
      <SandwichIcon
        width={24}
        height={24}
        color={"var(--menu-icon-color)"}
      />
    ),
  },
  {
    name: "burrito",
    icon: (
      <BurritoIcon
        color={"var(--menu-icon-color)"}
        width={24}
        height={24}
      />
    ),
  },
];
