import React from "react";
import Container from "@mui/material/Container";
import BlankCard from "../common/BlankCard";
import { useNavigate } from "react-router-dom";
import CancelSubscriptionModal from "../CancelSubscription/CancelSubscriptionModal";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import { UserContext } from "../Context/UserContext";
import { API_URL, getActualPlan } from "../../consts";
import { addCommaToPrice } from "../../services/merchantService";
import Loading from "../Loading/Loading";
import SuccessModal from "../common/SuccessModal";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

function Plans() {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("billingAndSubscriptionPageTitle")}`);
  const testMode = true;
  const {
    currentMerchantSubscribed,
    currentMerchantId,
    currentMerchantSubscription,
  } = React.useContext(UserContext);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [
    showSubscriptionCanceledWithSuccess,
    setShowSubscriptionCanceledWithSuccess,
  ] = React.useState(false);
  const actualPlan = getActualPlan(
    currentMerchantSubscribed,
    currentMerchantSubscription?.interval === "ANNUAL" ? "ANNUAL" : "MONTHLY"
  );

  const handleCancelSubscriptionFunction = (closeModal) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${API_URL}api/marchants/cancel-subscription/${currentMerchantId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        closeModal();
        setShowSubscriptionCanceledWithSuccess(true);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <DoliTopPageTitle
          title={t("billingAndSubscriptionPageTitle")}
          addPadding={true}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            width: "100% !important",
          }}
        >
          {actualPlan && (
            <>
              {currentMerchantSubscribed !== null ||
                (testMode && (
                  <BlankCard
                    maxWidth={true}
                    cardTitle={actualPlan.name}
                    checkList={actualPlan.avantages}
                    primaryBottomButton={{
                      name: t("billingAndSubscriptionUpgradeSubscription"),
                      display: actualPlan.free,
                      onclick: () =>
                        navigate("/user/billing?backPage=/user/plans"),
                    }}
                    secondaryBottomButton={{
                      name: t("billingAndSubscriptionUnsubscribe"),
                      display: !actualPlan.free,
                      onclick: () => setShowCancelModal(true),
                    }}
                    rightValue={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <h2 style={{ margin: 0, fontSize: 20 }}>
                          {!currentMerchantSubscribed
                            ? `${actualPlan.price}`
                            : `${addCommaToPrice(
                                currentMerchantSubscription.price
                              )}€`}
                        </h2>
                        {!currentMerchantSubscribed
                          ? ``
                          : currentMerchantSubscription.interval === "MONTHLY"
                          ? `${t("billingAndSubscriptionMonthly")}`
                          : `${t("billingAndSubscriptionAnnual")}`}
                      </div>
                    }
                  />
                ))}
              {currentMerchantSubscribed === null || (testMode && <Loading />)}
            </>
          )}
          {/* TODO(chris): Continue when Stripe is impplemented
          <BlankCard
            cardTitle={"Utilisation actuelle des limites de votre forfait"}
          />
          */}
        </div>
      </Container>
      {showSubscriptionCanceledWithSuccess && (
        <SuccessModal
          open={showSubscriptionCanceledWithSuccess}
          title={t("billingUnsubscribeTitle")}
          successMessage={t("billingUnsubscribeDescription")}
          handleClose={() => {
            setShowSubscriptionCanceledWithSuccess(false);
            navigate("/user/dashboard");
          }}
        />
      )}
      {showCancelModal && (
        <CancelSubscriptionModal
          open={showCancelModal}
          handleClose={() => setShowCancelModal(false)}
          cancelSubscriptionFunction={handleCancelSubscriptionFunction}
        />
      )}
    </>
  );
}

export default Plans;
