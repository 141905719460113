import React, { createContext, useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  deleteUser,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import {
  createUser,
  deleteDoliQrUser,
  getUser,
} from "../../services/userService";
import { checkOnboarding } from "../../services/userService";
import { UserContext } from "./UserContext";

const firebaseConfig = {
  apiKey: "AIzaSyCNMWgRgVs2-yoWtw5-Pbmds1Ny7xQps5Q",
  authDomain: "qrcode-6c2af.firebaseapp.com",
  projectId: "qrcode-6c2af",
  storageBucket: "qrcode-6c2af.appspot.com",
  messagingSenderId: "872431066660",
  appId: "1:872431066660:web:6531d83bd2544c54b882b1",
};

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthContext = createContext();

function AuthProvider({ children }) {
  const { setDoliQrUser, doliQrUser, removeAll } =
    React.useContext(UserContext);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [onboarding, setOnboarding] = useState({ onboarding: false });
  const [isCheckingOnboarding, setIsCheckingOnboarding] = useState(true);

  const updateUser = async (firebaseUserFetched) => {
    if (firebaseUserFetched === null) {
      console.log("No firebase_User logged in");
      setFirebaseUser(null);
      setLoading(false);
      setOnboarding({ onboarding: false });
      setIsCheckingOnboarding(false);
      return;
    } else {
      console.log("Firebase_User logged in:", firebaseUserFetched);
    }
    setFirebaseUser(firebaseUserFetched);
    setIsCheckingOnboarding(true);
    await checkOnboarding(firebaseUserFetched.uid)
      .then(async (data) => {
        console.log("Onboarding:", data);
        setOnboarding(data);
        if (data.onboarding === true) {
          await getUser(firebaseUserFetched.uid).then((doliQrUser) => {
            console.log("[AuthContext][getUser] User:", doliQrUser);
            setDoliQrUser(doliQrUser);
          });
        }
        setIsCheckingOnboarding(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Onbarding failed:", error);
        setOnboarding(error);
        setIsCheckingOnboarding(false);
        setLoading(false);
      });
  };

  const checkOnboarding2 = async (firebaseUserFetched) => {
    await checkOnboarding(firebaseUserFetched.uid)
      .then(async (data) => {
        console.log("[checkOnboarding]Onboarding:", data);
        setOnboarding(data);
        if (data.onboarding === false) {
          console.log("User has not completed onboarding");
        } else {
          await getUser(firebaseUserFetched.uid).then(async (doliQrUser) => {
            console.log("[AuthContext][getUser] User:", doliQrUser);
            setDoliQrUser(doliQrUser);
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Onbarding failed:", error);
        setOnboarding(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUserFetched) => {
      updateUser(firebaseUserFetched);
    });
    return () => unsubscribe();
  }, []);

  const logout = () => {
    signOut(auth)
      .then(() => {
        setFirebaseUser(null);
        removeAll();
        localStorage.clear();
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  const handleSignUp = async (
    email,
    password,
    firstName,
    lastName,
    callbackError
  ) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, {
        displayName: `firstName:${firstName} lastName:${lastName}`,
      });
      await createUser(auth.currentUser.uid, email);
    } catch (error) {
      console.error(error.code);
      if (callbackError) {
        return callbackError(
          "(firebase error)Les informations saisies sont incorrectes. Veuillez réessayer."
        );
      }
    }
  };

  const extractFirebaseProfileInformations = () => {
    let firstName = "";
    let lastName = "";
    if (!firebaseUser) {
      return { firstName, lastName };
    }
    if (
      firebaseUser?.displayName?.includes("firstName:") &&
      firebaseUser?.displayName?.includes("lastName:")
    ) {
      const firstNameStartIndex =
        firebaseUser.displayName.indexOf("firstName:") + "firstName:".length;
      const lastNameStartIndex =
        firebaseUser.displayName.indexOf("lastName:") + "lastName:".length;
      firstName = firebaseUser.displayName
        .slice(
          firstNameStartIndex,
          firebaseUser.displayName.indexOf("lastName:")
        )
        .trim();
      lastName = firebaseUser.displayName.slice(lastNameStartIndex).trim();
    } else {
      const parts = firebaseUser.displayName.split(" ");
      firstName = parts[0];
      lastName = parts.slice(1).join(" ");
    }

    return { firstName, lastName, photoURL: firebaseUser?.photoURL };
  };

  const handleSignIn = async (email, password, callbackError) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response.user.uid) {
        await getUser(response.user.uid).then((doliQrUser) => {
          setDoliQrUser(doliQrUser);
        });
      }
    } catch (error) {
      console.error("Error signing in:", error);
      if (callbackError) {
        return callbackError(
          "(firebase error)Les informations d'identification sont incorrectes. Veuillez réessayer."
        );
      }
    }
  };

  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider).then((res) => {
        createUser(res.user.uid, res.user.email);
      });
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const forgotPassword = async (email, callbackSuccess, callbackError) => {
    try {
      await sendPasswordResetEmail(auth, email);
      if (callbackSuccess) {
        return callbackSuccess(
          "(firebase message)Un e-mail de réinitialisation de mot de passe a été envoyé."
        );
      }
    } catch (error) {
      console.error("Error sending password reset email:", error);
      if (callbackError) {
        return callbackError(
          "(firebase error)L'adresse e-mail n'est pas valide. Veuillez réessayer."
        );
      }
    }
  };

  const changeUserPassword = async (oldPassword, newPassword) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user || !firebaseUser) {
      return { success: false };
    }

    const credential = EmailAuthProvider.credential(
      firebaseUser?.email,
      oldPassword
    );
    const response = await reauthenticateWithCredential(user, credential)
      .then(async (userReauthenticate) => {
        console.log("User reauthenticated:", userReauthenticate);
        const auth = getAuth();
        return await updatePassword(auth.currentUser, newPassword)
          .then(() => {
            return { success: true };
          })
          .catch((error) => {
            console.log("Error updating password:", error);
            return { success: false };
          });
      })
      .catch((error) => {
        console.log("Error reauthenticating user:", error);
        return { success: false };
      });
    console.log("response", response);
    return response;
  };

  const reauthenticateUser = async (providerId, password) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (providerId === "password") {
      const credential = EmailAuthProvider.credential(user.email, password);
      try {
        await reauthenticateWithCredential(user, credential);
        return { success: true };
      } catch (error) {
        console.error("Error reauthenticating user with password:", error);
        return { success: false, error: error.code };
      }
    } else if (providerId === "google.com") {
      const provider = new GoogleAuthProvider();
      try {
        await reauthenticateWithPopup(user, provider);
        return { success: true };
      } catch (error) {
        console.error("Error reauthenticating user with Google:", error);
        return { success: false, error: error.code };
      }
    } else {
      return { success: false, error: "Unsupported provider" };
    }
  };

  const deleteAccount = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      await deleteUser(user);
      await deleteDoliQrUser(doliQrUser.userId);
      logout();
      return { success: true };
    } catch (error) {
      console.error("Error deleting user:", error);
      return { success: false, error: error };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        firebaseUser,
        logout,
        handleSignIn,
        handleSignUp,
        handleSignInWithGoogle,
        forgotPassword,
        changeUserPassword,
        deleteAccount,
        auth,
        loading,
        onboarding,
        checkOnboarding2,
        isCheckingOnboarding,
        extractFirebaseProfileInformations,
        reauthenticateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider, auth };
