import * as React from "react";
import ProductListItemContainer from "./ProductListItemContainer";
import "../../index.css";
import CustomListButton from "./CustomListButton";
import AddMemberModal from "../Members/Modal/AddMemberModal";
import { useTranslation } from "react-i18next";
import { FullPageSxStyle } from "./FullPageStyle";

export default function TopMembersPlanList({
  pageTitle,
  actualPage,
  showInviteMemberButton = false,
}) {
  const { t } = useTranslation("global");
  const [openAddMember, setOpenAddMember] = React.useState(false);
  const handleOpenAddMember = () => setOpenAddMember(true);
  const handleCloseddMember = () => setOpenAddMember(false);

  return (
    <>
      <ProductListItemContainer
        style={{
          marginBottom: 15,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: "none",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "1px solid var(--border-color)",
          ...FullPageSxStyle,
        }}
      >
        {
          //<TopMembersPlanNavigation actualPage={actualPage} />
        }
      </ProductListItemContainer>
      <ProductListItemContainer
        style={{
          marginBottom: 15,
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: "none",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
        sx={{
          ...FullPageSxStyle,
        }}
      >
        <h2 style={{ margin: 0 }}>{pageTitle}</h2>
        {showInviteMemberButton && (
          <CustomListButton
            text={t("membersPageTitleInviteMember")}
            onClick={handleOpenAddMember}
          />
        )}
      </ProductListItemContainer>
      {openAddMember && (
        <AddMemberModal
          open={openAddMember}
          handleClose={handleCloseddMember}
        />
      )}
    </>
  );
}
