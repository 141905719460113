import React from "react";
import DoliLogo from "../assets/logo.png";
import "./Loading.css";
function Loading() {
  return (
    <div className="container">
      <div className="rotating-image-container">
        <img src={DoliLogo} alt="Image tournante" className="rotating-image" />
      </div>
      <div>Chargement...</div>
    </div>
  );
}

export default Loading;
