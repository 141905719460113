export function validateMail(email) {
  const regexMail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regexMail.test(email);
}

export function validateName(name) {
  const regexName =
    /^[a-zA-ZàâäéèêëïîôöùûüÿçœæÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇŒÆ-]+(\s[a-zA-ZàâäéèêëïîôöùûüÿçœæÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇŒÆ-]+)*$/;
  return regexName.test(name);
}

export function validateEstablishmentName(name) {
  const regexEstablishmentName = /^[a-zA-ZàâäéèêëïîôöùûüÿçœæÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇŒÆ0-9\s-]+$/;
  return regexEstablishmentName.test(name);
}

export function validateSubPage(subPage) {
  const regexSubPage = /^[a-zA-Z0-9]+$/;
  return regexSubPage.test(subPage);
}