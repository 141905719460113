import * as React from "react";
import { Container } from "@mui/material";
import Address from "./InformationsModalContent/Address";
import Wifi from "./InformationsModalContent/Wifi";
import PhoneNumber from "./InformationsModalContent/PhoneNumber";
import SocialMedias from "./InformationsModalContent/SocialMedias";

export default function ModalInformationContent({
  basicsSx,
  establishmentName,
  message,
  address,
  networkName,
  password,
  phoneNumber,
  instagram,
  facebook,
  x,
}) {
  const [displaySocialNetworks, setDisplaySocialNetworks] =
    React.useState(false);

  React.useEffect(() => {
    if (instagram !== "" || facebook !== "" || x !== "") {
      setDisplaySocialNetworks(true);
    }
  }, []);
  return (
    <Container
      sx={{
        ...basicsSx,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100% !important",
        margin: "0px !important",
        padding: "0px !important",
        maxWidth: "100% !important",
      }}
    >
      <div
        style={{
          padding: "0px 16px 24px",
          width: "100% !important",
        }}
      >
        {establishmentName && (
          <h1
            style={{
              color: "var(--text-color)",
              fontSize: "var(--title-text-size)",
              fontWeight: "700",
              overflowWrap: "break-word",
            }}
          >
            {establishmentName}
          </h1>
        )}
        {message && (
          <p
            style={{
              fontSize: "var(--text-size)",
              margin: 0,
              color: "var(--subtitles-color)",
              fontWeight: "300",
            }}
          >
            {message}
          </p>
        )}
      </div>
      {address !== (undefined || "") && <Address address={address} />}

      {networkName !== (undefined || "") && (
        <Wifi wifiName={networkName} password={password} />
      )}
      {phoneNumber !== (undefined || "") && (
        <PhoneNumber number={phoneNumber} />
      )}
      {displaySocialNetworks && (
        <SocialMedias
          socialNetworks={{
            instagram,
            facebook,
            x,
          }}
        />
      )}
    </Container>
  );
}
