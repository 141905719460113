import * as React from "react";
import "../../index.css";
import { Container } from "@mui/material";
import DoliButton from "./DoliButton";

export default function AccessibleButton({
  onClick,
  text,
  color = "rgb(21, 153, 172)",
  icon,
}) {
  return (
    <Container
      onClick={onClick}
      sx={{
        margin: "0px !important",
        padding: "0px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        gap: "5px",
        width: "80px",
        transition: "transform .2s ease",
        cursor: "pointer",
        "svg.MuiSvgIcon-root": {
          height: "26px",
          width: "26px",
          transition: "0.2s ease",
          color: "white",
        },
        "&:hover svg.MuiSvgIcon-root": {
          height: "30px",
          width: "30px",
          transition: "0.2s ease",
        },
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
    >
      <div
        style={{
          height: "50px",
          width: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DoliButton
          onClick={onClick}
          iconBefore={icon}
          primary={false}
          styles={{
            width: "48px !important",
            height: "48px !important",
            minHeight: "48px !important",
            maxHeight: "48px !important",
            minWidth: "48px !important",
            maxWidth: "48px !important",
            padding: "0px !important",
            borderRadius: "50%",
            backgroundColor: `${color} !important`,
            transition: "transform .2s ease",
            border: "none !important",
          }}
        />
      </div>
      <div
        style={{
          fontSize: "0.85rem",
          lineHeight: "1.2rem",
          fontWeight: "500",
          color: "rgb(87, 89, 91)",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    </Container>
  );
}
