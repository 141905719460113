import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function TooltipMenu({ itemsMenu, height = 15 }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 0, display: "flex" }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ padding: "5px" }}>
            <MoreVertIcon style={{ height: height }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{
            ".MuiList-root": {
              padding: "10px 0px !important",
              width: "auto",
              minWidth: "150px",
            },
            ".MuiMenu-root": {
              borderRadius: 50,
            },
            ".MuiMenu-list": { borderRadius: 50 },
            ".MuiMenu-paper": { borderRadius: "12px" },
            ".MuiButtonBase-root, .MuiMenuItem-root": {
              padding: "10px !important",
            },
            marginTop: "30px",
            padding: "10px !important",
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {itemsMenu
            .filter((item) => !item.hidden)
            .map((item) => (
              <MenuItem
                sx={{
                  ".MuiTypography-root": {
                    fontWeight: "500 !important",
                  },
                }}
                className={"navbarItem"}
                key={item.name}
                onClick={() => {
                  item.action();
                  handleCloseUserMenu();
                }}
              >
                <Typography textAlign="center">{item.name}</Typography>
              </MenuItem>
            ))}
        </Menu>
      </Box>
    </>
  );
}

export default TooltipMenu;
