import { MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import Divider from "../common/Divider";
import LoginInputTextField from "../Login/LoginInputTextField";
import AddItemInputContainer from "../common/AddItemInputContainer";
import OnboardingTemplate from "./OnboardingTemplate";
import { useTranslation } from "react-i18next";
import CustomListButton from "../common/CustomListButton";

function OnboardingInformations({
  affiliateDropdown,
  setAffiliateDropdown,
  setEstablishmentName = () => {},
  setPublicMenuURL = () => {},
  establishmentName,
  publicMenuURL,
  onPrimaryButtonClick,
  title = "onboardingInformationTitle",
  subtitle = "onboardingInformationSubTitle",
  showAffiliateDropdown = true,
  error,
}) {
  const { t } = useTranslation("global");
  const [editUrl, setEditUrl] = React.useState(false);

  const affiliateDropdownOptions = [
    { value: "1", label: "On m'a démarché" },
    { value: "2", label: "Recherche sur internet" },
    { value: "3", label: "Réseaux sociaux" },
    { value: "4", label: "Bouche à oreille" },
    { value: "5", label: "Je l'ai vu chez un autre établissement" },
  ];

  const handleAffiliateDropdownChange = (event) => {
    setAffiliateDropdown(event.target.value);
    return;
  };

  return (
    <OnboardingTemplate
      error={error}
      primaryButton={{
        text: "Continuer",
        onClick: () => onPrimaryButtonClick(),
      }}
      title={t(title)}
      subtitle={t(subtitle)}
      divider={true}
      inputs={
        <>
          <LoginInputTextField
            setter={setEstablishmentName}
            value={establishmentName}
            title={"Nom de l'établissement"}
          />
          <LoginInputTextField
            disabled={!editUrl}
            title={"URL du menu (lien sans scanner le QR Code)"}
            value={publicMenuURL}
            setter={setPublicMenuURL}
            startAdornment={
              <div
                style={{
                  color: "var(--category-secondary-text-color)",
                  fontWeight: "500",
                  fontSize: 18,
                  marginRight: -7,
                }}
              >
                go.doliqr.com/
              </div>
            }
            endAdornment={
              <div
                style={{
                  padding: 0,
                  margin: "-8px",
                }}
              >
                <CustomListButton
                  onClick={() => setEditUrl(!editUrl)}
                  styles={{
                    width: "100%",
                    height: 30,
                    fontWeight: "500 !important",
                  }}
                  text={t("textEdit")}
                />
              </div>
            }
          />
          {showAffiliateDropdown && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <Divider />
              </div>
              <AddItemInputContainer
                name={"Comment vous avez connu DoliQR?"}
                notTextField={true}
                titleFontSize={"15px"}
                fontSize={"18px"}
                heightProps={40}
                style={{
                  padding: "0px 16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "start",
                    width: "100%",
                  }}
                >
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    value={affiliateDropdown}
                    onChange={handleAffiliateDropdownChange}
                    style={{
                      width: "100%",
                      height: 40,
                      fontSize: 18,
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {affiliateDropdownOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </AddItemInputContainer>
            </>
          )}
        </>
      }
    />
  );
}

export default OnboardingInformations;
