export const getColor = () => {
  return (
    "hsl(" +
    360 * Math.random() +
    "," +
    100 +
    "%," +
    (85 + 10 * Math.random()) +
    "%)"
  );
};
