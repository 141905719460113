import React, { createContext, useState } from "react";
import { ALL_ITEMS, API_URL } from "../../consts";
import { UserContext } from "./UserContext";
import { dataURLtoFile } from "../../services/uploadFileService";

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const { currentMerchantId } = React.useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [menuInformation, setMenuInformation] = useState(null);
  const [hideCategories, setHideCategories] = useState(false);

  const fetchCategories = async (menuId) => {
    const response = await fetch(`${API_URL}api/menus/${menuId}`);
    const data = await response.json();
    console.log("fetchCategories data", data);
    console.log("fetchCategories hideCategories", data.hideCategories);
    console.log("fetchCategories categories", data.categories);
    setHideCategories(data.hideCategories);
    setCategories(data.categories);
    setMenuInformation({
      name: data.name,
      description: data.description,
      icon: data.icon,
    });
    return {
      categories: data.categories,
      hideCategories: data.hideCategories,
      menuInformation: {
        name: data.name,
        description: data.description,
        icon: data.icon,
      },
    };
  };

  const deleteCategory = async (categoryId) => {
    try {
      await fetch(`${API_URL}api/categories/${categoryId}`, {
        method: "DELETE",
      });
      setCategories(
        categories.filter((category) => category._id !== categoryId)
      );
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const moveCategory = async (categoryId, direction) => {
    try {
      const response = await fetch(
        `${API_URL}api/categories/${categoryId}/${direction}`,
        {
          method: "PUT",
        }
      );
      const updatedCategoryResponse = await response.json();
      const updatedCategory = updatedCategoryResponse.upadatedCategorie;
      const categorieMovedUpdate = updatedCategoryResponse.categorieMovedUpdate;
      console.log("updatedCategories", updatedCategory);
      const updatedCategories = categories.map((category) => {
        if (category._id === categorieMovedUpdate._id) {
          return { ...categorieMovedUpdate, plats: category.plats };
        }
        if (category._id === categoryId) {
          return { ...updatedCategory, plats: category.plats };
        }
        return category;
      });
      console.log(updatedCategories);
      setCategories(updatedCategories);
    } catch (error) {
      console.error(`Error moving category ${direction}:`, error);
    }
  };

  const movePlat = async (articleId, direction) => {
    try {
      const response = await fetch(
        `${API_URL}api/plats/${articleId}/${direction}`,
        {
          method: "PUT",
        }
      );
      const { updatedPlat, updatedMovedPlat } = await response.json();

      const updatedCategories = categories.map((category) => {
        if (category._id === updatedPlat.categorie) {
          const updatedPlats = category.plats.map((plat) => {
            if (plat._id === updatedPlat._id) {
              return updatedPlat;
            }
            if (plat._id === updatedMovedPlat._id) {
              return updatedMovedPlat;
            }
            return plat;
          });
          return { ...category, plats: updatedPlats };
        }
        return category;
      });

      setCategories(updatedCategories);
    } catch (error) {
      console.error(`Error moving plat ${direction}:`, error);
    }
  };

  const deletePlat = async (articleId) => {
    try {
      await fetch(`${API_URL}api/plats/${articleId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const updatedCategories = categories.map((category) => ({
        ...category,
        plats: category.plats.filter((plat) => plat._id !== articleId),
      }));
      setCategories(updatedCategories);
    } catch (error) {
      console.error("Error deleting plat:", error);
    }
  };

  const addProduct = async (
    name,
    description,
    price,
    categoryId,
    customImage,
    image,
    allergens,
    menuId,
    closeModal
  ) => {
    if (!name) {
      return;
    }
    let categoryIdToUse = categoryId;
    let categoriesListToUse = categories;
    if (!categoryIdToUse) {
      const isAllExist = categoriesListToUse.find(
        (category) => category.name === ALL_ITEMS
      );
      if (!isAllExist) {
        const newCategorie = await addCategorie(
          ALL_ITEMS,
          "",
          menuId,
          () => {},
          true
        );
        console.log("newCategorie", newCategorie.createdCategory);
        categoryIdToUse = newCategorie.createdCategory._id;
        categoriesListToUse = newCategorie.categories;
      } else {
        categoryIdToUse = isAllExist._id;
      }
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description ?? "");
    formData.append("price", price ?? "");
    formData.append("categorieId", categoryIdToUse);
    formData.append("marchantId", currentMerchantId);
    formData.append("allergens", JSON.stringify(allergens));

    if (customImage) {
      const fileToUpload = dataURLtoFile(customImage.image, customImage.name);
      formData.append("customImage", fileToUpload);
    } else if (image) {
      formData.append("imageValue", image.name);
    }

    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(`${API_URL}api/plats`, options);
    const data = await response.json();
    if (!data) {
      return;
    }
    if (!data.needToPay) {
      const updatedCategories = categoriesListToUse.map((category) => {
        if (category._id === categoryIdToUse) {
          return {
            ...category,
            plats: [...category.plats, data],
          };
        }
        return category;
      });
      setCategories(updatedCategories);
      if (closeModal) {
        closeModal();
      }
    } else {
      window.location.replace(`/user/menus/${menuId}?upgrade=true`);
    }
  };

  const addCategorie = async (
    name,
    description,
    menuId,
    closeModal,
    getResponse
  ) => {
    if (!name || !currentMerchantId) {
      return;
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description: description ?? "",
        menuId: menuId,
      }),
    };
    const response = await fetch(`${API_URL}api/categories`, options);
    const data = await response.json();
    console.log("[CategoriesContext] addCategorie", data);
    const updatedCategories = [...categories, data];
    console.log("[CategoriesContext] categories liste", updatedCategories);
    setCategories(updatedCategories);
    console.log("[CategoriesContext] categories liste", updatedCategories);
    if (closeModal) {
      closeModal();
    }
    if (getResponse) {
      return { createdCategory: data, categories: updatedCategories };
    }
  };

  const updateCategorie = async (id, name, description, closeModal) => {
    if (!name) {
      return;
    }
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description: description ?? "",
      }),
    };
    const response = await fetch(`${API_URL}api/categories/${id}`, options);
    const data = await response.json();
    console.log(data);
    const updatedCategories = categories.map((category) => {
      if (category._id === id) {
        return {
          ...category,
          name: data.name,
          description: data.description,
        };
      }
      return category;
    });
    setCategories(updatedCategories);
    if (closeModal) {
      closeModal();
    }
  };

  const updateProduct = async (
    id,
    name,
    description,
    price,
    categoryId,
    customImage,
    image,
    allergens,
    closeModal
  ) => {
    if (!name) {
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description ?? "");
    formData.append("price", price ?? "");
    formData.append("marchantId", currentMerchantId);
    formData.append("allergens", JSON.stringify(allergens));

    console.log("[updatedCategories]customImage", customImage);
    console.log("[updatedCategories]image", image);
    if (
      customImage &&
      customImage.image &&
      customImage.name &&
      customImage.extension
    ) {
      const fileToUpload = dataURLtoFile(customImage.image, customImage.name);
      formData.append("customImage", fileToUpload);
    } else if (image) {
      formData.append("imageValue", image.name);
    }
    console.log("formData", formData);
    const options = {
      method: "PUT",
      body: formData,
    };

    const response = await fetch(`${API_URL}api/plats/${id}`, options);
    const data = await response.json();
    console.log(data);
    const updatedCategories = categories.map((category) => {
      console.log("[updatedCategories] category", category);
      if (category._id === categoryId) {
        console.log("[updatedCategories] category", category);
        const updatedPlats = category.plats.map((plat) => {
          if (plat._id === id) {
            console.log("[updatedCategories] plat", plat);
            return {
              ...plat,
              allergens: data.allergens,
              description: data.description,
              image: data.image,
              name: data.name,
              price: data.price,
            };
          }
          return plat;
        });
        return {
          ...category,
          plats: updatedPlats,
        };
      }
      return category;
    });
    console.log("[updatedCategories] result", updatedCategories);
    setCategories(updatedCategories);
    if (closeModal) {
      closeModal();
    }
  };

  const updateHideCategories = async (menuId, hide) => {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hideCategories: hide,
      }),
    };
    const response = await fetch(`${API_URL}api/menus/${menuId}/hide`, options);
    const data = await response.json();
    console.log("[updateHideCategories] return", data);
    setHideCategories(data.hideCategories);
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        menuInformation,
        deleteCategory,
        moveCategory,
        movePlat,
        deletePlat,
        addProduct,
        addCategorie,
        updateCategorie,
        updateProduct,
        fetchCategories,
        updateHideCategories,
        hideCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};
